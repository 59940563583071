import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";

const updateHistory = async ({
  payload,
  historyId,
  projectSlug,
}: {
  payload: string;
  historyId: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.put(`/${projectSlug}/history/${historyId}`, {
      content: payload,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateHistory = () => {
  return useMutation<
    any,
    Error,
    { payload: string; historyId: number; projectSlug: string }
  >({
    mutationFn: updateHistory,
  });
};

export { useUpdateHistory };
