import { z } from "zod";

export const OpportunityAmongCompetitionSchema = z.object({
  brand_persona: z.string().min(4, "Brand persona is required"),
  digital_key_touch_points: z
    .string()
    .min(4, "Digital key touch points is required"),
  features_and_benefits: z.string().min(4, "Features and benefits is required"),
  creative_communication_angle: z
    .string()
    .min(4, "Creative communication angle is required"),
});

export type Schema = z.infer<typeof OpportunityAmongCompetitionSchema>;
