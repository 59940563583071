import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { Schema } from "@/Types/idea-expansion/schema";

interface SubmitIdeaExpansionParams {
  payload: Schema;
  projectSlug: string;
  archetypeId: number;
  funnel: string;
  recomNumber: number;
  id: number;
}

const submit = async ({
  payload,
  projectSlug,
  archetypeId,
  funnel,
  recomNumber,
  id,
}: SubmitIdeaExpansionParams) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/idea-expansion/store/${id}`,
      {
        archetype_id: archetypeId,
        recommendation_number: recomNumber,
        funnel,
        content: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitIdeaExpansion = () => {
  return useMutation<any, Error, SubmitIdeaExpansionParams>({
    mutationFn: submit,
  });
};

export { useSubmitIdeaExpansion };
