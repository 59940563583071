import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { IdeaPersonalizationContent } from "@/Types/idea_personalization";

const update = async ({
  payload,
  projectSlug,
  id,
  archetypeId,
}: {
  payload: Omit<IdeaPersonalizationContent, "idea_personalization_number">;
  projectSlug: string;
  id: number;
  archetypeId: number;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/idea-personalization/store/${id}`,
      {
        content: payload,
        archetype_id: archetypeId,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateIdeaPersonalization = () => {
  return useMutation<
    any,
    Error,
    {
      payload: Omit<IdeaPersonalizationContent, "idea_personalization_number">;
      projectSlug: string;
      id: number;
      archetypeId: number;
    }
  >({
    mutationFn: update,
  });
};

export { useUpdateIdeaPersonalization };
