import Wrapper from "@/Pages/AudienceArchetype/Wrapper";

const AudienceAchetypeRoutes = [
  {
    path: "/:project_slug/audience-archetype",
    element: <Wrapper />,
  },
];

export default AudienceAchetypeRoutes;
