import toast from "react-hot-toast";
import { Icon } from "@iconify/react";

const Error: React.FC<{
  handleClick?: () => void;
  t: any;
  message?: string;
}> = ({ handleClick = () => {}, t, message }) => {
  return (
    <div
      className="inline-flex items-center py-10 px-20 mb-4 text-10 text-white bg-danger-redx/60 rounded-lg"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: "opacity 100ms ease-in-out",
      }}
    >
      <p className="mr-10">
        {message ||
          "We encountered an error while trying to process your data."}{" "}
        Please&nbsp;
        <span
          className="underline font-semibold cursor-pointer"
          onClick={handleClick}
        >
          try again.
        </span>
      </p>
      <Icon
        icon="mdi:close"
        className="w-15 h-15 cursor-pointer"
        onClick={() => toast.remove()}
      >
        X
      </Icon>
    </div>
  );
};

export default Error;
