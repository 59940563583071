import ProjectLayout from "@/Layouts/ProjectLayout";
import TargetAudienceIndex from "./Index";
import { useParams } from "react-router-dom";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";

const Wrapper = () => {
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? "",
  );

  return (
    <ProjectLayout>
      {!isLoading && project && <TargetAudienceIndex project={project} />}
    </ProjectLayout>
  );
};

export default Wrapper;
