import { fetcher } from "@/Services/axios";
import { IdeaPersonalizationResponse } from "@/Types/idea_personalization";

import { useQuery } from "@tanstack/react-query";

const fetch = async (
  project_slug: string,
): Promise<IdeaPersonalizationResponse> => {
  try {
    const response = await fetcher.get(
      `${project_slug}/idea-personalization/lists`, // todo: change with correct url
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        "Failed to fetch idea personalization data",
    );
  }
};

const useIdeaPersonalization = (project_slug: string) => {
  return useQuery<IdeaPersonalizationResponse, Error>({
    queryKey: ["ideaPersonalization", project_slug],
    queryFn: () => fetch(project_slug),
    refetchOnMount: false, // Disable refetch on remount
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
    staleTime: Infinity, // Keep data fresh indefinitely (no refetching)
  });
};

export default useIdeaPersonalization;
