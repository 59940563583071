import type { ButtonHTMLAttributes } from 'react';

const PrimaryButton = ({
  className = '',
  disabled,
  textColor = 'white',
  roundedSize = 'md',
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  bgColor?: string;
  textColor?: string;
  roundedSize?: string;
}) => {
  const roundedClassName = `rounded-${roundedSize} text-${textColor}`;

  return (
    <button
      {...props}
      className={
        `inline-flex items-center justify-center px-12 py-10 font-semibold text-xs ${roundedClassName} ` +
        `!focus:outline-none transition ease-in-out duration-150 ` +
        `${disabled && 'opacity-50 bg-gray-300'} ${className}`
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
