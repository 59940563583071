import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";

const updateSelected = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.patch(
      `/${projectSlug}/idea-expansion/selected-content/${id}`,
      {},
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedIdeaExpansion = () => {
  return useMutation<any, Error, { id: number; projectSlug: string }>({
    mutationFn: updateSelected,
  });
};

export { useUpdateSelectedIdeaExpansion };
