import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";

import { fetcher } from "@/Services/axios";
import { SubsectionDataType } from "@/Types/projects";
import {
  setIdeaAlignmentData,
  setIdeaAlignmentLoading,
} from "@/Context/actions/idea_alignment";

export const handleGenerateIdeaAlignment =
  (dispatch: Dispatch<ProjectAction>) =>
  async (
    project_slug: string,
    generate_more_prompt: string | null = null,
    status?: string,
  ) => {
    let interval: any;
    try {
      dispatch(
        setIdeaAlignmentLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setIdeaAlignmentLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/idea-alignment/generate`,
        {
          generate_more: generate_more_prompt,
          status,
        },
      );

      clearInterval(interval);
      dispatch(
        setIdeaAlignmentLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setIdeaAlignmentLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setIdeaAlignmentData(data.data));
      }, 1000);
    } catch {
      clearInterval(interval);
      dispatch(
        setIdeaAlignmentLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleSetProductResearchProductDescriptionData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setIdeaAlignmentData(data));
  };
