import { SourcesType } from "@/Types/projects";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const SourcesList: React.FC<{ sources: SourcesType[] | undefined }> = ({
  sources,
}) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>; // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);

  return (
    <div className="flex flex-col w-full">
      {sources && sources.length > 0 && (
        <h1 className="text-12 font-semibold mb-4">Sources</h1>
      )}
      <div
        className="relative flex pr-10 flex-nowrap gap-x-15 w-full max-w-full overflow-x-scroll scrollbar-hide cursor-grab"
        {...events}
        ref={ref}
      >
        {sources?.map((source, index) => (
          <div
            key={index}
            className="min-w-363 flex items-center px-15 py-12 gap-x-12 bg-white border-1 border-stroke-redx rounded-10"
          >
            <div className="flex">
              <div className="leading-0 w-15 h-15 p-5 text-12 bg-soft-purple-redx text-blue-redx font-semibold self-center rounded-full flex items-center justify-center rounded-full">
                {index + 1}
              </div>
            </div>
            <div className="flex-1 flex-col text-15 leading-15 font-normal text-black-redx max-w-300">
              <div className="mb-8 inline-flex w-full items-center gap-x-8">
                {source.url && (
                  <img
                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${source.url}`}
                    className="w-15 h-15"
                    alt=""
                  />
                )}
                <a
                  className="truncate"
                  href={source.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="truncate">{source.url}</p>
                </a>
              </div>
              <a
                className="truncate"
                href={source.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="truncate">{source.title}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourcesList;
