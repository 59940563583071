import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";

const updateSelectedOpportunity = async ({
  state,
  id,
  projectSlug,
}: {
  state: boolean;
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/opportunity-among-competition/update-selected/${id}`,
      {
        is_selected: state,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedOpportunity = () => {
  return useMutation<
    any,
    Error,
    { state: boolean; id: number; projectSlug: string }
  >({
    mutationFn: updateSelectedOpportunity,
  });
};

export { useUpdateSelectedOpportunity };
