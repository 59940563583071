import Wrapper from "@/Pages/KeyInsight/Wrapper";

const KeyInsightRoutes = [
  {
    path: "/:project_slug/key-insight",
    element: <Wrapper />,
  },
];

export default KeyInsightRoutes;
