import { z } from "zod";

export const AudiencePerceptionAnalysis = z.object({
  brand_perception: z.string().min(4, "Brand perception is required"),
  sector_perception: z.string().min(4, "Sector perception is required"),
  product_category_perception: z
    .string()
    .min(4, "Product category perception is required"),
});

export type AudiencePerceptionAnalysisSchema = z.infer<
  typeof AudiencePerceptionAnalysis
>;
