import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";

import { fetcher } from "@/Services/axios";
import {
  setKeyIdeaExpansionData,
  setKeyIdeaExpansionLoading,
} from "@/Context/actions/idea_expansion";

export const handleGenerateIdeaExpansion =
  (dispatch: Dispatch<ProjectAction>) =>
  async (
    project_slug: string,
    regenerate_prompt: string | null = null,
    status?: string,
  ) => {
    try {
      dispatch(
        setKeyIdeaExpansionLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setKeyIdeaExpansionLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/idea-expansion/generate`,
        {
          generate_more: regenerate_prompt,
          status,
        },
      );
      clearInterval(interval);
      dispatch(
        setKeyIdeaExpansionLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setKeyIdeaExpansionLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setKeyIdeaExpansionData(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setKeyIdeaExpansionLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };
