import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import useClickOutside from "@/Hooks/useClickOutside";

interface IInputSelectItem {
  title: string;
  value: string;
  disabled?: boolean;
  isChecked?: boolean;
}

interface InputSelectProps {
  modelValue: string;
  list: IInputSelectItem[];
  state: "init" | "loading" | "error" | "success";
  disabled: boolean;
  multiple: boolean;
  placeholder: string;
  label?: string;
  containerClassName?: string;
  error?: string;
  onChange: (value: string | number | (string | number)[]) => void;
}

const InputCheckboxDropdown: React.FC<InputSelectProps> = ({
  modelValue,
  list,
  disabled,
  placeholder,
  onChange,
  containerClassName = "",
  error = "",
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const isItemEmpty = !modelValue;

  const handleClickOutside = () => {
    setDropdownActive(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    const modelValueItem = list.find(
      (listItem) => listItem.value === modelValue,
    );
    if (modelValueItem) {
      onChange(modelValueItem.value);
    }
  }, []);

  const handleChoose = (listItem: IInputSelectItem) => {
    const newModelValue = modelValue.length > 0 ? modelValue.split(", ") : [];
    const isItemSelected = newModelValue.find(
      (newModelValueItem) => listItem.value === newModelValueItem,
    );

    if (isItemSelected) {
      const newModelValueIndex = newModelValue.findIndex(
        (newModelValueItem) => listItem.value === newModelValueItem,
      );
      newModelValue.splice(newModelValueIndex, 1);
    } else {
      newModelValue.push(listItem.value);
    }

    onChange(newModelValue.join(", "));
  };

  return (
    <div ref={elementRef} className="relative">
      <div
        className={`bg-transparent flex justify-between w-full items-center overflow-hidden ${
          disabled ? "" : "cursor-pointer"
        } ${containerClassName} ${error ? "!border-red-500" : ""}`}
        onClick={() => !disabled && setDropdownActive(!dropdownActive)}
      >
        <div
          className={`px-0 py-4 mr-20 !text-14 ${
            isItemEmpty ? "text-placeholder-redx" : ""
          }`}
        >
          {!isItemEmpty ? modelValue : placeholder}
        </div>
        {!disabled && (
          <Icon
            icon="uil:angle-down"
            className={`transition-all duration-300 ease-out w-24 h-w-24 text-black ${
              dropdownActive ? "transform rotate-180" : ""
            }`}
          />
        )}
      </div>
      {dropdownActive && (
        <div
          className="rounded-10 px-10 py-10 w-[97.5%] bg-white absolute top-full left-8 z-50 mt-10"
          style={{
            boxShadow: "0px 0px 12px 0px #00000026",
          }}
        >
          <div
            ref={elementRef}
            className="py-0 w-full max-h-120 overflow-y-auto overflow-custom"
          >
            {list.map((listItem, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-1 flex items-center">
                  <input
                    type="checkbox"
                    checked={
                      !!modelValue
                        .split(", ")
                        .find((itemItem) => itemItem === listItem.value)
                    }
                    onChange={() => handleChoose(listItem)}
                    disabled={listItem.disabled}
                    className="ml-5 mr-5 cursor-pointer text-blue-purple-redx rounded-4 border-1 outline outline-offset-0 outline-1 outline-stroke-redx !focus:border-transparent !focus:ring-0"
                  />
                  <div className={`px-10 py-5 rounded-5 !text-14`}>
                    <div className={`text-black text-opacity-80`}>
                      {listItem.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputCheckboxDropdown;
