import validation from "@/Helpers/validation";

export const validateForm = async (
  activeSection: number,
  setError: any,
  data: any,
) => {
  let hasError =
    activeSection === 0
      ? await validateProductInformation(setError, data)
      : activeSection === 1
        ? await validateMarketInformation(setError, data)
        : activeSection === 2
          ? await validateAudienceArchetype(setError, data)
          : activeSection === 3
            ? await validateCompetitors(setError, data)
            : activeSection === 4
              ? await validateProjectObjective(setError, data)
              : false;

  return hasError;
};

export const validateProductInformation = async (setError: any, data: any) => {
  return new Promise((resolve) => {
    let hasError = false;
    setError("product_name", { message: "" });
    setError("brand_name", { message: "" });
    setError("product_links", { message: "" });

    if (data.product_name === "") {
      setError("product_name", { message: "Mandatory field" });
      hasError = true;
    }
    if (data.brand_name === "") {
      setError("brand_name", { message: "Mandatory field" });
      hasError = true;
    }
    if (
      data.product_links !== "" &&
      !validation.isWebsite(data.product_links)
    ) {
      setError("product_links", { message: "Invalid URL" });
      hasError = true;
    }

    resolve(hasError);
  });
};

export const validateMarketInformation = async (setError: any, data: any) => {
  return new Promise((resolve) => {
    let hasError = false;
    setError("product_category", { message: "" });
    setError("product_subcategory", { message: "" });

    if (!data.product_category) {
      setError("product_category", { message: "Mandatory field" });
      hasError = true;
    }
    if (!data.product_subcategory) {
      setError("product_subcategory", { message: "Mandatory field" });
      hasError = true;
    }

    resolve(hasError);
  });
};

export const validateAudienceArchetype = async (setError: any, data: any) => {
  return new Promise((resolve) => {
    let hasError = false;
    setError("age_market_start", { message: "" });
    setError("gender", { message: "" });

    if (data.age_market_start === "") {
      setError("age_market_start", { message: "Mandatory field" });
      hasError = true;
    } else if (data.age_market_end === "") {
      setError("age_market_end", { message: "Mandatory field" });
      hasError = true;
    } else if (!Number.isInteger(+data.age_market_start)) {
      setError("age_market_start", {
        message: "Must be integer",
      });
      hasError = true;
    } else if (!Number.isInteger(+data.age_market_end)) {
      setError("age_market_end", {
        message: "Must be integer",
      });
      hasError = true;
    } else if (+data.age_market_start > +data.age_market_end) {
      setError("age_market_start", {
        message: "The start number must be smaller than the end number",
      });
      hasError = true;
    }

    if (data.gender === "") {
      setError("gender", { message: "Mandatory field" });
      hasError = true;
    }

    resolve(hasError);
  });
};

export const validateProjectObjective = async (setError: any, data: any) => {
  return new Promise((resolve) => {
    let hasError = false;
    setError("objective", { message: "" });

    if (data.objective === "") {
      setError("objective", { message: "Mandatory field" });
      hasError = true;
    }
    if (data.second_objective === "") {
      setError("second_objective", { message: "Mandatory field" });
      hasError = true;
    }
    resolve(hasError);
  });
};

export const validateCompetitors = async (setError: any, data: any) => {
  return new Promise((resolve: any) => {
    let hasError = false;
    const competitorsErrors: { [index: number]: string } = {};

    setError("websites", { message: "" });

    data.competitors.forEach((competitor: any, index: number) => {
      if (
        competitor !== undefined &&
        competitor.website !== "" &&
        competitor.website !== null &&
        !validation.isWebsite(competitor.website)
      ) {
        competitorsErrors[index] = "Website wrong format";
        hasError = true;
      }
    });

    setError("websites", { message: JSON.stringify(competitorsErrors) });
    resolve(hasError);
  });
};
