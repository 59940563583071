import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';

interface RegenerateInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegenerateInfoModal: React.FC<RegenerateInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount or on modal close
  }, [isOpen, onClose]);

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed bottom-59 right-2 z-60 mx-12  overflow-y-auto"
        onClick={handleStopPropagation}
        onClose={onClose}
      >
        <div className="">
          <span
            aria-hidden="true"
            className="inline-block h-screen align-middle"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 flex max-w-md flex-col justify-between gap-16 overflow-hidden rounded-2xl bg-white p-16 text-left align-middle shadow-xl transition-all">
              <div className="flex flex-col gap-8">
                <h1 className="mb-8 text-14 font-semibold leading-16 text-black-redx">
                  Don’t forget to click generate button below!
                </h1>
                <p
                  className="text-12 leading-14 text-grey-redx
              "
                >
                  Click the button below to process the new results in the next
                  section based on your selections or additions made here.
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  className="mt-16 rounded-10 bg-blue-redx px-14 py-10 font-semibold text-white"
                  onClick={onClose}
                >
                  Understand
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegenerateInfoModal;
