import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import PrimaryButton from '@/Components/PrimaryButton';
import useApprovedUsers from '@/Hooks/react-query/useApprovedUsers';
import useProjects from '@/Hooks/react-query/useProjects';
import useUser from '@/Hooks/react-query/useUser';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';
import type { PageProps, User } from '@/Types';
import type { ProjectProps } from '@/Types/projects';

import CreateFormModal from './Partials/CreateFormModal';
import NoAccessModal from './Partials/NoAccessModal';
import ProjectCard from './Partials/ProjectCard';

const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(' ');
};

const List = ({ projects }: PageProps<{ projects: ProjectProps[] }>) => {
  const { data: auth } = useUser();
  const { data: approvedUsers } = useApprovedUsers();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const [allProjects, setAllProjects] = useState<ProjectProps[]>(projects);
  const [selectedSortBy, setSelectedSortBy] = useState(
    'Creation Date (Newest - Oldest)',
  );
  const [isOpenNoAccess, setIsOpenNoAccess] = useState(false);
  const { data, isLoading } = useProjects();
  const closeModalNoAccess = () => setIsOpenNoAccess(false);

  const sortBys = [
    { name: 'Creation Date (Newest - Oldest)' },
    { name: 'Creation Date (Oldest - Newest)' },
    { name: 'Project Name (A - Z)' },
    { name: 'Project Name (Z - A)' },
  ];

  const appUrl = new URL(window.location.href);
  const baseUrl = appUrl.origin;

  const [projectFound, setProjectFound] = useState(true);
  const [projectMessage, setProjectMessage] = useState<string>();

  useEffect(() => {
    setInitialUsers(approvedUsers?.users || []);
    setProjectFound(Cookies.get('project_found') !== 'false');
  }, [approvedUsers]);

  useEffect(() => {
    if (!isLoading && data?.projects?.length) {
      setAllProjects(data.projects);
    }
  }, [data]);

  useEffect(() => {
    if (projectFound === false) {
      const name = Cookies.get('project_owner_name');
      const email = Cookies.get('project_owner_email');

      setProjectMessage(`You need an invitation to access this Ainstein project. 
Please contact the project owner, <b>${name} (${email})</b>, to be added as a teammate.`);
      setIsOpenNoAccess(true);
    }
  }, [projectFound]);

  const filterProjects = (projects: ProjectProps[], search: string) => {
    if (search === '') {
      return projects;
    }
    return projects?.filter((project) => {
      return (
        project.name?.toLowerCase().includes(search.toLowerCase()) ||
        project.owner.name?.toLowerCase().includes(search.toLowerCase()) ||
        project.owner.email?.toLowerCase().includes(search.toLowerCase()) ||
        project.users?.some((u) =>
          u.name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.users?.some((u) =>
          u.email?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.brand_name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.product_name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.category?.name.toLowerCase().includes(search.toLowerCase()),
        )
      );
    });
  };

  // handler sort by project from allProjects state and from filterProjects function
  const handlerSortBy = (sortBy: string) => {
    setSelectedSortBy(sortBy);
    let sortedProjects = allProjects;
    switch (sortBy) {
      case 'Creation Date (Newest - Oldest)':
        sortedProjects = allProjects.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        break;
      case 'Creation Date (Oldest - Newest)':
        sortedProjects = allProjects.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        );
        break;
      case 'Project Name (A - Z)':
        sortedProjects = allProjects.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        break;
      case 'Project Name (Z - A)':
        sortedProjects = allProjects.sort((a, b) =>
          b.name.localeCompare(a.name),
        );
        break;
      default:
        break;
    }
    setAllProjects([...sortedProjects]);
  };

  return (
    <AuthenticatedLayout>
      <title>Project List</title>

      <div className="pb-40 pt-73">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* Title */}
          <div className="mt-20 flex flex-row items-center justify-center">
            <div className="basis-1/2">
              <div className="flex flex-col gap-10">
                <h1 className="text-32 font-semibold leading-[36px] text-black-redx sm:text-[24px] sm:leading-[28px]">
                  Your AInstein Projects
                </h1>
                <p className="text-16 font-normal leading-20 text-grey-redx">
                  Create, search, and choose your marketing project here.
                </p>
              </div>
            </div>
            <div className="basis-1/2 text-right">
              <PrimaryButton
                bgColor="blue-700"
                className="ms-4 w-[187px] bg-blue-purple-redx"
                disabled={false}
                id="btn-show-form-create-project"
                onClick={() => setIsOpen(true)}
              >
                Create Project
              </PrimaryButton>
            </div>
          </div>
          {/* Filter */}
          <div className="my-20 flex items-center gap-20 py-20">
            <div className="grow">
              <div className="flex flex-row items-center rounded-8 border-1 border-stroke-redx bg-white px-16 py-2">
                <Icon
                  className="mx-2 text-20 font-normal leading-24 text-black-redx"
                  icon="akar-icons:search"
                />
                <input
                  className="w-full border-none text-16 font-normal leading-20 text-grey-redx focus:outline-none focus:ring-0"
                  onChange={(e) => {
                    const search = e.target.value;
                    const filteredProjects = filterProjects(
                      data.projects,
                      search,
                    );
                    setAllProjects(filteredProjects);
                  }}
                  placeholder="Search project name / product name / brand name / owner name here"
                  type="text"
                />
              </div>
            </div>
            <div className="">
              <div className="text-right">
                {/* dropdown sort by */}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="gap-x-1.5 inline-flex w-full items-center justify-center rounded-md bg-white p-10 text-16 font-semibold leading-16 text-black-redx shadow-sm ring-1 ring-inset ring-stroke-redx hover:bg-gray-50">
                      Sort by
                      <Icon
                        className="ml-6 size-19 text-black-redx"
                        icon="bi:sort-down"
                      />
                    </MenuButton>
                  </div>

                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2 w-233 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="py-1">
                        {sortBys.map((sortBy, index) => (
                          <MenuItem key={index}>
                            {({ focus }) => (
                              <button
                                className={classNames(
                                  'w-full text-left',
                                  focus
                                    ? 'bg-gray-100 text-black-redx'
                                    : 'text-black-redx',
                                  'block px-10 py-5 text-12',
                                  selectedSortBy === sortBy.name
                                    ? 'font-semibold'
                                    : 'font-normal',
                                )}
                                disabled={allProjects.length <= 0}
                                onClick={() => handlerSortBy(sortBy.name)}
                              >
                                {sortBy.name}
                              </button>
                            )}
                          </MenuItem>
                        ))}
                      </div>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {/* List */}
          <div className="flex flex-col items-center gap-30">
            {allProjects.map((project, index) => (
              <ProjectCard
                key={index}
                auth={{
                  user: auth,
                }}
                initialUsers={initialUsers}
                project={project}
              />
            ))}
          </div>
          {/* Empty List */}
          {allProjects.length <= 0 && !isLoading && (
            <div className="mx-auto w-1/2">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Empty-amico 1.png"
                  className="size-[400px]"
                  src="assets/img/Empty-amico 1.png"
                />
                <div className="flex flex-col items-center justify-items-center gap-20">
                  <h2 className="text-32 font-semibold leading-[36px] text-black-redx">
                    Project not found...
                  </h2>
                  <p className="text-center text-16 font-normal leading-20 text-grey-redx">
                    It looks like you haven&apos;t created a project yet.
                    <br />
                    Click ‘Create Project’ to start a new one or contact your
                    teammates to invite you.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateFormModal
        appUrl={baseUrl}
        auth={{
          user: auth,
        }}
        initialUsers={initialUsers}
        isOpen={isOpen}
        onClose={closeModal}
      />
      <NoAccessModal
        isOpen={isOpenNoAccess}
        message={projectMessage!}
        onClose={closeModalNoAccess}
      />
    </AuthenticatedLayout>
  );
};

export default List;
