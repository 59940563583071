import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react';
import React from 'react';

import type { SelectedCardProps } from '@/Types/selected_data';

const CardContent: React.FC<{
  title: string;
  content: string;
}> = ({ title, content }) => {
  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-12 font-semibold leading-14 text-black-redx">
        {title}
      </h3>
      <p className="text-14 font-normal leading-20">{content}</p>
    </div>
  );
};

const CardHeader: React.FC<{
  label: string;
  subLabel: string;
  open: boolean;
  onClick: () => void;
}> = ({ label, subLabel, open, onClick }) => {
  return (
    <div className="flex grow items-center justify-between">
      <div className="flex flex-col items-start gap-8">
        <h3 className="text-14 font-bold leading-16 text-black-redx">
          {label}
        </h3>
        {!open && (
          <h3 className="text-14 font-bold leading-16 text-black-redx">
            {subLabel}
          </h3>
        )}
      </div>
      <Icon
        className="size-24 cursor-pointer text-black-redx"
        icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'}
        onClick={onClick}
      />
    </div>
  );
};

const SelectedKeyTouchPointCard: React.FC<{
  // archetypeId: number;
  // projectSlug: string;
  title: string;
  subtitle: string;
  data: SelectedCardProps[];
}> = ({ title, subtitle, data }) => {
  // const navigate = useNavigate();

  return (
    <Popover className="relative border-y-1 border-y-blue-purple-redx bg-soft-purple-redx data-[open]:opacity-0">
      <PopoverButton className="z-30 w-full p-16">
        <div className="flex flex-col gap-12">
          <CardHeader
            label={title}
            onClick={() => {}}
            open={false}
            subLabel={subtitle}
          />
        </div>
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-0"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-0"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor={{ to: 'bottom end', gap: -53 }}
          className="z-30 w-[var(--button-width)] border-y-1 border-y-blue-purple-redx bg-soft-purple-redx p-16 shadow-[0px_8px_12px_0px_rgba(0,0,0,0.12)]"
        >
          {({ close }) => (
            <div className="relative flex flex-col gap-12">
              <CardHeader
                label={title}
                onClick={() => close()}
                open
                subLabel={subtitle}
              />

              <div className="grid max-h-250 min-h-full grid-cols-2 gap-16 overflow-scroll text-left">
                {data.map((item, index) => (
                  <CardContent
                    key={index}
                    content={item.content}
                    title={item.title}
                  />
                ))}
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SelectedKeyTouchPointCard;
