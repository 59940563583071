import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { OpportunityPayload } from "@/Types/opportunity_among_competition";

const updateOpportunities = async ({
  payload,
  projectSlug,
}: {
  payload: OpportunityPayload;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.put(
      `/${projectSlug}/opportunity-among-competition/`,
      {
        data: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateOpportunities = () => {
  return useMutation<
    any,
    Error,
    { payload: OpportunityPayload; projectSlug: string }
  >({
    mutationFn: updateOpportunities,
  });
};

export { useUpdateOpportunities };
