// Import your PDF component

import { Icon } from '@iconify/react/dist/iconify.js';
import { PDFDownloadLink } from '@react-pdf/renderer';

import type { OnePageStrategy } from '@/Types/one-page/one-page-strategy';
import type { ProjectProps } from '@/Types/projects';

import MyPDF from '../Print/MyPdf';

interface PDFProps {
  isFetching: boolean;
  project: ProjectProps;
  parsedData: OnePageStrategy;
}

const DownloadPDFButton = ({ isFetching, project, parsedData }: PDFProps) => {
  return (
    <div className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx">
      {isFetching ? (
        <div className="h-20 w-88 animate-pulse rounded-full bg-soft-purple-redx" />
      ) : (
        <>
          <PDFDownloadLink
            document={<MyPDF parsedData={parsedData} project={project} />}
            fileName="one-page.pdf"
          >
            Download PDF
          </PDFDownloadLink>
          <Icon className="size-20" icon="lucide:download" />
        </>
      )}
    </div>
  );
};

export default DownloadPDFButton;
