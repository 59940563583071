import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { fetcher } from '@/Services/axios';
import type { ProjectProps } from '@/Types/projects';

const latestHistory = async (project: ProjectProps | string) => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  const url = `/${projectSlug}/history/latest`;
  try {
    const response = await fetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useHistoryLatest = (
  project: ProjectProps | string,
): UseQueryResult<any, Error> => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  return useQuery<any, Error>({
    queryKey: ['latestHistory', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => latestHistory(project),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: 6000,
  });
};

export default useHistoryLatest;
