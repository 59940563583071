import ProjectLayout from "@/Layouts/ProjectLayout";
import ProjectIndex from "./Index";
import { PageProps } from "@/Types";
import { useParams } from "react-router-dom";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";
import { Helmet } from "react-helmet";

const Wrapper = ({}: PageProps<{}>) => {
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? "",
  );

  return (
    <ProjectLayout>
      <Helmet>
        <title>Market Research</title>
      </Helmet>
      {!isLoading && project && <ProjectIndex project={project} />}
    </ProjectLayout>
  );
};

export default Wrapper;
