import React, { Fragment, useEffect } from "react";

import { Dialog, Transition, TransitionChild } from "@headlessui/react";

interface RegenerateInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ViewInfoModal: React.FC<RegenerateInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount or on modal close
  }, [isOpen, onClose]);

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Transition
      show={isOpen}
      as={Fragment}>
      <Dialog
        onClick={handleStopPropagation}
        as='div'
        className='fixed bottom-59 right-2 z-60 overflow-y-auto  mx-12'
        onClose={onClose}>
        <div className=''>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'>
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'>
            <div className='flex flex-col gap-16 justify-between p-16 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-w-md'>
              <div className='flex flex-col gap-8'>
                <p
                  className='text-grey-redx text-12 leading-14
              '>
                  You cannot view the next step because the editor has not yet
                  generated next section or the generation process is still in
                  progress
                </p>
              </div>
              <div className='flex justify-end'>
                <button
                  className='py-10 px-14 bg-blue-redx text-white font-semibold rounded-10 mt-16'
                  onClick={onClose}>
                  Understand
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ViewInfoModal;
