import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { Schema } from "@/Types/one-page/schema";

const updateKeyInsight = async ({
  payload,
  projectSlug,
}: {
  payload: Schema;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.put(`/${projectSlug}/one-page-strategy`, {
      selected_audience_archetypes: payload.selected_audience_archetypes,
      campaign_strategic_statement: payload.campaign_objective,
      challenge: payload.challenge,
      communication_task: payload.communication_task,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateOnePage = () => {
  return useMutation<
    any,
    Error,
    {
      payload: Schema;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyInsight,
  });
};

export { useUpdateOnePage };
