import { fetcher } from "@/Services/axios";
import { OpportunitiesResponse } from "@/Types/opportunity_among_competition";

import { useQuery } from "@tanstack/react-query";

const fetchOpportunities = async (
  project_slug: string,
): Promise<OpportunitiesResponse> => {
  try {
    const response = await fetcher.get(
      `${project_slug}/opportunity-among-competition/lists`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch opportunities data",
    );
  }
};

const useOpportunities = (project_slug: string) => {
  return useQuery<OpportunitiesResponse, Error>({
    queryKey: ["opportunities", project_slug],
    queryFn: () => fetchOpportunities(project_slug),
    refetchOnMount: false, // Disable refetch on remount
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
    staleTime: Infinity, // Keep data fresh indefinitely (no refetching)
  });
};

export default useOpportunities;
