import { Dispatch } from "react";
import axios from "axios";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import {
  setCompetitorAnalysisData,
  setCompetitorAnalysisLoading,
} from "@/Context/actions/competitor_analysis";
import { SubsectionDataType } from "@/Types/projects";
import { fetcher } from "@/Services/axios";

export const handleGenerateCompetitorAnalysis =
  (dispatch: Dispatch<ProjectAction>) =>
  async (
    project_slug: string,
    regenerate_prompt: string | null = null,
    status?: string,
  ) => {
    try {
      dispatch(
        setCompetitorAnalysisLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setCompetitorAnalysisLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/competitor-analysis/generate`,
        {
          prompt: regenerate_prompt,
          status,
        },
      );
      clearInterval(interval);
      dispatch(
        setCompetitorAnalysisLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setCompetitorAnalysisLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setCompetitorAnalysisData(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setCompetitorAnalysisLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleEditedCompetitorAnalysis =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, history_id: number, content: string) => {
    try {
      const { data } = await fetcher.put(
        `/${project_slug}/history/${history_id}`,
        {
          content: content,
        },
      );

      dispatch(setCompetitorAnalysisData(data.data));
    } catch (err) {
      // TODO: dispatch error state
    }
  };

export const handleSetCompetitorAnalysisData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setCompetitorAnalysisData(data));
  };
