import { useNavigate } from 'react-router-dom';

import PrimaryButton from '@/Components/PrimaryButton';
import type { ProjectProps } from '@/Types/projects';

interface NotFoundCompetitorProps {
  project: ProjectProps;
}
const NotFoundCompetitor = ({ project }: NotFoundCompetitorProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${project.slug}/submission`);
  };
  return (
    <div className="flex min-h-[50vh] flex-row items-center justify-center gap-55">
      <div className="my-6">
        <img
          alt="Confused Character"
          className="w-48 mx-auto h-auto"
          src="/assets/img/ainstein-notfound.png"
        />
      </div>
      <div className="flex-col gap-30">
        <div className="mb-6">
          <h1 className="text-24 font-semibold">No result found...</h1>
          <p className="py-20 text-gray-400">
            We couldn&apos;t find any results for Competitor Analysis.
            <br />
            You can input or update the Competitor Information in the submission
            section.
          </p>
        </div>

        <PrimaryButton
          className="border-1 bg-blue-redx px-30 py-10 text-18 font-semibold hover:bg-blue-redx active:bg-blue-redx"
          onClick={handleClick}
        >
          Update Competitor Information
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NotFoundCompetitor;
