import Wrapper from "@/Pages/CompetitorAnalysis/Wrapper";

const CompetitorAnalysisRoutes = [
  {
    path: "/:project_slug/competitor-analysis",
    element: <Wrapper />,
  },
];

export default CompetitorAnalysisRoutes;
