import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";

import { fetcher } from "@/Services/axios";
import { SubsectionDataType } from "@/Types/projects";
import {
  setIdeaPersonalizationData,
  setIdeaPersonalizationLoading,
} from "@/Context/actions/idea_personalization";

export const handleGenerateIdeaPersonalization =
  (dispatch: Dispatch<ProjectAction>) =>
  async (
    project_slug: string,
    generate_more_prompt: string | null = null,
    status: string | null = null,
  ) => {
    let interval: any;
    try {
      dispatch(
        setIdeaPersonalizationLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setIdeaPersonalizationLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/idea-personalization/generate`,
        {
          generate_more: generate_more_prompt,
          status: status,
        },
      );

      clearInterval(interval);
      dispatch(
        setIdeaPersonalizationLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setIdeaPersonalizationLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setIdeaPersonalizationData(data.data));
      }, 1000);
    } catch {
      clearInterval(interval);
      dispatch(
        setIdeaPersonalizationLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleSetIdeaPersonalizationData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setIdeaPersonalizationData(data));
  };
