import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { KeyInsightSchema } from "@/Types/key_insight/schema";

const updateKeyInsight = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: KeyInsightSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/key-insight/submit/${id}`,
      {
        content: payload,
        archetypeId: archetypeId,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateKeyInsight = () => {
  return useMutation<
    any,
    Error,
    {
      payload: KeyInsightSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyInsight,
  });
};

export { useUpdateKeyInsight };
