import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import {
  setOpportunityAmongCompetitionData,
  setOpportunityAmongCompetitionLoading,
} from "@/Context/actions/opportunity_among_competition";
import { SubsectionDataType } from "@/Types/projects";
import { fetcher } from "@/Services/axios";

export const handleGenerateOpportunityAmongCompetition =
  (dispatch: Dispatch<ProjectAction>) =>
  async (
    project_slug: string,
    regenerate_prompt: string | null = null,
    status?: string,
  ) => {
    try {
      dispatch(
        setOpportunityAmongCompetitionLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setOpportunityAmongCompetitionLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/opportunity-among-competition/generate`,
        {
          generate_more: regenerate_prompt,
          status,
        },
      );
      clearInterval(interval);
      dispatch(
        setOpportunityAmongCompetitionLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setOpportunityAmongCompetitionLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setOpportunityAmongCompetitionData(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setOpportunityAmongCompetitionLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleEditedOpportunityAmongCompetition =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, history_id: number, content: string) => {
    try {
      const { data } = await fetcher.put(
        `/${project_slug}/history/${history_id}`,
        {
          content: content,
        },
      );

      dispatch(setOpportunityAmongCompetitionData(data.data));
    } catch (err) {
      // TODO: dispatch error state
    }
  };

export const handleSetOpportunityAmongCompetitionData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setOpportunityAmongCompetitionData(data));
  };
