import React from 'react';

import ModalCenter from '@/Components/Modals/Center';

const RegenerateModal: React.FC<{
  section: string;
  isRegenerating: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}> = ({ isOpen, isRegenerating, section, onClose, handleSubmit }) => {
  const btnDisabledClass = isRegenerating
    ? 'bg-placeholder-redx border-placeholder-redx cursor-not-allowed'
    : `bg-blue-redx border-blue-redx cursor-pointer`;

  return (
    <ModalCenter isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full max-w-xl">
        <div className="flex transition-transform duration-500">
          <div className="w-full shrink-0 px-40 py-50 md:px-30 md:py-50">
            <div className="mb-40">
              <h1 className="mb-16 text-center text-30 font-semibold text-black-redx">
                Your Previous Results Will Be Lost
              </h1>
              <p className="text-center text-15 text-grey-redx">
                Modifying this section will impact other related sections. The
                system will regenerate and your previous output cannot be
                recovered.
              </p>
            </div>
            <div className="flex w-full flex-col gap-y-15">
              <button
                className={`w-full py-15 ${btnDisabledClass} rounded-10 font-semibold text-white`}
                disabled={isRegenerating}
                id={`btn-regenerate-${section}`}
                onClick={handleSubmit}
              >
                {isRegenerating ? 'Loading...' : 'Proceed Anyway'}
              </button>
              <button
                className="w-full rounded-10 border-2 border-blue-redx bg-transparent py-15 font-semibold text-blue-redx"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default RegenerateModal;
