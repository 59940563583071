import Wrapper from "@/Pages/IdeaExpansion/Wrapper";

const IdeaExpansionRoutes = [
  {
    path: "/:project_slug/idea-expansion",
    element: <Wrapper />,
  },
];

export default IdeaExpansionRoutes;
