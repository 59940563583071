import Print from "@/Pages/OnePageStrategy/Print/Index";
import Wrapper from "@/Pages/OnePageStrategy/Wrapper";

const OnePageStrategyRoutes = [
  {
    path: "/:project_slug/one-page-strategy",
    element: <Wrapper />,
  },
  {
    path: "/:project_slug/one-page-strategy/print",
    element: <Print />,
  },
];

export default OnePageStrategyRoutes;
