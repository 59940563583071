import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import MarkdownView from '@/Components/MardownView';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateSelectedChallengeAndTask } from '@/Hooks/react-query/challenge-and-task';
import type { ChallengeProps } from '@/Types/challenge_and_task';
import type { ChallengeSchema } from '@/Types/challenge_and_task/schema';
import { Challenge } from '@/Types/challenge_and_task/schema';
import type { ProjectProps } from '@/Types/projects';

export interface CardHandles {
  submitForm: () => void;
}

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    project: ProjectProps;
    totalData: number;
    isFetching?: boolean;
    item: ChallengeProps;
    index: number;
    isEditing: boolean;
    isSelected: boolean;
    isAdding?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess: (data: ChallengeSchema, id: number, index: number) => void;
    onSelectSuccess: (data: any) => void;
  }
>(
  (
    {
      id,
      project,
      totalData,
      item,
      isFetching = false,
      index,
      isSelected,
      isEditing,
      isAdding = false,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedChallengeAndTask();
    const { refetch } = useDifferentArchetypes(project);

    const { handleSubmit, reset, control } = useForm<ChallengeSchema>({
      resolver: zodResolver(Challenge),
      defaultValues: {
        challenge: item.challenge ?? '',
        communication_task: item.communication_task ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: ChallengeSchema) => {
      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (!isEditing) {
        reset({
          challenge: item.challenge ?? '',
          communication_task: item.communication_task ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data) => {
            const updatedItem = data.data;
            refetch();
            onSelectSuccess(updatedItem);
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {!isFetching && isAdding && (
            <h1 className="text-15 font-bold">
              New Challenge & Communication Task
            </h1>
          )}

          {!isFetching && !isAdding && (
            <h1 className="text-15 font-bold">
              Challenge & Communication Task {index + 1} of {totalData}
            </h1>
          )}

          {isFetching && (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          )}
        </div>
        <div
          className={`flex flex-col gap-24 p-24 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          <div className="flex flex-col gap-10">
            <h3 className="text-12 font-semibold leading-14">Challenge</h3>
            {isEditing ? (
              <Controller
                control={control}
                name="challenge"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextAreaInput
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={error?.message}
                    isFocused={false}
                    onChange={onChange}
                    placeholder="Input the challenge here"
                    rows={2}
                    value={value}
                  />
                )}
              />
            ) : (
              !isFetching && (
                <div className="min-h-50 py-4 text-14">
                  <MarkdownView content={item.challenge} />
                </div>
              )
            )}
            {isFetching && (
              <div className="min-h-50 py-4 text-14">
                <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-10">
            <h3 className="text-12 font-semibold leading-14">
              Communication Task
            </h3>
            {isEditing ? (
              <Controller
                control={control}
                name="communication_task"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextAreaInput
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={error?.message}
                    isFocused={false}
                    onChange={onChange}
                    placeholder="Input the communication task here"
                    rows={2}
                    value={value}
                  />
                )}
              />
            ) : (
              !isFetching && (
                <div className="min-h-50 py-4 text-14">
                  <MarkdownView content={item.communication_task} />
                </div>
              )
            )}
            {isFetching && (
              <div className="min-h-50 py-4 text-14">
                <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id !== -1 && isCanSelect && (
            <SelectButton
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="challenge-and-task"
            />
          )}
        </div>
      </div>
    );
  },
);

export default Card;
