import { RadioGroup } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import PencilGenerate from '@/Components/Icon/pencil-generate';
import MarkdownView from '@/Components/MardownView';
import ModalCenter from '@/Components/Modals/Center';
import { useUpdateSelectedFramework } from '@/Hooks/react-query/submission/useUpdateSelectedFramework';
import type { SelectedFramework } from '@/Types/key_touch_point';
import type { ProjectProps } from '@/Types/projects';

const SelectingFunnel: React.FC<{
  isOpen: boolean;
  defaultSelected: string;
  onRegenerate: (data: any) => void;
  onClose: () => void;
  project: ProjectProps;
}> = ({ isOpen, onClose, onRegenerate, project, defaultSelected }) => {
  const [selected, setSelected] = useState('');
  const { mutate, isPending } = useUpdateSelectedFramework();
  const handleClick = () => {
    mutate(
      {
        projectSlug: project.slug,
        selectedFramework: selected as SelectedFramework,
      },
      {
        onSuccess: (data: any) => {
          Cookies.set('selected_framework', selected, { expires: 1 / 5760 });
          onRegenerate(data);
        },
      },
    );
  };

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const options = [
    {
      value: 'marketing_funnel',
      title: 'Marketing Funnel',
      description:
        'The marketing funnel guides potential customers from **awareness** to advocacy, focusing on attracting attention, encouraging **consideration**, driving **conversions**, fostering **purchase intent**, and promoting brand **advocacy.**',
    },
    {
      value: 'sdrcp',
      title: 'S.D.R.C.P',
      description:
        "The S.D.R.C.P framework ensures a product's online presence is easily **searchable**, **discoverable** across various channels, **reachable** through multiple touch points, **credible** through trust-building measures, and simple to **purchase.**",
    },
  ];

  const buttonNextDisabledClass =
    selected === '' || isPending
      ? 'bg-placeholder-redx border-placeholder-redx cursor-not-allowed text-white'
      : `bg-button-gradient-90 cursor-pointer`;

  return (
    <ModalCenter hasButtonClose={false} isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full max-w-3xl overflow-scroll p-20 md:h-538 md:max-w-sm">
        <div className="flex transition-transform duration-500">
          <div className="w-full shrink-0 p-10">
            <div className="mb-20 max-w-2xl">
              <h1 className="mb-4 text-35 font-bold text-black-redx md:text-24">
                Select your desired framework
              </h1>
              <p className="text-15 text-grey-redx">
                The framework you select will shape the touch points output
                based on the chosen archetype in audience archetype.
              </p>
            </div>
            <RadioGroup
              className="my-20 border-t-1 border-gray-300"
              onChange={setSelected}
              value={selected}
            >
              {options.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  className={({ active, checked }) =>
                    `relative flex cursor-pointer rounded-m px-4 focus:outline-none
                      ${
                        checked
                          ? 'bg-blue-50 border-blue-500'
                          : 'bg-white border-gray-300'
                      } 
                      ${active ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`
                  }
                  value={option.value}
                >
                  {({ checked }) => (
                    <>
                      <div
                        className={`flex items-center py-20 ${
                          checked
                            ? 'border-y-3 border-blue-redx'
                            : 'border-b-1 border-gray-300'
                        }`}
                      >
                        <div className="p-20">
                          <span
                            className={`flex size-20 items-center justify-center justify-items-center rounded-full border-1 bg-white ${
                              checked ? 'border-blue-redx' : 'border-gray-300'
                            }`}
                          >
                            {checked && (
                              <Icon
                                className="size-12 text-blue-redx"
                                icon="bxs:circle"
                              />
                            )}
                          </span>
                        </div>
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className="text-30 font-bold text-gray-900 md:text-24"
                          >
                            {option.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`text-black-redx `}
                          >
                            <MarkdownView content={option.description} />
                          </RadioGroup.Description>
                        </div>
                      </div>
                      <div className="absolute right-0 flex items-center pr-4">
                        <span className={`size-4 rounded-full `} />
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div className="flex w-full flex-row gap-24 py-20 md:flex-col">
              <button
                className="w-1/2 rounded-10 border-2 border-blue-redx bg-transparent py-15 text-18 font-semibold text-blue-redx md:w-full"
                onClick={onClose}
              >
                Close
              </button>
              <button
                className={`flex w-1/2 flex-row items-center justify-center gap-8 rounded-lg border-2 px-24 py-15 text-18 font-semibold text-white md:w-full ${buttonNextDisabledClass}`}
                disabled={isPending}
                id="btn-regenerate-key-touch-point"
                onClick={handleClick}
              >
                Regenerate Key Touch Point <PencilGenerate />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default SelectingFunnel;
