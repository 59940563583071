/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { forwardRef, useContext, useState } from 'react';
import type { FieldErrors, UseFormSetValue } from 'react-hook-form';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import TextInput from '@/Components/TextInput';
import { ProjectContext } from '@/Context/ProjectContext';
import type { SubmissionFormPayload } from '@/Types/form';

interface ProductInformationProps {
  data: {
    product_name: string;
    brand_name: string;
    product_links: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const ProductInformation = forwardRef<HTMLDivElement, ProductInformationProps>(
  (props, ref) => {
    const {
      data,
      setData,
      errors,
      setError,
      isActive,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
      isEditingCount,
      setIsEditingCount,
    } = props;
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleIsEditing = (isEdit: boolean) => {
      setIsEditing(isEdit);
      if (setIsEditingCount) {
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        setIsEditingCount(newCount);
      }
    };

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(0);
      if (!isNotError) {
        toggleIsEditing(true);
        return;
      }
      toggleIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Product Information
          </h1>
          {!isFetching &&
            state.submission.is_product_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <button
                className=" inline-flex cursor-pointer items-center justify-center gap-8"
                id="btn-edit-submission-product-information"
                onClick={() => toggleIsEditing(true)}
              >
                <span className="text-14 font-semibold text-blue-redx">
                  Edit
                </span>
                <Icon
                  className="size-16 text-blue-redx"
                  icon="lucide:pencil-line"
                />
              </button>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className="inline-flex gap-8">
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                id="btn-cancel-edit-submission-product-information"
                onClick={() => {
                  fetchData();
                  toggleIsEditing(false);
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-16" icon="lucide:x" />
              </button>

              <button
                className="inline-flex cursor-pointer items-center gap-x-4"
                id="btn-edit-submission-product-information"
                onClick={() => setIsModalOpen(true)}
              >
                <p className="text-14 font-semibold text-blue-redx">Save</p>
                <Icon className="size-16 text-blue-redx" icon="lucide:save" />
              </button>
            </div>
          )}
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="product_name"
              value="Product Name"
            />

            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                autoComplete="product_name"
                className="mt-1 block w-full !text-14"
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.product_name?.message}
                id="product_name"
                isFocused={false}
                name="product_name"
                onChange={(e) => {
                  setData('product_name', e.target.value);
                  setError('product_name', { message: '' });
                }}
                placeholder="Input your product name here"
                type="text"
                value={data.product_name}
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {data.product_name.length > 0 ? data.product_name : '-'}
              </p>
            )}

            <InputError
              className="mt-2"
              message={errors.product_name?.message}
            />
          </div>
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="brand_name"
              value="Brand Name"
            />
            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                autoComplete="brand_name"
                className="mt-1 block w-full !text-14"
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.brand_name?.message}
                id="brand_name"
                isFocused={false}
                name="brand_name"
                onChange={(e) => {
                  setData('brand_name', e.target.value);
                  setError('brand_name', { message: '' });
                }}
                placeholder="Input your brand name here"
                type="text"
                value={data.brand_name}
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {data.brand_name.length > 0 ? data.brand_name : '-'}
              </p>
            )}

            <InputError className="mt-2" message={errors.brand_name?.message} />
          </div>
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="product_links"
              value="Product Related Links (Optional)"
            />
            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                autoComplete="product_links"
                className="mt-1 block w-full !text-14"
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.product_links?.message}
                id="product_links"
                isFocused={false}
                name="product_links"
                onChange={(e) => setData('product_links', e.target.value)}
                placeholder="Input your product related links here"
                type="text"
                value={data.product_links}
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {data.product_links.length > 0 ? data.product_links : '-'}
              </p>
            )}

            <InputError
              className="mt-2"
              message={errors.product_links?.message}
            />
          </div>
        </div>
        <RegenerateModal
          handleSubmit={submitForm}
          isOpen={isModalOpen}
          isRegenerating={false}
          onClose={() => {
            fetchData();
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          section="product-information"
        />
      </div>
    );
  },
);

export default ProductInformation;
