import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { Archetype } from "@/Types/audience-archetype";

const updateArchetype = async ({
  payload,
  id,
  projectSlug,
}: {
  payload: Archetype;
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/audience-archetype/submit/${id}`,
      {
        content: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateArchetype = () => {
  return useMutation<
    any,
    Error,
    { payload: Archetype; id: number; projectSlug: string }
  >({
    mutationFn: updateArchetype,
  });
};

export { useUpdateArchetype };
