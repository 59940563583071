import Redirect from "@/Pages/Submission/Redirect";
import Wrapper from "@/Pages/Submission/Wrapper";
import { PageProps } from "@/Types";
import { CategoryProps } from "@/Types/projects";

const dummyPageProps: PageProps<{
  categories: CategoryProps[];
  subcategories: CategoryProps[];
}> = {
  categories: [],
  subcategories: [],
  maxGenerateLimit: 200,
};

const SubmissionRoutes = [
  {
    path: "/:project_slug",
    element: <Redirect />,
  },
  {
    path: "/:project_slug/submission",
    element: <Wrapper {...dummyPageProps} />,
  },
];

export default SubmissionRoutes;
