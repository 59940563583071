import { ProjectProps } from "@/Types/projects";
import { fetcher } from "@/Services/axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const getLists = async (project: ProjectProps | string) => {
  const project_slug = typeof project === "string" ? project : project.slug;
  const url = `/${project_slug}/key-insight/lists`;
  try {
    const response = await fetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "An error occurred");
  }
};

const useKeyInsights = (
  project: ProjectProps | string,
): UseQueryResult<any, Error> => {
  const project_slug = typeof project === "string" ? project : project.slug;
  return useQuery<any, Error>({
    queryKey: ["keyInsights", project_slug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(project),
    refetchOnMount: false, // Disable refetch on remount
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
    staleTime: Infinity, // Keep data fresh indefinitely (no refetching)
  });
};

export default useKeyInsights;
