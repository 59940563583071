import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useKeyTouchPoint from '@/Hooks/react-query/key-touch-point/useKeyTouchPoint';
import { useUpdateKeyTouchPoint } from '@/Hooks/react-query/key-touch-point/useUpdateKeyTouchPoint';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type {
  ArchetypeKeyTouchPointData,
  Content,
  Recommendation,
  RecommendationContent,
  SelectedFramework,
} from '@/Types/key_touch_point';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';
import SelectedFrameworkCard from './Partials/SelectedFrameworkCard';
import SelectingFunnel from './Partials/SelectingFunnel';

interface KeyTouchPointIndexProps {
  project: ProjectProps;
}
const Index = ({ project }: KeyTouchPointIndexProps) => {
  const [searchParams] = useSearchParams();
  const archetype = searchParams.get('archetype');
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [parsedData, setParsedData] = useState<ArchetypeKeyTouchPointData[]>(
    [],
  );
  const [editedData, setEditedData] = useState<ArchetypeKeyTouchPointData[]>(
    [],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const maxGenerateLimit = project.max_generated_data;
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [selectedArchetypeId, setSelectedArchetypeId] = useState<number>(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>('');
  const [selectedFramework, setSelectedFramework] = useState<SelectedFramework>(
    project.submissions[0].selected_framework,
  );
  const [selectedKeyTouchPointContent, setSelectedKeyTouchPointContent] =
    useState<Content[]>();
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const navigate = useNavigate();
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const { roles } = useRolesBadge(project, user);
  const isEditor =
    roles.includes('Owner') ||
    roles.includes('Strategist') ||
    roles.includes('Creative');

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabsArchetypeRef = useRef([]);
  const tabsCategoryTypeRef = useRef([]);
  const [totalSelectedKeyTouchPoint, setTotalSelectedKeyTouchPoint] =
    useState<number>(0);
  const [totalSelectedArchetype, setTotalSelectedArchetype] =
    useState<number>(0);
  const [totalArchetype, setTotalArchetype] = useState<number>(0);

  const resetState = () => {
    setParsedData([]);
    setEditedData([]);
    dispatch(saveSubInformation(''));
    setSelectedArchetypeId(0);
    setSelectedFunnel('');
    setSelectedKeyTouchPointContent([]);
  };

  const {
    isKeyTouchPoint,
    isIdeaExpansion,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const {
    isLoading,
    data,
    refetch: refetchTouchPoint,
  } = useKeyTouchPoint(project.slug);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { handleGenerateKeyTouchPoint, handleGenerateIdeaExpansion } =
    useGenerate(dispatch);

  const { mutate, isPending: isSubmitting } = useUpdateKeyTouchPoint();

  const handleSelectedFramework = (value: string) => {
    if (value === 'marketing_funnel') return 'Marketing Funnel';
    return 'S.D.R.C.P';
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    resetState();
    handleGenerateKeyTouchPoint()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const routeOptionValue: RouteOption = {
    label: 'Next to Creative Idea',
    isActive: true,
    isGenerate: false,
    isInactive: true,
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));

    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/one-page-strategy`),
      }),
    );
    const newFramework = Cookies.get('selected_framework');
    setSelectedFramework(
      (newFramework as SelectedFramework) === undefined
        ? project.submissions[0].selected_framework
        : (newFramework as SelectedFramework),
    );
  }, []);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleNext = () => {
    if (activeTab < parsedData!.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      setSelectedArchetypeId(parsedData[nextIndex].id);
      setSelectedFunnel(parsedData[nextIndex].key_touch_points[0].funnel ?? '');
      if (
        tabsArchetypeRef.current[nextIndex] &&
        'scrollIntoView' in tabsArchetypeRef.current[nextIndex]
      ) {
        (tabsArchetypeRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      setSelectedArchetypeId(parsedData[prevIndex].id);
      setSelectedFunnel(parsedData[prevIndex].key_touch_points[0].funnel ?? '');
      if (
        tabsArchetypeRef.current[prevIndex] &&
        'scrollIntoView' in tabsArchetypeRef.current[prevIndex]
      ) {
        (tabsArchetypeRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: 'Key Touch Point',
    value: selectedFramework,
    section: 'key_touch_point',
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  useEffect(() => {
    const totalArchetype = data?.total_archetype || 0;
    const totalSelected = data?.total_selected || 0;
    const totalSelectedPerArchetype = data?.total_selected_per_archetype || 0;
    setTotalSelectedArchetype(totalSelectedPerArchetype);
    setTotalSelectedKeyTouchPoint(totalSelected);
    setTotalArchetype(totalArchetype);

    if (data) {
      const {
        data: keyTouchPoints,
        sources,
        total_regenerate: totalRegenerate,
        selected_framework: selectedFrameworkResponse,
        is_querying: isQuerying,
      } = data;

      if (isQuerying) {
        setIsGenerate(true);
        setParsedData([]);
        setEditedData([]);
        setSelectedFramework(project.submissions[0].selected_framework);
        setSelectedArchetypeId(0);
        setSelectedFunnel('');
        return;
      }

      setParsedData(keyTouchPoints);
      setEditedData(keyTouchPoints);

      (selectedFramework !== selectedFrameworkResponse ||
        selectedArchetypeId === 0) &&
        setSelectedArchetypeId(keyTouchPoints[0]?.id ?? 0);

      if (
        selectedFramework !== selectedFrameworkResponse ||
        selectedFunnel === ''
      ) {
        setSelectedFunnel(keyTouchPoints[0]?.key_touch_points[0]?.funnel ?? '');
        setActiveCategoryTab(0);
      }

      setTotalRegenerate(totalRegenerate);
      setSources(sources);

      setSelectedFramework(selectedFrameworkResponse);

      dispatch(saveSubInformation(''));
      setIsGenerate(false);
    }
  }, [
    data,
    isKeyTouchPoint,
    differentArchetypes?.is_different_archetype.key_touch_points,
  ]);

  useEffect(() => {
    if (!isKeyTouchPoint.status) {
      dispatch(saveInformation(`0 Touchpoint in 0 Archetype are selected`));
    }

    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.key_touch_points
    ) {
      if (!isGenerate && !isPendingHistory && !isKeyTouchPoint.isQuerying) {
        setIsGenerate(true);
        resetState();
        handleGenerateKeyTouchPoint()(project.slug);
      }
    }

    if (isKeyTouchPoint.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isPendingHistory,
    isPendingDiffArch,
    isGenerate,
    differentArchetypes,
    isKeyTouchPoint,
  ]);

  useEffect(() => {
    !isKeyTouchPoint.isQuerying && !isPendingHistory && refetchTouchPoint();

    isKeyTouchPoint.isQuerying && !isPendingHistory && setIsGenerate(true);
  }, [
    isGenerate,
    isKeyTouchPoint,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.key_touch_points,
  ]);

  const handleTitleFunnel = (value: string) => {
    return value
      .replace(/\bcredible\b/g, 'credibility')
      .replace(/\bpurchase\b/g, 'purchasability')
      .replace(/able\b/g, 'ability')
      .replace('_funnel', '')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSubmit = (data: Recommendation, id: number, index: number) => {
    const payload = {
      recommendation: {
        ...data,
      },
    } as RecommendationContent;

    mutate(
      {
        payload,
        id,
        archetypeId: selectedArchetypeId,
        funnel: selectedFunnel,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetchTouchPoint();
          }, 700);

          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }
        },
      },
    );
  };

  const handleSave = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleChangeFramework = (data: any) => {
    const newFramework = Cookies.get('selected_framework');
    if ((newFramework as SelectedFramework) !== undefined) {
      setSelectedFramework(newFramework as SelectedFramework);
    }

    setModalFramework(false);
    resetState();

    if (data.need_generate) {
      setIsGenerate(true);
      handleGenerateKeyTouchPoint()(project.slug);
    } else {
      queryClient.resetQueries({
        queryKey: ['keyTouchPoint', project.slug],
        exact: true,
      });

      refetchTouchPoint();
    }
  };

  useEffect(() => {
    if (
      isAdding ||
      isEditing ||
      differentArchetypes?.is_different_archetype.key_touch_points ||
      parsedData.length === 0 ||
      isKeyTouchPoint.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.key_touch_points,
    parsedData,
    isKeyTouchPoint.isQuerying,
  ]);

  useEffect(() => {
    if (archetype) {
      const archetypeId = Number(archetype);
      if (!Number.isNaN(archetypeId)) {
        const index = parsedData.findIndex((item) => item.id === archetypeId);
        const filterData = parsedData[index];
        if (filterData) {
          setSelectedArchetypeId(filterData.id);
          setSelectedFunnel(filterData.key_touch_points[0]?.funnel ?? '');
          setActiveTab(index);
          if (
            tabsArchetypeRef.current[index] &&
            'scrollIntoView' in tabsArchetypeRef.current[index]
          ) {
            (tabsArchetypeRef.current[index] as Element).scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
      }
    }
  }, [archetype, parsedData]);

  useEffect(() => {
    const hasMany = totalSelectedKeyTouchPoint > 1;
    dispatch(
      saveInformation(
        `${totalSelectedKeyTouchPoint} ${
          hasMany ? 'Touchpoints' : 'Touchpoint'
        }  in ${totalArchetype} Archetype are selected`,
      ),
    );
  }, [totalSelectedKeyTouchPoint, totalArchetype]);

  const checkSelectedArchetype = () => {
    const totalSelectedPerArchetype = parsedData.map((archetype) => {
      const totalSelected = archetype.key_touch_points.reduce(
        (total, touchPoint) => {
          const selectedInTouchPoint = touchPoint.content!.filter(
            (content) => content.is_selected === 1,
          ).length;
          return total + selectedInTouchPoint;
        },
        0,
      );

      return {
        archetype_id: archetype.id,
        total_selected_touchpoints: totalSelected,
      };
    });

    return totalSelectedPerArchetype
      .map((_, index) =>
        totalSelectedPerArchetype[index].total_selected_touchpoints === 0
          ? index
          : -1,
      )
      .filter((index) => index !== -1);
  };

  const hasSelectedKeyTouchPoint = () => {
    const indexesWithZeroSelected = checkSelectedArchetype();

    if (indexesWithZeroSelected.length) {
      setErrorTabIndex(indexesWithZeroSelected);
      const namesWithoutSelectedKeyInsight = indexesWithZeroSelected
        .map((item) => `Archetype ${item + 1}`)
        .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Key touch point for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    dispatch(saveSubInformation(''));
    await handleGenerateIdeaExpansion()(project.slug, null, 'regenerate');
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-expansion`);
  };

  useEffect(() => {
    if (parsedData.length) {
      setSelectedIndex([]);
      const keyTouchPointContent =
        parsedData
          .find((item) => item.id === selectedArchetypeId)
          ?.key_touch_points?.find((item) => item.funnel === selectedFunnel)
          ?.content ?? [];

      const selectedDataWithIndex = keyTouchPointContent
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => item.is_selected === 1);

      if (selectedDataWithIndex.length) {
        const indices = selectedDataWithIndex.map(({ index }) => index);
        setSelectedIndex(indices);

        if (errorTabIndex?.length) {
          const updatedErrorTabIndex =
            errorTabIndex.filter((tabIndex) => tabIndex !== activeTab) ?? [];

          const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
            .map((index) => `Archetype ${index + 1}`)
            .join(', ');

          dispatch(saveSubInformation(''));

          namesWithoutSelectedKeyInsight &&
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please select Key touch point for for ${namesWithoutSelectedKeyInsight}`,
              ),
            );

          setErrorTabIndex(updatedErrorTabIndex);
        }
      }

      setSelectedKeyTouchPointContent(keyTouchPointContent);
    }

    routeOptionValue.isInactive =
      isEditor &&
      (isGenerate ||
        isKeyTouchPoint.isQuerying ||
        !isKeyTouchPoint.status ||
        totalSelectedArchetype < totalArchetype);

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        label:
          isEditor && isIdeaExpansion.isRegenerated
            ? 'Regenerate Idea Expansion'
            : 'Next to Creative Idea',
        isGenerate: isEditor ? isIdeaExpansion.isRegenerated : false,
        onClick: () => {
          if (
            !isKeyTouchPoint.status ||
            isGenerate ||
            isKeyTouchPoint.isQuerying
          ) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, key touch point is still generating`,
              ),
            );
            return;
          }

          if (isEditor) {
            if (isAdding || isEditing) {
              dispatch(
                saveSubInformation(`{text-error-redx} Please do save first`),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            const hasError = hasSelectedKeyTouchPoint();

            if (!hasError) {
              if (isIdeaExpansion.isRegenerated) {
                setShowRegenerateModal(true);
                return;
              }

              navigate(`/${project.slug}/creative-idea`);
            }
            return;
          }
          navigate(`/${project.slug}/creative-idea`);
        },
      }),
    );
  }, [
    isAdding,
    isEditing,
    parsedData,
    selectedArchetypeId,
    selectedFunnel,
    isKeyTouchPoint,
    isGenerate,
    totalArchetype,
    totalSelectedArchetype,
    isIdeaExpansion,
    isEditor,
    isAdding,
    isEditing,
  ]);

  return (
    <>
      {isGenerate ? (
        <GenerateLoading
          estimateTimeFrom={2}
          estimateTimeTo={6}
          progress={progressPortion({
            progress: Number((state.keyTouchPoint.progress / 2).toFixed(0)),
            isQuerying: isKeyTouchPoint.isQuerying,
            isContentAvailable: parsedData!.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isKeyTouchPoint.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="w-fit flex flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Key Touch Point - {handleSelectedFramework(selectedFramework)}
              </h1>
              <span className="text-15 font-normal text-grey-redx">
                Please select{' '}
                <span className="font-bold text-black-redx">one or more</span>{' '}
                touchpoint for{' '}
                <span className="font-bold text-black-redx">
                  each archetype,{' '}
                </span>
                or add by clicking &apos;Add Touchpoint Manually&apos;.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="key-touch-point"
              />
            )}
          </div>

          <SelectedFrameworkCard
            onChangeFramework={() => setModalFramework(true)}
            selectedFramework={handleSelectedFramework(selectedFramework)}
          />

          <TabGroup
            className="mt-35 pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <div className="relative flex items-center">
              {!isLoading && parsedData!.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === 0}
                  onClick={handlePrev}
                >
                  <Icon className="text-25" icon="mingcute:left-line" />
                </button>
              )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading &&
                  parsedData.map((archetype, index) => (
                    <Tab
                      key={index}
                      ref={(el: HTMLElement | null) => {
                        if (el) {
                          (tabsArchetypeRef.current as HTMLElement[])[index] =
                            el;
                        }
                      }}
                      className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                      disabled={isEditing || isAdding}
                      onClick={() => setSelectedArchetypeId(archetype.id)}
                    >
                      <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                        <div className="flex flex-col gap-8">
                          {`Archetype ${index + 1}`}
                          <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                            {archetype?.archetype_content?.archetype_name}
                          </div>
                        </div>
                        {errorTabIndex && errorTabIndex.includes(index) && (
                          <Icon
                            className="text-25 text-error-redx"
                            icon="mingcute:information-line"
                          />
                        )}
                      </div>
                    </Tab>
                  ))}

                {isLoading &&
                  Array.from({ length: 3 }).map((_, index) => (
                    <button
                      key={`dummy-${index}`}
                      className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                    >
                      <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                    </button>
                  ))}
              </TabList>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === parsedData!.length - 1}
                  onClick={handleNext}
                >
                  <Icon className="text-25" icon="mingcute:right-line" />
                </button>
              )}
            </div>
            <TabPanels className="py-24">
              {!isLoading ? (
                parsedData.map((archetype, index) => (
                  <TabPanel key={index}>
                    <div className="pt-5">
                      <SelectedCard
                        data={[
                          {
                            title: 'Archetype Name',
                            content:
                              archetype.archetype_content?.archetype_name,
                          },
                          {
                            title: 'Demographic',
                            content: archetype.archetype_content?.demographics,
                          },
                          {
                            title: 'Occupation',
                            content: archetype.archetype_content?.occupation,
                          },
                          {
                            title: 'Lifestyle',
                            content: archetype.archetype_content?.lifestyle,
                          },
                          {
                            title: 'Behavior',
                            content: archetype.archetype_content?.behavior,
                          },
                          {
                            title: 'Need & Challenges',
                            content:
                              archetype.archetype_content?.needs_and_challenges,
                          },
                          {
                            title: 'Potential Buying Motives',
                            content:
                              archetype.archetype_content
                                ?.potential_buying_motives,
                          },
                          {
                            title: 'Current Trends',
                            content:
                              archetype.archetype_content?.current_trends,
                          },
                          {
                            title: 'Source of Information',
                            content:
                              archetype.archetype_content
                                ?.source_of_information,
                          },
                          {
                            title: 'Online Search Behavior',
                            content:
                              archetype.archetype_content
                                ?.online_search_behavior,
                          },
                          {
                            title: 'Purchase Frequency',
                            content:
                              archetype.archetype_content?.purchase_frequency,
                          },
                          {
                            title: 'Preferred Sales Channels',
                            content:
                              archetype.archetype_content
                                ?.preferred_sales_channels,
                          },
                        ]}
                        title={`Archetype Detail - ${archetype.archetype_content?.archetype_name}`}
                      />
                      <div className="mt-20" />

                      <TabGroup
                        className="flex w-full flex-row gap-24"
                        defaultIndex={activeCategoryTab}
                        onChange={setActiveCategoryTab}
                        selectedIndex={activeCategoryTab}
                      >
                        <TabList className="flex w-2/3 flex-col items-start justify-start border-r-1 border-r-stroke-redx">
                          {parsedData
                            .filter((item) => item.id === selectedArchetypeId)
                            .map((archetype, indexArchetype) => {
                              return archetype.key_touch_points.map(
                                (funnel, indexFunnel) => {
                                  const value =
                                    funnel?.total_selected_touchpoint ?? 0;

                                  return (
                                    <Tab
                                      key={`${indexArchetype}-${indexFunnel}`}
                                      ref={(el: HTMLElement | null) => {
                                        if (el) {
                                          (
                                            tabsCategoryTypeRef.current as HTMLElement[]
                                          )[indexArchetype] = el;
                                        }
                                      }}
                                      className="w-full py-10 text-start text-16 font-normal leading-20 text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
                                      disabled={isEditing || isAdding}
                                      onClick={() => {
                                        setSelectedFunnel(funnel!.funnel ?? '');
                                      }}
                                    >
                                      <p className="font-normal">
                                        {handleTitleFunnel(
                                          funnel?.funnel ?? '',
                                        )}
                                      </p>
                                      <p className="text-12 leading-14 text-grey-redx">
                                        {value} selected
                                      </p>
                                    </Tab>
                                  );
                                },
                              );
                            })}
                        </TabList>
                        <TabPanels className="flex w-4/5 grow flex-col">
                          <div key={selectedFunnel}>
                            {selectedKeyTouchPointContent?.length && (
                              <SliderContainer
                                goToIndex={goToIndex}
                                isAdding={isAdding}
                                isEditing={isEditing}
                                length={selectedKeyTouchPointContent.length}
                                onActiveIndex={(index) => setActiveIndex(index)}
                                selectedIndexes={selectedIndex}
                              >
                                {!isAdding &&
                                  selectedKeyTouchPointContent.map(
                                    (content, index) => (
                                      <div className="mb-24 mt-10">
                                        <Card
                                          key={index}
                                          ref={(el) =>
                                            (cardRefs.current[index] = el)
                                          }
                                          id={content.id ?? -1}
                                          index={index}
                                          isEditing={isEditing}
                                          isEditor={isEditor}
                                          isFetching={false}
                                          isSelected={content.is_selected === 1}
                                          item={
                                            content.recommendation_content
                                              ?.recommendation!
                                          }
                                          onHandleSubmit={handleSubmit}
                                          onSuccessSelected={() => {
                                            refetchTouchPoint();
                                          }}
                                          project={project}
                                          selectedFramework={
                                            selectedFramework ===
                                            'marketing_funnel'
                                              ? `${handleTitleFunnel(
                                                  selectedFunnel,
                                                )} funnel`
                                              : handleTitleFunnel(
                                                  selectedFunnel,
                                                )
                                          }
                                          totalData={
                                            selectedKeyTouchPointContent.length
                                          }
                                        />
                                      </div>
                                    ),
                                  )}
                                {isAdding && (
                                  <div className="my-24">
                                    <Card
                                      key={0}
                                      ref={(el) => (cardRefs.current[0] = el)}
                                      id={-1}
                                      index={
                                        selectedKeyTouchPointContent.length
                                      }
                                      isEditing={isEditing || isAdding}
                                      isEditor={isEditor}
                                      isFetching={false}
                                      isSelected={false}
                                      item={{
                                        reason: '',
                                        media_types: '',
                                        idea_example: '',
                                        success_measurement: '',
                                        digital_recommendation: '',
                                        keyword: '',
                                        trending_topics: '',
                                      }}
                                      onHandleSubmit={handleSubmit}
                                      project={project}
                                      selectedFramework={
                                        selectedFramework === 'marketing_funnel'
                                          ? `${handleTitleFunnel(
                                              selectedFunnel,
                                            )} funnel`
                                          : handleTitleFunnel(selectedFunnel)
                                      }
                                    />
                                  </div>
                                )}
                              </SliderContainer>
                            )}
                          </div>

                          <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                            {isEditor &&
                              (!isAdding && !isEditing ? (
                                <button
                                  className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                                  onClick={toggleIsAdding}
                                >
                                  Add {handleTitleFunnel(selectedFunnel)}{' '}
                                  Touchpoint Manually
                                  <Icon
                                    className="size-20"
                                    icon="lucide:plus"
                                  />
                                </button>
                              ) : (
                                <div />
                              ))}
                            <div className="flex items-center justify-end gap-x-15">
                              {isEditor &&
                                (isEditing || isAdding ? (
                                  <>
                                    <button
                                      className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                      onClick={
                                        isAdding
                                          ? toggleIsAdding
                                          : handleCancelEdit
                                      }
                                    >
                                      <span className="text-15 font-semibold">
                                        Cancel
                                      </span>
                                      <Icon
                                        className="size-20"
                                        icon="lucide:x"
                                      />
                                    </button>
                                    <button
                                      className={`inline-flex items-center gap-x-4 ${
                                        isSubmitting
                                          ? 'text-placeholder-redx'
                                          : 'text-blue-redx'
                                      } cursor-pointer`}
                                      disabled={isSubmitting}
                                      id="btn-edit-key-touch-point"
                                      onClick={handleSave}
                                    >
                                      <span className="text-15 font-semibold">
                                        Save
                                      </span>
                                      <Icon
                                        className="size-20"
                                        icon="lucide:save"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <EditButton toggleEditing={toggleIsEditing} />
                                ))}
                            </div>
                          </div>
                          <div className="flex w-full pb-60">
                            {!isLoading && sources && (
                              <SourcesList sources={sources} />
                            )}

                            {isLoading && (
                              <div className="inline-flex gap-10">
                                {Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                    key={index}
                                    className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </TabPanels>
                      </TabGroup>
                    </div>
                  </TabPanel>
                ))
              ) : (
                <TabPanel>
                  <div className="pt-5">
                    <div className="h-20 w-full animate-pulse rounded-full bg-soft-purple-redx" />
                    <div className="mt-20" />

                    <TabGroup
                      className="flex w-full flex-row gap-24"
                      onChange={setActiveCategoryTab}
                      selectedIndex={activeCategoryTab}
                    >
                      <TabList className="flex w-2/3 flex-col items-start justify-start border-r-1 border-r-stroke-redx">
                        {Array.from({ length: 3 }).map((_, index) => {
                          return (
                            <Tab
                              key={index}
                              ref={(el: HTMLElement | null) => {
                                if (el) {
                                  (
                                    tabsCategoryTypeRef.current as HTMLElement[]
                                  )[index] = el;
                                }
                              }}
                              className="w-full py-10 text-start text-16 font-normal leading-20 text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
                            >
                              <p className="font-normal">
                                <div className="h-20 w-2/3 animate-pulse rounded-full bg-soft-purple-redx" />
                              </p>
                              <p className="mt-10 text-12 leading-14 text-grey-redx">
                                <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
                              </p>
                            </Tab>
                          );
                        })}
                      </TabList>
                      <TabPanels className="flex w-4/5 grow flex-col">
                        <div className="-mt-24">
                          <SliderContainer
                            goToIndex={goToIndex}
                            isAdding={false}
                            isEditing={false}
                            length={3}
                            onActiveIndex={(index) => setActiveIndex(index)}
                            selectedIndexes={[]}
                          >
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div className="my-24">
                                <Card
                                  key={index}
                                  id={-1}
                                  index={-1}
                                  isEditor={false}
                                  isFetching
                                  item={{
                                    reason: '',
                                    media_types: '',
                                    idea_example: '',
                                    success_measurement: '',
                                    digital_recommendation: '',
                                  }}
                                  project={project}
                                  selectedFramework=""
                                />
                              </div>
                            ))}
                          </SliderContainer>
                        </div>

                        <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                                onClick={toggleIsAdding}
                              >
                                Add {handleTitleFunnel(selectedFunnel)}{' '}
                                Touchpoint Manually
                                <Icon className="size-20" icon="lucide:plus" />
                              </button>
                            ) : (
                              <div />
                            ))}
                          <div className="flex items-center justify-end gap-x-15">
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }
                                  >
                                    <span className="text-15 font-semibold">
                                      Cancel
                                    </span>
                                    <Icon className="size-20" icon="lucide:x" />
                                  </button>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                                    id="btn-edit-key-touch-point"
                                    onClick={() => {}}
                                  >
                                    <span className="text-15 font-semibold">
                                      Save
                                    </span>
                                    <Icon
                                      className="size-20"
                                      icon="lucide:save"
                                    />
                                  </button>
                                </>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className="flex w-full pb-60">
                          {!isLoading && sources && (
                            <SourcesList sources={sources} />
                          )}

                          {isLoading && (
                            <div className="inline-flex gap-10">
                              {Array.from({ length: 3 }).map((_, index) => (
                                <div
                                  key={index}
                                  className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </TabPanel>
              )}
            </TabPanels>
          </TabGroup>
        </div>
      )}

      <SelectingFunnel
        defaultSelected={selectedFramework}
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        onRegenerate={handleChangeFramework}
        project={project}
      />

      <RegenerateModal
        handleSubmit={handleRegenerateNextProcess}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="key-touch-point"
      />
    </>
  );
};

export default Index;
