import { HistoriesDataItem, SubsectionDataType } from "@/Types/projects";

interface Response {
  data?: HistoriesDataItem[];
}

interface HistoriesFilterProps {
  data: Response;
  section: string;
  subSection: string | null;
  contentType?: "string" | "json" | null;
}

export const historiesFilter = ({
  data,
  section,
  subSection,
  contentType,
}: HistoriesFilterProps): SubsectionDataType | null => {
  if (!contentType) contentType = "string";
  const array = data?.data?.length ? data.data : [];
  const result =
    array instanceof Array
      ? array.find(
          (item) =>
            item.section === section &&
            (subSection === null || item.subsection === subSection),
        )
      : null;
  const convertToJson = (
    contentType: "string" | "json",
    data: string | null,
  ) => {
    if (data && contentType === "json") {
      try {
        return JSON.parse(data);
      } catch (error) {
        return data ?? "";
      }
    }
    return data ?? "";
  };
  return {
    history_id: result?.id || 0,
    content: convertToJson(contentType, result?.content || ""),
    selectedContent: convertToJson(contentType, result?.selected_content || ""),
    sources: result?.sources ? JSON.parse(result.sources) : [],
    created_at: result?.created_at || "",
    updated_at: result?.updated_at || "",
    total_regenerate: result?.regenerate_count || 0,
  };
};

export const filterSelectedData = (data: any[], indices: number[]): any[] => {
  return data.filter((_item, index) => {
    return indices.includes(index);
  });
};

export const getIndicesOfSelectedData = (
  data: any[],
  selectedData: any[],
  field: string,
): number[] => {
  let sourceData: any[] = [];
  let selectedOfSourceData: any[] = [];

  if (data instanceof Array) {
    sourceData = data;
  } else {
    sourceData = JSON.parse(data);
  }
  if (selectedData instanceof Array) {
    selectedOfSourceData = selectedData;
  } else {
    selectedOfSourceData = JSON.parse(selectedData);
  }

  const indices: any = [];
  sourceData.forEach((item, index) => {
    if (selectedData[0] && selectedData[0].hasOwnProperty(field)) {
      if (
        selectedOfSourceData.find(
          (selectedItem) => selectedItem[field] === item[field],
        )
      )
        indices.push(index);
    }
  });

  return indices;
};
