import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import useClickOutside from "@/Hooks/useClickOutside";

interface IInputSelectItem {
  title: string;
  value: string | number;
  disabled?: boolean;
}

interface InputSelectProps {
  modelValue: string | number | (string | number)[];
  list: IInputSelectItem[];
  state: "init" | "loading" | "error" | "success";
  disabled: boolean;
  multiple: boolean;
  placeholder: string;
  label?: string;
  withFilter: boolean;
  containerClassName?: string;
  error?: string;
  onChange: (value: string | number | (string | number)[]) => void;
}

const InputDropdown: React.FC<InputSelectProps> = ({
  modelValue,
  list,
  disabled,
  multiple,
  placeholder,
  withFilter = true,
  onChange,
  containerClassName = "",
  error = "",
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [filterText, setFilterText] = useState("");
  const elementRef = useRef<HTMLDivElement>(null);
  const elementFilterTextRef = useRef<HTMLInputElement>(null);

  const isItemEmpty = !modelValue;
  const item = list.find((listItem) => listItem.value == modelValue);
  const items = list.filter((listItem) => {
    if (Array.isArray(modelValue)) {
      return modelValue.find(
        (modeValueItem) => modeValueItem == listItem.value,
      );
    }
    return false;
  });
  const newList = list.filter((listItem) =>
    listItem.title.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleClickOutside = () => {
    setDropdownActive(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    if (dropdownActive) {
      setFilterText("");
      setTimeout(() => {
        elementFilterTextRef.current?.focus();
      }, 0);
    }
  }, [dropdownActive]);

  useEffect(() => {
    if (!multiple) {
      const modelValueItem = list.find(
        (listItem) => listItem.value === modelValue,
      );
      if (modelValueItem) {
        onChange(modelValueItem.value);
      }
    }
  }, []);

  const handleChoose = (listItem: IInputSelectItem) => {
    if (multiple) {
      const newModelValue = Array.isArray(modelValue) ? [...modelValue] : [];
      const isItemSelected = newModelValue.find(
        (newModelValueItem) => listItem.value === newModelValueItem,
      );
      if (isItemSelected) {
        const newModelValueIndex = newModelValue.findIndex(
          (newModelValueItem) => listItem.value === newModelValueItem,
        );
        newModelValue.splice(newModelValueIndex, 1);
      } else {
        newModelValue.push(listItem.value);
      }
      onChange(newModelValue);
    } else {
      onChange(listItem.value);
    }
    setDropdownActive(false);
  };

  const handleFilterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newList.length > 0) {
      handleChoose(newList[0]);
    }
  };

  return (
    <div ref={elementRef} className="relative">
      <div
        className={`bg-transparent flex justify-between w-full items-center overflow-hidden ${
          disabled ? "" : "cursor-pointer"
        } ${containerClassName} ${error ? "!border-red-500" : ""}`}
        onClick={() => !disabled && setDropdownActive(!dropdownActive)}
      >
        {!multiple && (
          <div
            className={`px-0 py-4 mr-20 !text-14 ${
              isItemEmpty ? "text-placeholder-redx" : ""
            }`}
          >
            {!isItemEmpty ? item?.title : placeholder}
          </div>
        )}
        {multiple && (
          <>
            {items.length === 0 && (
              <div className="px-2 py-4 mr-20 !text-14 text-placholder-redx">
                {placeholder}
              </div>
            )}
            {items.length !== 0 && (
              <div className="flex items-center flex-wrap -mx-2">
                {items.map((itemItem, index) => (
                  <div key={index} className="px-2 mb-4">
                    <div className="bg-blue01 bg-opacity-10 rounded-4 flex overflow-hidden">
                      <div className="!text-14 font-medium text-line-1 text-blue01 px-8">
                        {itemItem.title}
                      </div>
                      {!disabled && (
                        <div
                          className="bg-blue01 px-5 items-stretch"
                          onClick={() => handleChoose(itemItem)}
                        >
                          <div className="h-full flex items-center">
                            <Icon
                              icon="mdi:close"
                              className="w-5 h-5 text-white"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {!disabled && (
          <Icon
            icon="uil:angle-down"
            className={`transition-all duration-300 ease-out w-24 h-w-24 text-black ${
              dropdownActive ? "transform rotate-180" : ""
            }`}
          />
        )}
      </div>
      {dropdownActive && (
        <div
          className="rounded-10 px-10 py-10 w-[97.5%] bg-white absolute top-full left-8 z-50 mt-10"
          style={{
            boxShadow: "0px 0px 12px 0px #00000026",
          }}
        >
          {withFilter && (
            <div className="mb-10">
              <input
                ref={elementFilterTextRef}
                value={filterText}
                type="text"
                placeholder="Search..."
                className="focus:outline-none px-10 rounded-5 w-full !text-14 py-8"
                onChange={(e) => setFilterText(e.target.value)}
                onKeyUp={handleFilterKeyPress}
              />
            </div>
          )}
          <div
            ref={elementRef}
            className="py-0 w-full max-h-120 overflow-y-auto overflow-custom"
          >
            {newList.map((listItem, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-1">
                  <div
                    className={`px-10 py-5 rounded-5 text-12 ${
                      !listItem.disabled
                        ? "cursor-pointer hover:bg-blue01 hover:bg-opacity-10"
                        : ""
                    }`}
                    onClick={() => !listItem.disabled && handleChoose(listItem)}
                  >
                    <div
                      className={`${
                        multiple &&
                        items.find((itemItem) => itemItem === listItem)
                          ? "font-medium text-blue01"
                          : !multiple && item === listItem
                            ? "font-medium text-blue01"
                            : !listItem.disabled
                              ? ""
                              : "text-black text-opacity-50"
                      }`}
                    >
                      {listItem.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputDropdown;
