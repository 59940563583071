/* eslint-disable no-nested-ternary */
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, Fragment, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import { useUpdateSelectedKeyTouchPoint } from '@/Hooks/react-query/key-touch-point';
import type { Recommendation } from '@/Types/key_touch_point';
import type { ProjectProps } from '@/Types/projects';

export interface CardHandles {
  submitForm: () => void;
}

const schema = z.object({
  digital_recommendation: z
    .string()
    .min(4, 'Digital recommendation is required'),
  idea_example: z.string().min(4, 'Idea example is required'),
  success_measurement: z.string().min(4, 'Success measurement is required'),
  media_types: z.string().min(4, 'Media types is required'),
  reason: z.string().min(4, 'Reason is required'),
  keyword: z.string(),
  trending_topics: z.string(),
});

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    index: number;
    project: ProjectProps;
    item?: Recommendation;
    isSelected?: boolean;
    isEditing?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    selectedFramework: string;
    totalData?: number;
    onSuccessSelected?: () => void;
    onHandleSubmit?: (data: Recommendation, id: number, index: number) => void;
  }
>(
  (
    {
      id,
      index,
      project,
      item,
      selectedFramework,
      onHandleSubmit,
      isEditing = false,
      isEditor,
      isFetching = false,
      isSelected = false,
      totalData = 0,
      onSuccessSelected,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedKeyTouchPoint();

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<Recommendation>({
      resolver: zodResolver(schema),
      defaultValues: {
        digital_recommendation: item?.digital_recommendation ?? '',
        idea_example: item?.idea_example ?? '',
        success_measurement: item?.success_measurement ?? '',
        media_types: item?.media_types ?? '',
        keyword: item?.keyword ?? '',
        trending_topics: item?.trending_topics ?? '',
        reason: item?.reason ?? '',
      },
    });

    useEffect(() => {
      setValue('digital_recommendation', item?.digital_recommendation ?? '');
      setValue('idea_example', item?.idea_example ?? '');
      setValue('success_measurement', item?.success_measurement ?? '');
      setValue('media_types', item?.media_types ?? '');
      setValue('reason', item?.reason ?? '');
      setValue('keyword', item?.keyword ?? '');
      setValue('trending_topics', item?.trending_topics ?? '');
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: Recommendation) => {
      onHandleSubmit && onHandleSubmit(data, id, index);
    };

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSuccessSelected && onSuccessSelected();
          },
        },
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          id === -1 ? (
            <div className="inline-flex w-full items-center justify-center gap-8 font-semibold">
              {selectedFramework}
              <div className="h-20 w-1 border-1 border-stroke-redx" />
              <p>New Touchpoint</p>
            </div>
          ) : (
            <div className="inline-flex w-full items-center justify-center gap-8 font-semibold">
              {selectedFramework}
              <div className="h-20 w-1 border-1 border-stroke-redx" />
              <p>
                Touchpoint {index + 1} of {totalData}
              </p>
            </div>
          )
        ) : (
          <div className="inline-flex w-full items-center justify-center gap-8">
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
            <div className="h-20 w-1 border-1 border-stroke-redx" />
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
          </div>
        )}

        <div
          className={`mt-10 flex w-full flex-col gap-y-8 px-18 py-20 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          }
        ${
          isSelected && !isFetching
            ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
            : 'border-t-1 border-stroke-redx'
        }`}
        >
          <div className="grid grid-cols-2 gap-x-24 gap-y-15 pb-8">
            <Controller
              control={control}
              name="digital_recommendation"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.digital_recommendation?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the digital recommendation here"
                  showTitle
                  title="Digital Recommendation"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="idea_example"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.idea_example?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the idea example here"
                  showTitle
                  title="Idea Example"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="success_measurement"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.success_measurement?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the success measurement here"
                  showTitle
                  title="Success Measurement"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="media_types"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.media_types?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the media types here"
                  showTitle
                  title="Media Types"
                  {...field}
                />
              )}
            />
            {selectedFramework === 'Searchability' && (
              <Controller
                control={control}
                name="keyword"
                render={({ field }) => (
                  <CardInput
                    defaultValue={field.value}
                    error={errors.keyword?.message}
                    isEditing={isEditing}
                    isFetching={isFetching}
                    placeholder="Input the keyword here"
                    showTitle
                    title="Keyword"
                    {...field}
                  />
                )}
              />
            )}
            {selectedFramework === 'Discoverability' && (
              <Controller
                control={control}
                name="trending_topics"
                render={({ field }) => (
                  <CardInput
                    defaultValue={field.value}
                    error={errors.trending_topics?.message}
                    isEditing={isEditing}
                    isFetching={isFetching}
                    placeholder="Input the trending topics here"
                    showTitle
                    title="Trending Topics"
                    {...field}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.reason?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the reason here"
                  showTitle
                  title="Reason"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="flex w-full justify-end px-1 py-15">
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="key-touch-point"
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
