import Wrapper from "@/Pages/MarketResearch/Wrapper";

const MarketResearchRoutes = [
  {
    path: "/:project_slug/market-research",
    element: <Wrapper />,
  },
];

export default MarketResearchRoutes;
