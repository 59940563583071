import React from 'react';

import MarkdownView from '@/Components/MardownView';

import TextArea from '../../TextAreaInput';

const CardInput: React.FC<{
  isFetching?: boolean;
  isEditing: boolean;
  showTitle?: boolean;
  title: string;
  value: any;
  defaultValue: any;
  error: string | undefined;
  onChange: (e: any) => void;
  placeholder: string;
}> = ({
  isFetching = false,
  isEditing,
  title,
  value,
  defaultValue,
  error,
  onChange,
  placeholder,
  showTitle = true,
}) => {
  return (
    <div className="flex flex-col gap-4 ">
      {showTitle && (
        <h3 className="text-12 font-semibold leading-14">{title}</h3>
      )}
      {isEditing ? (
        <TextArea
          className="overflow-custom mt-1 block w-full resize-none !text-14"
          containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
          error={error}
          isFocused={false}
          name={title.toLowerCase()}
          onChange={onChange}
          placeholder={placeholder}
          rows={2}
          value={value}
        />
      ) : (
        !isFetching && (
          <div className="min-h-50 py-4 text-14">
            <MarkdownView content={defaultValue} />
          </div>
        )
      )}
      {isFetching && (
        <div className="min-h-50 py-4 text-14">
          <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
        </div>
      )}
    </div>
  );
};

export default CardInput;
