import React from 'react';

import ModalCenter from '@/Components/Modals/Center';

const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  handleGenerate: () => void;
}> = ({ isOpen, onClose, handleGenerate }) => {
  return (
    <ModalCenter hasButtonClose={false} isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full md:max-w-sm">
        <div className="flex transition-transform duration-500">
          <div className="w-full shrink-0 p-49">
            <div className="mb-40 max-w-xl">
              <h1 className="mb-16 text-center text-35 font-bold text-black-redx md:text-24">
                Did you know you can generate multiple archetypes?
              </h1>
              <p className="text-center text-15 text-grey-redx">
                Are you sure you generated only one archetype? You can choose
                multiple archetypes at once or add new ones.
              </p>
            </div>
            <div className="flex w-full flex-row gap-24 md:flex-col">
              <button
                className="w-full rounded-10 border-2 border-blue-redx bg-transparent py-15 font-semibold text-blue-redx"
                onClick={onClose}
              >
                Back to Audience Archetype
              </button>
              <button
                className="w-full rounded-10 border-2 border-blue-redx bg-blue-redx py-15 font-semibold text-white"
                onClick={handleGenerate}
              >
                Generate Just One Archetype
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default ConfirmationModal;
