import { useEditor, EditorContent } from "@tiptap/react";
import { Icon } from "@iconify/react";
import { Markdown } from "tiptap-markdown";
import StarterKit from "@tiptap/starter-kit";
import showdown from "showdown";
import TurndownService from "turndown";
import Link from "@tiptap/extension-link";
import { adjustOrderedList } from "@/Utils/markdownAdjustOrderList";

const MarkdownEditor: React.FC<{
  content: string;
  onChange: (html: any) => void;
}> = (props) => {
  const { content, onChange } = props;

  const showdownService = new showdown.Converter({
    omitExtraWLInCodeBlocks: true,
    noHeaderId: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    strikethrough: true,
    tables: true,
    openLinksInNewWindow: true,
    emoji: true,
    simpleLineBreaks: true,
    encodeEmails: false,
    requireSpaceBeforeHeadingText: false,
  });

  let html = showdownService.makeHtml(content);
  html = adjustOrderedList(html);

  TurndownService.prototype.escape = function (string) {
    return string;
  };
  const turndownService = new TurndownService({ headingStyle: "atx" });

  turndownService.addRule("strikethrough", {
    filter: ["s", "del"],
    replacement: function (content) {
      return "~~" + content + "~~";
    },
  });

  const htmlToMarkdown = (html: any) => {
    let markdown = turndownService.turndown(html);
    markdown = markdown.replace(/[ \t\r]+\n/g, "\n");
    markdown = markdown.replace(/\s+\n\n/g, "\n\n");
    return markdown;
  };

  const editor = useEditor({
    content: html,
    extensions: [
      StarterKit,
      Markdown,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
    ],
    onUpdate: ({ editor }) => {
      onChange(htmlToMarkdown(editor.getHTML()));
    },
    editorProps: {
      attributes: {
        class:
          "border border-gray-400 py-4 px-0 min-h-250 overflow-y-auto outline-none prose overflow-custom max-w-none",
      },
    },
  });

  return (
    <>
      {editor && (
        <section className='buttons bg-soft-purple-redx text-black-redx w-full flex items-center flex-wrap gap-x-12 border-t border-l border-r border-soft-purple-redx py-4 px-15'>
          <button
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={`inline-flex items-center p-4 h-24 w-auto ${
              editor.isActive("bold") ? "bg-blue-redx text-white rounded" : ""
            }`}
            onClick={() => editor.chain().focus().toggleBold().run()}>
            <Icon
              icon='lucide:bold'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={`inline-flex items-center p-4 h-24 w-auto ${
              editor.isActive("italic") ? "bg-blue-redx text-white rounded" : ""
            }`}
            onClick={() => editor.chain().focus().toggleItalic().run()}>
            <Icon
              icon='lucide:italic'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={`inline-flex items-center p-4 h-24 w-auto ${
              editor.isActive("strike") ? "bg-blue-redx text-white rounded" : ""
            }`}
            onClick={() => editor.chain().focus().toggleStrike().run()}>
            <Icon
              icon='lucide:strikethrough'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().toggleBulletList().run()}
            className={`inline-flex items-center p-4 h-24 w-auto ${
              editor.isActive("bulletList")
                ? "bg-blue-redx text-white rounded"
                : ""
            }`}
            onClick={() => editor.chain().focus().toggleBulletList().run()}>
            <Icon
              icon='lucide:list'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().toggleOrderedList().run()}
            className={`inline-flex items-center p-4 h-24 w-auto ${
              editor.isActive("orderedList")
                ? "bg-blue-redx text-white rounded"
                : ""
            }`}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}>
            <Icon
              icon='lucide:list-ordered'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().undo().run()}
            className={`inline-flex items-center p-4 h-24 w-auto`}
            onClick={() => editor.commands.undo()}>
            <Icon
              icon='lucide:undo-2'
              className='w-16 h-16'
            />
          </button>
          <button
            disabled={!editor.can().chain().focus().redo().run()}
            className={`inline-flex items-center p-4 h-24 w-auto`}
            onClick={() => editor.commands.redo()}>
            <Icon
              icon='lucide:redo-2'
              className='w-16 h-16'
            />
          </button>
        </section>
      )}
      <EditorContent editor={editor} />
    </>
  );
};

export default MarkdownEditor;
