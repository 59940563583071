/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';

import EditButton from '@/Components/Projects/EditButton';

const SectionCard: React.FC<{
  title: string;
  isEditor: boolean;
  isFetching?: boolean;
  cols: 1 | 2;
  children: (props: {
    isEditing: boolean;
    gridColumnClass: string;
  }) => React.ReactNode;
  initialEditState?: boolean;
  onCancelEdit: () => void;
  onSubmitEdit: () => void;
  disabledSave?: boolean;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  isFetching = false,
  title,
  isEditor = false,
  cols = 1,
  children,
  initialEditState = false,
  onCancelEdit,
  onSubmitEdit,
  disabledSave,
  isEditingCount,
  setIsEditingCount,
}) => {
  const [isEditing, setIsEditing] = useState(initialEditState);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
    if (setIsEditingCount) {
      const newCount = !isEditing
        ? (isEditingCount += 1)
        : (isEditingCount -= 1);
      setIsEditingCount(newCount);
    }
  };
  const gridColumnClass = cols === 1 ? 'grid-cols-1' : 'grid-cols-2';

  const handleCancelEdit = () => {
    onCancelEdit();
    toggleEditing();
  };

  const handleSubmitEdit = () => {
    onSubmitEdit();
    toggleEditing();
  };

  useEffect(() => {
    setIsEditing(initialEditState);
  }, [initialEditState]);

  return (
    <div className="flex w-full flex-col gap-y-8 text-black-redx ">
      <h3 className="grow border-b-1 border-stroke-redx pb-4 text-15 font-bold">
        {title}
      </h3>

      <div className={`border-stroke-redx ${!isEditing ? 'border-b-1' : ''}`}>
        {children({ isEditing, gridColumnClass })}
      </div>
      {isEditor && (
        <div className="flex w-full items-center justify-end gap-x-15">
          {isFetching ? (
            <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
          ) : isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  disabledSave ? 'text-gray-500' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={disabledSave}
                id={`btn-edit-one-page-${title.toLowerCase().replace(' ', '-')}`}
                onClick={handleSubmitEdit}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <div className="inline-flex gap-8">
              <EditButton toggleEditing={toggleEditing} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionCard;
