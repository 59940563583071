import { Icon } from '@iconify/react/dist/iconify.js';

interface SelectedFrameworkCardProps {
  selectedFramework: 'Marketing Funnel' | 'S.D.R.C.P';
  onChangeFramework: () => void;
}

const SelectedFrameworkCard = ({
  selectedFramework,
  onChangeFramework,
}: SelectedFrameworkCardProps) => {
  return (
    <div className="inline-flex justify-between gap-16 border-1 border-y-blue-redx bg-softer-purple-redx p-16 md:flex md:flex-col">
      <div className="flex flex-col gap-8">
        <p className="text-16 font-bold leading-19">
          Selected Framework: {selectedFramework}
        </p>
        {selectedFramework === 'Marketing Funnel' && (
          <p className="text-14 font-normal leading-16 text-black-redx">
            The marketing funnel guides potential customers from{' '}
            <strong>awareness</strong> to advocacy, focusing on attracting
            attention, encouraging <strong>consideration</strong> , driving{' '}
            <strong>conversions</strong>, fostering{' '}
            <strong>purchase intent</strong>, and promoting brand{' '}
            <strong>advocacy</strong>.
          </p>
        )}
        {selectedFramework === 'S.D.R.C.P' && (
          <p className="text-14 font-normal leading-16 text-black-redx">
            The S.D.R.C.P framework ensures a product&apos;s online presence is
            easily <strong>searchable</strong>, <strong>discoverable</strong>{' '}
            across various channels,
            <strong>reachable</strong> through multiple touchpoints,
            <strong>credible</strong> through trust-building measures, and
            simple to <strong>purchase</strong>.
          </p>
        )}
      </div>
      <button
        className="inline-flex shrink-0 cursor-pointer items-center justify-center gap-4 text-16 font-semibold leading-18 text-blue-redx"
        id="btn-change-framework"
        onClick={onChangeFramework}
      >
        Change Framework
        <Icon className="size-20" icon="lucide:refresh-cw" />
      </button>
    </div>
  );
};

export default SelectedFrameworkCard;
