/* eslint-disable no-nested-ternary */
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, Fragment, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import { useUpdateSelectedIdeaExpansion } from '@/Hooks/react-query/idea-expansion/useUpdateSelected';
import type { IdeaExpansionContent } from '@/Types/idea-expansion';
import { IdeaExpansionSchema } from '@/Types/idea-expansion/schema';
import type { ProjectProps } from '@/Types/projects';

export interface CardHandles {
  submitForm: () => void;
}

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    index: number;
    project: ProjectProps;
    item?: IdeaExpansionContent;
    isSelected?: boolean;
    isEditing?: boolean;
    isEdited?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    selectedFramework: string;
    totalData?: number;
    onSuccessSelected?: () => void;
    onHandleSubmit?: (
      data: IdeaExpansionContent,
      id: number,
      index: number,
    ) => void;
  }
>(
  (
    {
      id,
      index,
      project,
      item,
      selectedFramework,
      onHandleSubmit,
      isEditing = false,
      isEditor,
      isFetching = false,
      isEdited = false,
      isSelected = false,
      totalData = 0,
      onSuccessSelected,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaExpansion();

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<IdeaExpansionContent>({
      resolver: zodResolver(IdeaExpansionSchema),
      defaultValues: {
        idea_title: item?.idea_title ?? '',
        campaign_example: item?.campaign_example ?? '',
        campaign_metrics: item?.campaign_metrics ?? '',
        idea_description: item?.idea_description ?? '',
        preferred_platform: item?.preferred_platform ?? '',
        preferred_influencer: item?.preferred_influencer ?? '',
        reason_product_brand: item?.reason_product_brand ?? '',
        idea_expansion_number: item?.idea_expansion_number ?? 0,
        reason_audience_archetype: item?.reason_audience_archetype ?? '',
      },
    });

    useEffect(() => {
      setValue('idea_title', item?.idea_title ?? '');
      setValue('campaign_example', item?.campaign_example ?? '');
      setValue('campaign_metrics', item?.campaign_metrics ?? '');
      setValue('idea_description', item?.idea_description ?? '');
      setValue('preferred_platform', item?.preferred_platform ?? '');
      setValue('preferred_influencer', item?.preferred_influencer ?? '');
      setValue('reason_product_brand', item?.reason_product_brand ?? '');
      setValue(
        'reason_audience_archetype',
        item?.reason_audience_archetype ?? '',
      );
      setValue('idea_expansion_number', item?.idea_expansion_number ?? 0);
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: IdeaExpansionContent) => {
      onHandleSubmit && onHandleSubmit(data, id, index);
    };

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSuccessSelected && onSuccessSelected();
          },
        },
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          id === -1 ? (
            <div className="inline-flex w-full items-center justify-center gap-8 font-semibold">
              {selectedFramework}
              <div className="h-20 w-1 border-1 border-stroke-redx" />
              <p>
                Idea Expansion {index + 1} of {totalData + 1}
              </p>
            </div>
          ) : (
            <div className="inline-flex w-full items-center justify-center gap-8 font-semibold">
              {selectedFramework}
              <div className="h-20 w-1 border-1 border-stroke-redx" />
              <p>
                Idea Expansion {index + 1} of {totalData}
              </p>
              {isEdited && (
                <div className="rounded-8 bg-soft-grey-redx px-8 py-4 text-12 leading-14 text-grey-redx">
                  Edited
                </div>
              )}
            </div>
          )
        ) : (
          <div className="inline-flex w-full items-center justify-center gap-8">
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
            <div className="h-20 w-1 border-1 border-stroke-redx" />
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
          </div>
        )}

        <div
          className={`mt-10 flex w-full flex-col gap-y-8 px-18 py-20 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          }
        ${
          isSelected && !isFetching
            ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
            : 'border-t-1 border-stroke-redx'
        }`}
        >
          <div className="grid grid-cols-2 gap-x-24 gap-y-15 pb-8">
            <Controller
              control={control}
              name="idea_title"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.idea_title?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the idea title here"
                  showTitle
                  title="Idea Title"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="idea_description"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.idea_description?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the idea description here"
                  showTitle
                  title="Idea Description"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="reason_product_brand"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.reason_product_brand?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the reason product brand here"
                  showTitle
                  title="Reason Product Brand"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="reason_audience_archetype"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.reason_audience_archetype?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the reason target archetype here"
                  showTitle
                  title="Reason Audience Archetype"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="campaign_example"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.campaign_example?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the campaign example here"
                  showTitle
                  title="Campaign Example"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="campaign_metrics"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.campaign_metrics?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the campaign metrics here"
                  showTitle
                  title="Campaign Metrics"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="preferred_influencer"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.preferred_influencer?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the preferred influencer here"
                  showTitle
                  title="Preferred Influencer"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="preferred_platform"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.preferred_platform?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input the preferred platform here"
                  showTitle
                  title="Preferred Platform"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="flex w-full justify-end px-1 py-15">
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="idea-expansion"
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
