import { ProjectActionsTypes } from "@/Context/actions/projectActionsType";
import { LoadingStatus, SubsectionDataType } from "@/Types/projects";
import {
  SaveGeneratedDataAction,
  SaveLoadingAction,
} from "@/Context/actions/projectActions";
import { Archetype } from "@/Types/audience-archetype";

export const setAudienceArchetypeData = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_DATA,
    payload: data,
  };
};

export const setAudienceArchetypeLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_LOADING,
    payload: isLoading,
  };
};
