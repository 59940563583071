/* eslint-disable no-nested-ternary */
import React, { forwardRef } from 'react';

import TextArea from './TextArea';

const CardInput = forwardRef(
  (
    {
      isFetching = false,
      isEditing,
      title,
      value,
      defaultValue,
      error,
      onChange,
      placeholder,
      viewPlaceholder = placeholder,
      showTitle = true,
    }: {
      isFetching?: boolean;
      isEditing: boolean;
      title: string;
      value: string;
      defaultValue: string;
      error?: string;
      onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
      placeholder?: string;
      viewPlaceholder?: string;
      showTitle?: boolean;
    },
    ref: React.Ref<HTMLTextAreaElement>,
  ) => (
    <div className="flex flex-col gap-4">
      {showTitle && (
        <h3 className="text-12 font-semibold leading-14">{title}</h3>
      )}
      {isFetching ? (
        <div className="min-h-50 py-4 text-14">
          <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
        </div>
      ) : isEditing ? (
        <TextArea
          ref={ref}
          className="overflow-custom mt-1 block w-full resize-none !text-14"
          containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
          error={error}
          name={title.toLowerCase()}
          onChange={onChange}
          placeholder={placeholder}
          rows={2}
          value={value}
        />
      ) : !defaultValue ? (
        <p className="min-h-50 py-4 text-14 italic text-placeholder-redx">
          {viewPlaceholder}
        </p>
      ) : (
        <p className="min-h-50 py-4 text-14">{defaultValue}</p>
      )}
    </div>
  ),
);

export default CardInput;
