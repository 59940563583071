import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { ChallengeSchema } from "@/Types/challenge_and_task/schema";

const updateChallengeAndTask = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: ChallengeSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/challenge-and-task/submit/${id}`,
      {
        content: payload,
        archetypeId: archetypeId,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateChallengeAndTask = () => {
  return useMutation<
    any,
    Error,
    {
      payload: ChallengeSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateChallengeAndTask,
  });
};

export { useUpdateChallengeAndTask };
