import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { SelectedFramework } from "@/Types/key_touch_point";

const updateSelectedFramework = async ({
  projectSlug,
  selectedFramework,
}: {
  projectSlug: string;
  selectedFramework: SelectedFramework;
}) => {
  try {
    const { data } = await fetcher.put(
      `/${projectSlug}/submission/selected-framework`,
      {
        selected_framework: selectedFramework,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedFramework = () => {
  return useMutation<
    any,
    Error,
    { projectSlug: string; selectedFramework: SelectedFramework }
  >({
    mutationFn: updateSelectedFramework,
  });
};

export { useUpdateSelectedFramework };
