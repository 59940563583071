import { ProjectActionsTypes } from "@/Context/actions/projectActionsType";
import { LoadingStatus, SubsectionDataType } from "@/Types/projects";
import {
  SaveGeneratedDataAction,
  SaveLoadingAction,
} from "@/Context/actions/projectActions";

export const setProductResearchProductDescription = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION,
    payload: data,
  };
};

export const setProductResearchProductDescriptionLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION_LOADING,
    payload: isLoading,
  };
};

export const setProductResearchMainFeatures = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES,
    payload: data,
  };
};

export const setProductResearchMainFeaturesLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES_LOADING,
    payload: isLoading,
  };
};

export const setProductResearchMarcommHighlights = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS,
    payload: data,
  };
};

export const setProductResearchMarcommHighlightsLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS_LOADING,
    payload: isLoading,
  };
};
