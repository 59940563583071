import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateChallengeAndTask } from '@/Hooks/react-query/challenge-and-task';
import useChallengeAndTasks from '@/Hooks/react-query/challenge-and-task/useChallengeAndTask';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type { SectionArchetypeType } from '@/Types/challenge_and_task';
import type { ChallengeSchema } from '@/Types/challenge_and_task/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<SectionArchetypeType[]>([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeItem, setActiveItem] = useState<SectionArchetypeType>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const { data: user } = useUser();
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const maxGenerateLimit = project.max_generated_data;
  const { roles } = useRolesBadge(project, user);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [totalSelectedChallenge, setTotalSelectedChallenge] =
    useState<number>(0);
  const [totalChallenge, setTotalChallenge] = useState<number>(0);

  const { handleGenerateChallengeAndTask, handleGenerateCompetitorAnalysis } =
    useGenerate(dispatch);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const {
    isChallengeAndTaskOfTheBrand,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleCancelEdit = () => {
    toggleIsEditing();
  };

  const navigate = useNavigate();
  const { mutate } = useUpdateChallengeAndTask();

  const { data, isLoading, refetch, isRefetching } =
    useChallengeAndTasks(project);

  const handleRegenerateButton = (data: { prompt: string }) => {
    setParsedData([]);
    setErrorTabIndex([]);
    setIsGenerated(true);
    dispatch(saveSubInformation(''));
    handleGenerateChallengeAndTask()(project.slug, data.prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const routeOptionValue: RouteOption = {
    label: 'Generate Competitor Analysis',
    isActive: true,
    isGenerate: true,
    isInactive: true,
  };

  const hasSelectedChallenge = (selectedData: SectionArchetypeType[]) => {
    const dataToUse = selectedData?.length ? selectedData : parsedData;

    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = dataToUse
      .map((section, index) => {
        const hasSelectedKeyInsight = section.challenges.some(
          (challenge) => challenge.is_selected === 1,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select challenge & communication task for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }

    dispatch(saveSubInformation(``));
    return false;
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleFetchSubmit = async (
    payload: ChallengeSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      {
        payload,
        id,
        archetypeId: activeItem?.id!,
        projectSlug,
      },
      {
        onSuccess: () => {
          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);

          setActiveItem(activeItem);

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const onSelectSuccess = (data: any) => {
    setShowAlertGenerateModal(data.is_show_pop_up);
    refetch();
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['OnePageStrategy', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/challenge-and-task/check-regenerate`,
    );

    if (
      data?.data.is_show_pop_up ||
      isCompetitorAnalysisComplete.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isCompetitorAnalysisComplete.isRegenerated,
      );
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/competitor-analysis`);
  };

  const handleRegenerate = async () => {
    if (
      differentArchetypes?.is_different_archetype.challenges ||
      isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      setIsReGenerating(true);
      setIsGenerated(true);
      await handleGenerateChallengeAndTask()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
      return;
    }

    if (isCompetitorAnalysisComplete.isRegenerated) {
      setIsReGenerating(true);
      setIsGenerated(true);
      await handleGenerateCompetitorAnalysis()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
    }

    navigate(`/${project.slug}/competitor-analysis`);
  };

  useEffect(() => {
    const totalSelected = data?.data?.data?.total_selected_challenges || 0;
    const totalData = data?.data?.data?.total_challenges || 0;

    setTotalChallenge(totalData);
    setTotalSelectedChallenge(totalSelected);

    if (data?.data?.data) {
      const {
        data: challenges,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data.data.data;

      if (isQuerying) {
        setIsGenerated(true);
        setParsedData([]);
        setTotalRegenerate(0);
        setSources([]);

        return;
      }

      if (challenges.length > 0) {
        setParsedData(challenges);
        setTotalRegenerate(totalRegenerate);
        setSources(sources);
        setIsGenerated(false);
      }

      dispatch(saveSubInformation(''));
    }
  }, [
    data,
    isChallengeAndTaskOfTheBrand,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  useEffect(() => {
    !isChallengeAndTaskOfTheBrand.isQuerying && !isPendingHistory && refetch();
    isChallengeAndTaskOfTheBrand.isQuerying &&
      !isPendingHistory &&
      setIsGenerated(true);
  }, [
    isChallengeAndTaskOfTheBrand,
    isGenerated,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  useEffect(() => {
    if (!isChallengeAndTaskOfTheBrand.status) {
      dispatch(
        saveInformation(`0 from 0 challenge & communication task selected`),
      );
    }

    if (isChallengeAndTaskOfTheBrand.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    !isPendingHistory &&
      isKeyInsightsComplete.isQuerying &&
      setIsGenerated(true);
  }, [isChallengeAndTaskOfTheBrand, isPendingHistory]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.challenges
    ) {
      if (
        !isGenerated &&
        !isPendingHistory &&
        !isChallengeAndTaskOfTheBrand.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        dispatch(saveSubInformation(''));
        handleGenerateChallengeAndTask()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isChallengeAndTaskOfTheBrand,
    isPendingHistory,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/key-insight`),
      }),
    );
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    if (!parsedData.length) return;
    setSelectedIndex([]);

    const activeItem = parsedData[activeTab];
    const selectedDataWithIndex = activeItem?.challenges
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);

      if (errorTabIndex?.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== activeTab) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(', ');

        dispatch(saveSubInformation(''));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select challenge & communication task for ${namesWithoutSelectedKeyInsight}`,
            ),
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }

    setActiveItem(activeItem);
  }, [activeTab, parsedData]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [activeTab]);

  const countSelectedData = (data: SectionArchetypeType[]) => {
    const archetypeCounts = data.map((archetype) => {
      let totalArchetype = 0;
      let totalSelected = 0;

      if (archetype.challenges && Array.isArray(archetype.challenges)) {
        archetype.challenges.forEach((challenge) => {
          totalArchetype++;
          if (challenge.is_selected === 1) {
            totalSelected++;
          }
        });
      }

      return {
        archetypeId: archetype.id,
        totalArchetype,
        totalSelected,
      };
    });

    const isInactive = archetypeCounts.some(
      (archetype) => archetype.totalSelected === 0,
    );

    return isInactive;
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totalSelectedChallenge} from ${totalChallenge} challenge & communication task selected`,
      ),
    );
  }, [totalSelectedChallenge, totalChallenge]);

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: 'Challenge & Communication Task',
    value: 'challenges',
    section: 'brand',
  };

  useEffect(() => {
    if (
      isAdding ||
      isEditing ||
      differentArchetypes?.is_different_archetype.challenges ||
      parsedData.length === 0 ||
      isChallengeAndTaskOfTheBrand.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.challenges,
    parsedData,
    isChallengeAndTaskOfTheBrand.isQuerying,
  ]);

  useEffect(() => {
    if (isEditor) {
      const isInactive =
        isGenerated ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        !isChallengeAndTaskOfTheBrand.status
          ? true
          : countSelectedData(parsedData);
      routeOptionValue.isInactive = isInactive;

      if (
        isCompetitorAnalysisComplete.status &&
        isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Regenerate Competitor Analysis';
        routeOptionValue.isGenerate = true;
      }

      if (
        !isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Generate Competitor Analysis';
        routeOptionValue.isGenerate = true;
      }

      if (
        isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Next to Competitor Analysis';
        routeOptionValue.isGenerate = false;
      }
    } else {
      routeOptionValue.label = 'Next to Competitor Analysis';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isCompetitorAnalysisComplete.status ||
        isCompetitorAnalysisComplete.isQuerying;
    }

    routeOptionValue.onClick = () => {
      if (
        !isChallengeAndTaskOfTheBrand.status ||
        isGenerated ||
        isChallengeAndTaskOfTheBrand.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait challenge & communication Task is still generating`,
          ),
        );
        return;
      }

      if (!isEditor && !isCompetitorAnalysisComplete.status) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor) {
        if (!isKeyInsightsComplete.status) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait key insight is still generating`,
            ),
          );
          return;
        }

        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, competitor task is still generating`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        if (isEditor && (isAdding || isEditing)) {
          dispatch(
            saveSubInformation(`{text-error-redx} Please do save first`),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        const hasError = hasSelectedChallenge(parsedData);
        if (!hasError)
          routeOptionValue.isGenerate
            ? handleRefetchSelectedData()
            : navigate(`/${project.slug}/competitor-analysis`);
        return;
      }

      navigate(`/${project.slug}/competitor-analysis`);
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
    parsedData,
    isGenerated,
    isCompetitorAnalysisComplete,
    showRegenerateModal,
    isEditor,
    isAdding,
    isEditing,
  ]);

  return (
    <>
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.challengeAndTask.progress / 2).toFixed(0)),
            isQuerying: isChallengeAndTaskOfTheBrand.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isChallengeAndTaskOfTheBrand.isSendingEmail}
        />
      ) : (
        <>
          <div className="sticky top-56 z-20 flex flex-col gap-8 bg-white py-16">
            <div className="inline-flex items-center justify-between ">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Challenge & Communication Task of the Brand
              </h1>
              <div className="flex shrink-0 items-center">
                {isEditor && (
                  <RegenerateButton
                    isGenerateMore
                    limit={totalRegenerate}
                    maxLimit={maxGenerateLimit}
                    onSubmit={handleRegenerateButton}
                    section="challenge-and-task"
                  />
                )}
              </div>
            </div>

            <span className="text-15 font-normal text-grey-redx">
              Please <b className="text-black-redx">select one or more</b>{' '}
              challenge & communication task{' '}
              <b className="text-black-redx">for each archetype,</b> or add by
              clicking &apos;Add Challenge & Communication Task Manually&apos;.
            </span>
          </div>

          <TabGroup
            className="pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <div className="relative flex items-center">
              {!isLoading &&
                !isChallengeAndTaskOfTheBrand.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={activeTab === 0}
                    onClick={handlePrev}
                  >
                    <Icon className="text-25" icon="mingcute:left-line" />
                  </button>
                )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading && !isChallengeAndTaskOfTheBrand.isQuerying
                  ? parsedData.map((item, dataIndex) => (
                      <Tab
                        key={dataIndex}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[dataIndex] = el;
                          }
                        }}
                        className="w-full min-w-5/24 border-b-1 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                        disabled={isEditing}
                      >
                        <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${dataIndex + 1}`}
                            <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                              {item.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex &&
                            errorTabIndex.includes(dataIndex) && (
                              <Icon
                                className="text-25 text-error-redx"
                                icon="mingcute:information-line"
                              />
                            )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                      >
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isChallengeAndTaskOfTheBrand.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={activeTab === parsedData.length - 1}
                    onClick={handleNext}
                  >
                    <Icon className="text-25" icon="mingcute:right-line" />
                  </button>
                )}
            </div>
            {!isLoading && !isChallengeAndTaskOfTheBrand.isQuerying ? (
              <TabPanels className="py-24">
                {parsedData.map((item, index) => (
                  <TabPanel key={index}>
                    <div key={index} className="pt-5">
                      <SelectedCard
                        data={[
                          {
                            title: 'Archetype Name',
                            content: item.archetype_content.archetype_name,
                          },
                          {
                            title: 'Demographic',
                            content: item.archetype_content.demographics,
                          },
                          {
                            title: 'Occupation',
                            content: item.archetype_content.occupation,
                          },
                          {
                            title: 'Lifestyle',
                            content: item.archetype_content.lifestyle,
                          },
                          {
                            title: 'Behavior',
                            content: item.archetype_content.behavior,
                          },
                          {
                            title: 'Need & Challenges',
                            content:
                              item.archetype_content.needs_and_challenges,
                          },
                          {
                            title: 'Potential Buying Motives',
                            content:
                              item.archetype_content.potential_buying_motives,
                          },
                          {
                            title: 'Current Trends',
                            content: item.archetype_content.current_trends,
                          },
                          {
                            title: 'Source of Information',
                            content:
                              item.archetype_content.source_of_information,
                          },
                          {
                            title: 'Online Search Behavior',
                            content:
                              item.archetype_content.online_search_behavior,
                          },
                          {
                            title: 'Purchase Frequency',
                            content: item.archetype_content.purchase_frequency,
                          },
                          {
                            title: 'Preferred Sales Channels',
                            content:
                              item.archetype_content.preferred_sales_channels,
                          },
                        ]}
                        title={`Archetype Detail - ${item.archetype_content.archetype_name}`}
                      />
                      <div className="mt-20" />
                      <SliderContainer
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        isEditing={isEditing}
                        length={item.challenges.length}
                        onActiveIndex={(index) => setActiveIndex(index)}
                        selectedIndexes={selectedIndex}
                      >
                        {item.challenges.length &&
                          item.challenges.map((challenge, index) => (
                            <Card
                              key={index}
                              ref={(el) => (cardRefs.current[index] = el)}
                              id={challenge.id}
                              index={index}
                              isAdding={isAdding}
                              isCanSelect={isEditor}
                              isEditing={isEditing}
                              isFetching={isRefetching}
                              isSelected={!!challenge.is_selected}
                              item={challenge.challenges_content}
                              onSelectSuccess={onSelectSuccess}
                              onSubmitSuccess={handleFetchSubmit}
                              project={project}
                              totalData={item.challenges.length}
                            />
                          ))}
                        {isAdding && (
                          <Card
                            ref={(el) =>
                              (cardRefs.current[item.challenges.length] = el)
                            }
                            id={-1}
                            index={item.challenges.length}
                            isAdding
                            isEditing={isAdding}
                            isSelected={false}
                            item={{
                              challenge: '',
                              communication_task: '',
                            }}
                            onSelectSuccess={onSelectSuccess}
                            onSubmitSuccess={handleFetchSubmit}
                            project={project}
                            totalData={item.challenges.length + 1}
                          />
                        )}
                      </SliderContainer>
                      <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                              onClick={toggleIsAdding}
                            >
                              Add Challenge & Communication Task Manually
                              <Icon className="size-20" icon="lucide:plus" />
                            </button>
                          ) : (
                            <div />
                          ))}
                        <div className="flex items-center justify-end gap-x-15">
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <>
                                <button
                                  className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }
                                >
                                  <span className="text-15 font-semibold">
                                    Cancel
                                  </span>
                                  <Icon className="size-20" icon="lucide:x" />
                                </button>
                                <button
                                  className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                                  id="btn-edit-challenge-and-task"
                                  onClick={handleSubmit}
                                >
                                  <span className="text-15 font-semibold">
                                    Save
                                  </span>
                                  <Icon
                                    className="size-20"
                                    icon="lucide:save"
                                  />
                                </button>
                              </>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className="flex w-full pb-60">
                        {!isLoading && sources && (
                          <SourcesList sources={sources} />
                        )}
                        {isLoading && (
                          <div className="inline-flex gap-10">
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div
                                key={index}
                                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className="block">
                <div className="h-55 w-full animate-pulse rounded-8 bg-soft-purple-redx" />
                <div className="mt-20" />
                <SliderContainer
                  goToIndex={goToIndex}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  length={1}
                  onActiveIndex={undefined}
                  selectedIndexes={selectedIndex}
                >
                  <Card
                    ref={(el) => (cardRefs.current[-1] = el)}
                    id={-1}
                    index={-1}
                    isEditing={isAdding}
                    isFetching
                    isSelected={false}
                    item={{
                      challenge: '',
                      communication_task: '',
                    }}
                    onSelectSuccess={onSelectSuccess}
                    onSubmitSuccess={handleFetchSubmit}
                    project={project}
                    totalData={-1}
                  />
                </SliderContainer>
                <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                  {!roles.includes('View Only') &&
                    (!isAdding && !isEditing ? (
                      <button
                        className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                        onClick={undefined}
                      >
                        Add Challenge & Communication Task Manually
                        <Icon className="size-20" icon="lucide:plus" />
                      </button>
                    ) : (
                      <div />
                    ))}
                  <div className="flex items-center justify-end gap-x-15">
                    {!roles.includes('View Only') &&
                      (isEditing || isAdding ? (
                        <>
                          <button
                            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">
                              Cancel
                            </span>
                            <Icon className="size-20" icon="lucide:x" />
                          </button>
                          <button
                            className={`inline-flex items-center gap-x-4 ${
                              false ? 'text-gray-600' : 'text-blue-redx'
                            } cursor-pointer`}
                            disabled={false}
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">Save</span>
                            <Icon className="size-20" icon="lucide:save" />
                          </button>
                        </>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className="flex w-full pb-60">
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => {
          setShowRegenerateModal(false);
        }}
        section="challenge-and-task"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
