import { ProjectActionsTypes } from "@/Context/actions/projectActionsType";
import { LoadingStatus, SubsectionDataType } from "@/Types/projects";
import {
  SaveGeneratedDataAction,
  SaveLoadingAction,
} from "@/Context/actions/projectActions";

export const setOpportunityAmongCompetitionData = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_DATA,
    payload: data,
  };
};

export const setOpportunityAmongCompetitionLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_LOADING,
    payload: isLoading,
  };
};
