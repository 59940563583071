import { TargetAudienceItem } from "@/Types/target_audience";

export const jsonToMarkdown = (data: TargetAudienceItem[]) => {
  let markdown = "";
  data.forEach((item) => {
    if (item.title && item.content) {
      markdown += `**${item.title}**: ${item.content}\n`;
    } else {
      markdown += `${item.content}\n`;
    }
  });

  return markdown;
};

export const markdownToJson = (markdown: string) => {
  const lines = markdown.split("\n");
  const parsedContent: TargetAudienceItem[] = [];

  lines.forEach((line) => {
    if (line.includes(":")) {
      const regex = /(\*\*[^*]+\*\*):/;
      const parts = line.split(regex).filter((part) => part.trim() !== "");

      const title = parts[0].trim();
      const content = parts.slice(1).join(":").trim();

      if (title && content) {
        const titleMatch = title.trim().match(/\*\*(.*?)\*\*/);

        if (titleMatch && titleMatch[1]) {
          parsedContent.push({
            title: "**" + titleMatch[1] + "**",
            content: content.trim(),
          });
        }
      } else {
        parsedContent.push({
          title: "",
          content: title,
        });
      }
    } else {
      if (line.length > 0) {
        parsedContent.push({
          title: "",
          content: line.trim(),
        });
      }
    }
  });

  return parsedContent;
};
