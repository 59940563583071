import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import TextArea from '@/Components/TextAreaInput';

const RegenerateSchema = z.object({
  prompt: z.string().min(3, 'Minimum length is 3 characters'),
});

type Regenerate = z.infer<typeof RegenerateSchema>;

const RegenButton: React.FC<{
  limit: number;
  maxLimit: number;
  isGenerateMore?: boolean;
}> = ({ limit, maxLimit, isGenerateMore = false }) => {
  return (
    <>
      <span
        className={`inline-block text-15 font-semibold ${
          limit < maxLimit
            ? 'bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] bg-clip-text text-transparent'
            : 'text-placeholder-redx'
        }`}
      >
        {isGenerateMore ? 'Generate More' : 'Regenerate'}
      </span>
      <Icon
        className={`size-18 ${
          limit < maxLimit ? 'text-blue-redx' : 'text-placeholder-redx'
        }`}
        icon="lucide:wand-sparkles"
      />
      {maxLimit > 0 && (
        <div className="inline-flex rounded-4 bg-soft-purple-redx p-4 text-12 font-bold leading-none text-blue-redx">
          {limit}/{maxLimit}
        </div>
      )}
    </>
  );
};

const RegenerateButton: React.FC<{
  section: string;
  limit: number;
  maxLimit: number;
  isGenerateMore?: boolean;
  onSubmit: (data: Regenerate) => void;
}> = ({ limit, maxLimit = 2, onSubmit, isGenerateMore = false, section }) => {
  const defaultValues: Regenerate = useMemo(
    () => ({
      prompt: '',
    }),
    [],
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<Regenerate>({
    resolver: zodResolver(RegenerateSchema),
    defaultValues,
    mode: 'all',
  });

  const onFormSubmit = (data: Regenerate) => {
    onSubmit(data);
  };

  if (maxLimit <= 0) {
    return (
      <div className="inline-flex items-center gap-x-4">
        <RegenButton
          isGenerateMore={isGenerateMore}
          limit={limit}
          maxLimit={maxLimit}
        />
      </div>
    );
  }

  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center gap-x-4">
        <RegenButton
          isGenerateMore={isGenerateMore}
          limit={limit}
          maxLimit={maxLimit}
        />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor={{ to: 'bottom end', gap: -25, offset: 2 }}
          className="divide-y z-30 !max-w-525 rounded-lg bg-white p-15 shadow-lg md:w-full lg:min-w-525"
        >
          {({ close }) =>
            limit < maxLimit ? (
              <>
                <InputLabel
                  className="text-12 font-semibold !text-black-redx"
                  htmlFor="prompt"
                  value="Let us know what kind of output you wanted"
                />
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="mb-15">
                    <Controller
                      control={control}
                      name="prompt"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <TextArea
                              className="block w-full !text-14"
                              containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                              isFocused={false}
                              onChange={onChange}
                              placeholder="Example: I want the output talk about the product in specific context."
                              rows={3}
                              value={value}
                            />
                            {error?.message && (
                              <InputError
                                className="mt-2"
                                message={error?.message}
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="flex w-full justify-end gap-x-15">
                    <button
                      className="text-15 font-semibold text-error-redx"
                      onClick={() => {
                        reset();
                        close();
                      }}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className={`inline-flex items-center gap-x-4 ${
                        isValid ? 'cursor-pointer' : 'cursor-not-allowed'
                      }`}
                      disabled={!isValid}
                      id={`btn-regenerate-${section}`}
                      type="submit"
                    >
                      <RegenButton
                        isGenerateMore={isGenerateMore}
                        limit={limit}
                        maxLimit={maxLimit}
                      />
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <InputLabel
                  className="mb-8 text-14 font-semibold !text-black-redx"
                  htmlFor="prompt"
                  value="Generation limit reached"
                />
                <p className="mb-15 block w-full !text-14 leading-tight text-grey-redx">
                  You have reached the generation limit for this section. Click
                  the feedback button on the bottom-left side to request
                  additional regenerations.
                </p>
                <div className="flex w-full justify-end gap-x-15">
                  <button
                    className="rounded-8 bg-blue-redx px-14 py-10 text-15 font-semibold text-white"
                    onClick={() => {
                      reset();
                      close();
                    }}
                  >
                    Understand
                  </button>
                </div>
              </>
            )
          }
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default RegenerateButton;
