import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import {
  setProductResearchMainFeatures,
  setProductResearchMainFeaturesLoading,
  setProductResearchMarcommHighlights,
  setProductResearchMarcommHighlightsLoading,
  setProductResearchProductDescription,
  setProductResearchProductDescriptionLoading,
} from "@/Context/actions/product_research";
import { fetcher } from "@/Services/axios";
import { SubsectionDataType } from "@/Types/projects";

export const handleGenerateProductResearchProductDescription =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setProductResearchProductDescriptionLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setProductResearchProductDescriptionLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/product-research/generate/product-description`,
        {
          prompt: regenerate_prompt,
        },
      );

      clearInterval(interval);
      dispatch(
        setProductResearchProductDescriptionLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setProductResearchProductDescriptionLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setProductResearchProductDescription(data.data));
      }, 1000);
    } catch {
      clearInterval(interval);
      dispatch(
        setProductResearchProductDescriptionLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateProductResearchMainFeatures =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;

    try {
      dispatch(
        setProductResearchMainFeaturesLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setProductResearchMainFeaturesLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/product-research/generate/main-features`,
        {
          prompt: regenerate_prompt,
        },
      );

      clearInterval(interval);
      dispatch(
        setProductResearchMainFeaturesLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setProductResearchMainFeaturesLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setProductResearchMainFeatures(data.data));
      }, 1000);
    } catch {
      clearInterval(interval);
      dispatch(
        setProductResearchMainFeaturesLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateProductResearchMarcommHighlights =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setProductResearchMarcommHighlightsLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setProductResearchMarcommHighlightsLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/product-research/generate/marcomm-highlights`,
        {
          prompt: regenerate_prompt,
        },
      );

      clearInterval(interval);
      dispatch(
        setProductResearchMarcommHighlightsLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setProductResearchMarcommHighlightsLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setProductResearchMarcommHighlights(data.data));
      }, 1000);
    } catch {
      clearInterval(interval);
      dispatch(
        setProductResearchMarcommHighlightsLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleSetProductResearchProductDescriptionData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setProductResearchProductDescription(data));
  };

export const handleSetProductResearchMainFeaturesData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setProductResearchMainFeatures(data));
  };

export const handleSetProductResearchMarcommHighlightsData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setProductResearchMarcommHighlights(data));
  };
