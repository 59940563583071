import { z } from "zod";

export const AudienceBehavioralTrends = z.object({
  current_trends: z.string().min(4, "Current trends is required"),
  consumption_behaviors: z.string().min(4, "Consumption behavior is required"),
});

export type AudienceBehavioralTrendsSchema = z.infer<
  typeof AudienceBehavioralTrends
>;
