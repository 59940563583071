import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import GenerateNotification from "@/Components/Toast/GenerateNotification";
import { fetcher } from "@/Services/axios";
import { ProjectProps } from "@/Types/projects";
import { SectionList } from "@/Types/tabs";

const GenerateLoading: React.FC<{
  progress: number;
  project: ProjectProps;
  section: SectionList;
  estimateTimeFrom?: number;
  estimateTimeTo?: number;
  showEmailNotification: boolean;
}> = ({
  progress,
  project,
  section,
  estimateTimeFrom = 1,
  estimateTimeTo = 2,
  showEmailNotification,
}) => {
  const [emailNotification, setEmailNotification] = useState(false);

  useEffect(() => {
    setEmailNotification(!showEmailNotification);
  }, [showEmailNotification]);

  const handleEmailNotification = async () => {
    try {
      if (emailNotification == true) {
        setEmailNotification(false);
        const data = {
          submission_id: project.id,
          section: section.section,
          subsection: section.value,
        };
        await fetcher.put(`/${project.slug}/email-notification`, data);
        toast.custom((t) => <GenerateNotification t={t} />);
      }
    } catch (error: any) {
      setEmailNotification(true);
      throw new Error(error.response.data.message);
    }
  };

  return (
    <div className='flex md:flex-col justify-between items-center lg:gap-x-30 lg:py-35 lg:pr-40 pb-120'>
      <div className='w-300 h-300'>
        <img
          src='/assets/img/ainstein-generating.png'
          alt='loading'
          className='object-contain w-full h-full'
        />
      </div>
      <div className='flex-1 flex-col'>
        <h1 className='text-25 font-bold mb-8 text-black-redx'>
          AInstein is working hard on your output... Bear with him!
        </h1>
        <p className='text-15 mb-24 text-grey-redx'>
          If you notice any bugs, we'd love to hear about them! Just click the
          feedback button on the bottom-left side of this page.
        </p>
        <div className='w-full bg-stroke-redx rounded-full h-15 mb-24'>
          <div
            className='bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] h-15 rounded-full text-12 font-semibold text-white text-left py-2 pl-9 leading-none'
            style={{ width: `${progress}%` }}>
            {progress}%
          </div>
        </div>
        <div className='flex w-full justify-between'>
          <div className='text-15'>
            Estimate Time:{" "}
            <span className='font-bold'>
              {estimateTimeFrom}-{estimateTimeTo} minutes
            </span>
          </div>
          <div className='flex gap-x-24'>
            {emailNotification && (
              <a
                href='#'
                onClick={handleEmailNotification}
                className='text-15 text-blue-redx font-semibold'>
                Notify me via Email
              </a>
            )}
            {/*
            // TODO: add generate browser notification
            <a
              href='#'
              className='text-15 text-blue-redx font-semibold'>
              Notify me via Browser
            </a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateLoading;
