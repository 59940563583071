import React, { Fragment } from "react";
import { Icon } from "@iconify/react";
import {
  useHover,
  useFloating,
  useInteractions,
  offset,
} from "@floating-ui/react";

const EditButton: React.FC<{ toggleEditing: () => void }> = ({
  toggleEditing,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 2,
        crossAxis: -10,
      }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
  return (
    <Fragment>
      <div
        className="inline-flex gap-4 text-blue-redx cursor-pointer"
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={toggleEditing}
      >
        Edit <Icon icon="lucide:pencil-line" className=" w-20 h-20" />
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            boxShadow: "0px 0px 12px 0px #00000026",
          }}
          className="py-8 px-15 rounded-8 text-15"
          {...getFloatingProps()}
        >
          Edit
        </div>
      )}
    </Fragment>
  );
};

export default EditButton;
