/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useKeyInsights from '@/Hooks/react-query/key-insight/useKeyInsight';
import { useUpdateKeyInsight } from '@/Hooks/react-query/key-insight/useUpdateKeyInsight';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type { SectionArchetypeType } from '@/Types/key_insight';
import type { KeyInsightSchema } from '@/Types/key_insight/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditing, setIsEditing] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [parsedData, setParsedData] = useState<SectionArchetypeType[]>([]);
  const [editedData, setEditedData] = useState<SectionArchetypeType[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [isGenerate, setIsGenerate] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeItem, setActiveItem] = useState<SectionArchetypeType>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [totalSelectedKeyInsight, setTotalSelectedKeyInsight] =
    useState<number>(0);
  const [totalKeyInsight, setTotalKeyInsight] = useState<number>(0);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { mutate } = useUpdateKeyInsight();

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [isRegenerating, setIsReGenerating] = useState(false);

  const { handleGenerateKeyInsight, handleGenerateChallengeAndTask } =
    useGenerate(dispatch);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const { data, isLoading, refetch, isRefetching } = useKeyInsights(project);
  const {
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const routeOptionValue: RouteOption = {
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  };

  const hasSelectedKeyInsight = (parsedData: SectionArchetypeType[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = parsedData
      .map((section, index) => {
        const hasSelectedKeyInsight = section.key_insights.some(
          (keyInsight) => keyInsight.is_selected === 1,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select key insight for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleRegenerateButton = (prompt: string) => {
    setParsedData([]);
    setEditedData([]);
    setSelectedIndex([]);
    setErrorTabIndex([]);
    setIsGenerate(true);
    dispatch(saveSubInformation(''));
    handleGenerateKeyInsight()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const handleFetchSubmit = async (
    payload: KeyInsightSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeItem?.id!, projectSlug },
      {
        onSuccess: (data) => {
          const keyInsights = data.data;
          const activeItem = keyInsights.find(
            (item: any) => item.id === activeTab,
          );
          setActiveItem(activeItem);

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  useEffect(() => {
    const totalSelected = data?.data?.data?.total_selected || 0;
    const totalData = data?.data?.data?.total_key_insight || 0;

    setTotalSelectedKeyInsight(totalSelected);
    setTotalKeyInsight(totalData);

    if (data?.data?.data) {
      const {
        data: keyInsights,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data.data.data;

      if (isQuerying) {
        setIsGenerate(true);

        setParsedData([]);
        setEditedData([]);
        setTotalRegenerate(0);
        setSources([]);
        return;
      }

      if (keyInsights.length > 0) {
        setParsedData(keyInsights);
        setEditedData(keyInsights);
        setTotalRegenerate(totalRegenerate);
        setSources(sources);
        setIsGenerate(false);
      }

      dispatch(saveSubInformation(''));
    }
  }, [
    data,
    isKeyInsightsComplete,
    differentArchetypes?.is_different_archetype.key_insight,
  ]);

  useEffect(() => {
    if (!isKeyInsightsComplete.status) {
      dispatch(saveInformation(`0 from 0 key insight selected`));
    }

    if (isKeyInsightsComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (!isPendingHistory && isKeyInsightsComplete.isQuerying) {
      setIsGenerate(true);
    }
  }, [isKeyInsightsComplete, isPendingHistory]);

  useEffect(() => {
    !isKeyInsightsComplete.isQuerying && !isPendingHistory && refetch();
    isKeyInsightsComplete.isQuerying &&
      !isPendingHistory &&
      setIsGenerate(true);
  }, [
    isGenerate,
    isKeyInsightsComplete,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.key_insight,
  ]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.key_insight
    ) {
      if (
        !isGenerate &&
        !isPendingHistory &&
        !isKeyInsightsComplete.isQuerying
      ) {
        setIsGenerate(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveSubInformation(''));
        handleGenerateKeyInsight()(project.slug);
      }
    }
  }, [
    isGenerate,
    differentArchetypes,
    isPendingHistory,
    isKeyInsightsComplete,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/audience-archetype`);
        },
      }),
    );
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    if (
      isAdding ||
      isEditing ||
      differentArchetypes?.is_different_archetype.key_insight ||
      parsedData.length === 0 ||
      isKeyInsightsComplete.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.key_insight,
    parsedData,
    isKeyInsightsComplete.isQuerying,
  ]);

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totalSelectedKeyInsight} from ${totalKeyInsight} key Insight selected`,
      ),
    );
  }, [totalSelectedKeyInsight, totalKeyInsight]);

  const countSelectedData = (data: SectionArchetypeType[]) => {
    const archetypeCounts = data.map((archetype) => {
      let totalKeyInsights = 0;
      let selectedKeyInsights = 0;

      if (archetype.key_insights && Array.isArray(archetype.key_insights)) {
        archetype.key_insights.forEach((keyInsight) => {
          totalKeyInsights++;
          if (keyInsight.is_selected === 1) {
            selectedKeyInsights++;
          }
        });
      }

      // Return the counts for each archetype
      return {
        archetypeId: archetype.id, // Adjust if needed to your archetype identifier
        totalKeyInsights,
        selectedKeyInsights,
      };
    });

    const isInactive = archetypeCounts.some(
      (archetype) => archetype.selectedKeyInsights === 0,
    );

    return isInactive;
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['challenges', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/key-insight/check-regenerate`,
    );

    if (
      data?.data.is_show_pop_up ||
      isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isChallengeAndTaskOfTheBrand.isRegenerated,
      );
      return;
    }
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/challenge-and-communication-task`);
  };

  useEffect(() => {
    if (!parsedData.length) return;

    setSelectedIndex([]);
    const activeItem = parsedData[activeTab];
    const selectedDataWithIndex = activeItem?.key_insights
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);

      if (errorTabIndex?.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== activeTab) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(', ');

        dispatch(saveSubInformation(''));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select key insight for ${namesWithoutSelectedKeyInsight}`,
            ),
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }

    setActiveItem(activeItem);
  }, [activeTab, parsedData]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [activeTab]);

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleRegenerate = async () => {
    if (
      differentArchetypes?.is_different_archetype.key_insight ||
      isKeyInsightsComplete.isRegenerated
    ) {
      setIsReGenerating(true);
      setIsGenerate(true);
      await handleGenerateKeyInsight()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
    } else {
      setIsReGenerating(true);
      if (
        isChallengeAndTaskOfTheBrand.status ||
        isKeyInsightsComplete.isRegenerated ||
        differentArchetypes?.is_different_archetype.challenges
      ) {
        await handleGenerateChallengeAndTask()(project.slug, null);
      }

      setIsReGenerating(false);
      dispatch(saveInformation(''));
      navigate(`/${project.slug}/challenge-and-communication-task`);
    }
  };

  const sectionList: SectionList = {
    title: 'Key Insight',
    value: '',
    section: 'key_insights',
  };

  useEffect(() => {
    if (isEditor) {
      const isInactive = isGenerate ? true : countSelectedData(parsedData);

      if (
        !isChallengeAndTaskOfTheBrand.status &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !differentArchetypes?.is_different_archetype.challenges
      ) {
        routeOptionValue.label = 'Generate Challenge & Task';
        routeOptionValue.isGenerate = true;
      }

      if (
        isChallengeAndTaskOfTheBrand.status &&
        (isChallengeAndTaskOfTheBrand.isRegenerated ||
          differentArchetypes?.is_different_archetype.challenges)
      ) {
        routeOptionValue.label = 'Regenerate Challenge & Task';
        routeOptionValue.isGenerate = true;
      }

      if (
        isChallengeAndTaskOfTheBrand.status &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !differentArchetypes?.is_different_archetype.challenges
      ) {
        routeOptionValue.label = 'Next to Challenge & Task';
        routeOptionValue.isGenerate = false;
      }

      routeOptionValue.isInactive =
        !isKeyInsightsComplete.status || isKeyInsightsComplete.isQuerying
          ? true
          : isInactive;
    } else {
      routeOptionValue.label = 'Next to Challenge & Task';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        differentArchetypes?.is_different_archetype.challenges;
    }

    routeOptionValue.onClick = () => {
      if (
        !isKeyInsightsComplete.status ||
        isGenerate ||
        isKeyInsightsComplete.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait key insight is still generating`,
          ),
        );
        return;
      }

      if (
        !isEditor &&
        (!isChallengeAndTaskOfTheBrand.status ||
          differentArchetypes?.is_different_archetype.challenges)
      ) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor && isChallengeAndTaskOfTheBrand.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, challenge & communication task is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && (isAdding || isEditing)) {
        dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && routeOptionValue.isGenerate) {
        const hasError = hasSelectedKeyInsight(parsedData);

        if (!hasError) {
          handleRefetchSelectedData();
        }

        return;
      }

      navigate(`/${project.slug}/challenge-and-communication-task`);
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    differentArchetypes?.is_different_archetype.challenges,
    isKeyInsightsComplete.status,
    isKeyInsightsComplete.isQuerying,
    isAdding,
    isEditing,
    parsedData,
    isGenerate,
    totalSelectedKeyInsight,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    isChallengeAndTaskOfTheBrand.isQuerying,
    isEditor,
  ]);

  return (
    <>
      <Helmet title="Key Insight" />
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.keyInsight.progress / 2).toFixed(0)),
            isQuerying: isKeyInsightsComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isKeyInsightsComplete.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
            <div className="flex grow flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Key Insight
              </h1>
              <span className="text-1620 font-normal text-grey-redx">
                Please{' '}
                <span className="font-bold text-black-redx">
                  select one or more
                </span>{' '}
                key insights{' '}
                <span className="font-bold text-black-redx">
                  for each archetype
                </span>
                , or add a new insight by clicking &apos;Add New Key
                Insight&apos;.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="key-insight"
              />
            )}
          </div>

          <TabGroup
            className="pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <div className="relative flex items-center">
              {!isLoading &&
                !isKeyInsightsComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={activeTab === 0}
                    onClick={handlePrev}
                  >
                    <Icon className="text-25" icon="mingcute:left-line" />
                  </button>
                )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading && !isKeyInsightsComplete.isQuerying
                  ? parsedData.map((section, index) => (
                      <Tab
                        key={index}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                        disabled={isEditing || isAdding}
                      >
                        <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${index + 1}`}
                            <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              className="text-25 text-error-redx"
                              icon="mingcute:information-line"
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                      >
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isKeyInsightsComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={activeTab === parsedData.length - 1}
                    onClick={handleNext}
                  >
                    <Icon className="text-25" icon="mingcute:right-line" />
                  </button>
                )}
            </div>
            {!isLoading && !isKeyInsightsComplete.isQuerying ? (
              <TabPanels className="py-24">
                {parsedData.map((section, index) => {
                  return (
                    <TabPanel key={index}>
                      <div key={index} className="pt-5">
                        <SelectedCard
                          data={[
                            {
                              title: 'Archetype Name',
                              content: section.archetype_content.archetype_name,
                            },
                            {
                              title: 'Demographic',
                              content: section.archetype_content.demographics,
                            },
                            {
                              title: 'Occupation',
                              content: section.archetype_content.occupation,
                            },
                            {
                              title: 'Lifestyle',
                              content: section.archetype_content.lifestyle,
                            },
                            {
                              title: 'Behavior',
                              content: section.archetype_content.behavior,
                            },
                            {
                              title: 'Need & Challenges',
                              content:
                                section.archetype_content.needs_and_challenges,
                            },
                            {
                              title: 'Potential Buying Motives',
                              content:
                                section.archetype_content
                                  .potential_buying_motives,
                            },
                            {
                              title: 'Current Trends',
                              content: section.archetype_content.current_trends,
                            },
                            {
                              title: 'Source of Information',
                              content:
                                section.archetype_content.source_of_information,
                            },
                            {
                              title: 'Online Search Behavior',
                              content:
                                section.archetype_content
                                  .online_search_behavior,
                            },
                            {
                              title: 'Purchase Frequency',
                              content:
                                section.archetype_content.purchase_frequency,
                            },
                            {
                              title: 'Preferred Sales Channels',
                              content:
                                section.archetype_content
                                  .preferred_sales_channels,
                            },
                          ]}
                          title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                        />
                        <div className="mt-20" />
                        {(section.key_insights.length > 0 || isAdding) && (
                          <SliderContainer
                            goToIndex={goToIndex}
                            isAdding={isAdding}
                            isEditing={isEditing}
                            length={section.key_insights.length}
                            onActiveIndex={(index) => setActiveIndex(index)}
                            selectedIndexes={selectedIndex}
                          >
                            {!isAdding &&
                              section.key_insights.map((keyInsight, index) => (
                                <Card
                                  key={index}
                                  ref={(el) => (cardRefs.current[index] = el)}
                                  id={keyInsight.id}
                                  index={index}
                                  isAdding={isAdding}
                                  isCanSelect={isEditor}
                                  isEditing={isEditing}
                                  isFetching={isRefetching}
                                  isSelected={!!keyInsight.is_selected}
                                  item={keyInsight.key_insight_content}
                                  onSelectSuccess={onSelectSuccess}
                                  onSubmitSuccess={handleFetchSubmit}
                                  project={project}
                                  totalData={section.key_insights.length}
                                />
                              ))}

                            {isAdding && (
                              <Card
                                ref={(el) => (cardRefs.current[0] = el)}
                                id={-1}
                                index={section.key_insights.length}
                                isAdding
                                isEditing={isAdding}
                                isSelected={false}
                                item={{
                                  consumer: '',
                                  cultural: '',
                                  category: '',
                                }}
                                onSelectSuccess={onSelectSuccess}
                                onSubmitSuccess={handleFetchSubmit}
                                project={project}
                                totalData={section.key_insights.length + 1}
                              />
                            )}
                          </SliderContainer>
                        )}

                        {section.key_insights.length === 0 &&
                          !isAdding &&
                          !isEditing && (
                            <div className="flex min-h-[50vh] flex-col items-center justify-center gap-55 lg:flex-row">
                              <div className="my-6">
                                <img
                                  alt="Confused Character"
                                  className="w-48 mx-auto h-auto"
                                  src="/assets/img/ainstein-notfound.png"
                                />
                              </div>
                              <div className="flex-col gap-30">
                                <div className="mb-6">
                                  <h1 className="text-24 font-semibold">
                                    No result found...
                                  </h1>
                                  <p className="py-20 text-gray-400">
                                    We couldn&apos;t find any results for Key
                                    insight.
                                    <br />
                                    You can input or generate the key insight
                                    Information in this section.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                                onClick={toggleIsAdding}
                              >
                                Add Key Insight Manually
                                <Icon className="size-20" icon="lucide:plus" />
                              </button>
                            ) : (
                              <div />
                            ))}
                          <div className="flex items-center justify-end gap-x-15">
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }
                                  >
                                    <span className="text-15 font-semibold">
                                      Cancel
                                    </span>
                                    <Icon className="size-20" icon="lucide:x" />
                                  </button>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                                    id="btn-edit-key-insight"
                                    onClick={handleSubmit}
                                  >
                                    <span className="text-15 font-semibold">
                                      Save
                                    </span>
                                    <Icon
                                      className="size-20"
                                      icon="lucide:save"
                                    />
                                  </button>
                                </>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className="flex w-full pb-60">
                          {sources && <SourcesList sources={sources} />}
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            ) : (
              <div className="block">
                <div className="h-55 w-full animate-pulse rounded-8 bg-soft-purple-redx" />
                <div className="mt-20" />
                <SliderContainer
                  goToIndex={goToIndex}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  length={1}
                  onActiveIndex={undefined}
                  selectedIndexes={selectedIndex}
                >
                  <Card
                    ref={(el) => (cardRefs.current[-1] = el)}
                    id={-1}
                    index={-1}
                    isEditing={isAdding}
                    isFetching
                    isSelected={false}
                    item={{
                      consumer: '',
                      cultural: '',
                      category: '',
                    }}
                    onSelectSuccess={onSelectSuccess}
                    onSubmitSuccess={handleFetchSubmit}
                    project={project}
                    totalData={-1}
                  />
                </SliderContainer>
                <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                  {!roles.includes('View Only') &&
                    (!isAdding && !isEditing ? (
                      <button
                        className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                        onClick={undefined}
                      >
                        Add Key Insight Manually
                        <Icon className="size-20" icon="lucide:plus" />
                      </button>
                    ) : (
                      <div />
                    ))}
                  <div className="flex items-center justify-end gap-x-15">
                    {!roles.includes('View Only') &&
                      (isEditing || isAdding ? (
                        <>
                          <button
                            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">
                              Cancel
                            </span>
                            <Icon className="size-20" icon="lucide:x" />
                          </button>
                          <button
                            className={`inline-flex items-center gap-x-4 ${
                              false ? 'text-gray-600' : 'text-blue-redx'
                            } cursor-pointer`}
                            disabled={false}
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">Save</span>
                            <Icon className="size-20" icon="lucide:save" />
                          </button>
                        </>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className="flex w-full pb-60">
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="key-insight"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
