import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { RecommendationContent } from "@/Types/key_touch_point";

const updateKeyTouchPoint = async ({
  payload,
  id,
  archetypeId,
  funnel,
  projectSlug,
}: {
  payload: RecommendationContent;
  id: number;
  archetypeId: number;
  funnel: string;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/key-touch-point/submit/${id}`,
      {
        content: payload,
        archetype_id: archetypeId,
        funnel: funnel,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateKeyTouchPoint = () => {
  return useMutation<
    any,
    Error,
    {
      payload: RecommendationContent;
      id: number;
      archetypeId: number;
      funnel: string;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyTouchPoint,
  });
};

export { useUpdateKeyTouchPoint };
