/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Icon } from '@iconify/react';
import React, { forwardRef, useContext, useState } from 'react';
import type { FieldErrors, UseFormSetValue } from 'react-hook-form';

import InputCheckboxDropdown from '@/Components/InputCheckboxDropdown';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import TextInput from '@/Components/TextInput';
import { ProjectContext } from '@/Context/ProjectContext';
import type { SubmissionFormPayload } from '@/Types/form';

interface AudienceArchetypeProps {
  data: {
    age_market_start: number | string;
    age_market_end: number | string;
    gender: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isFetching: boolean;
  genders: { title: string; value: string }[];
  isEditor: boolean;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const AudienceArchetype = forwardRef<HTMLDivElement, AudienceArchetypeProps>(
  (
    {
      data,
      setData,
      errors,
      setError,
      isActive,
      genders,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
      isEditingCount,
      setIsEditingCount,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAgeMarketStartChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value } = e.target;
      if (
        value === '' ||
        (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)
      ) {
        setData('age_market_start', value);
        setError('age_market_start', { message: '' });
        setError('age_market_end', { message: '' });
      }
    };

    const handleAgeMarketEndChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value } = e.target;
      if (
        value === '' ||
        (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)
      ) {
        setData('age_market_end', value);
        setError('age_market_start', { message: '' });
        setError('age_market_end', { message: '' });
      }
    };

    const toggleIsEditing = (isEdit: boolean) => {
      setIsEditing(isEdit);
      if (setIsEditingCount) {
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        setIsEditingCount(newCount);
      }
    };

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(2);
      if (!isNotError) {
        toggleIsEditing(true);
        return;
      }
      toggleIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Target Audience
          </h1>
          {!isFetching &&
            state.submission.is_audience_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <button
                className=" inline-flex cursor-pointer items-center justify-center gap-8"
                id="btn-edit-submission-target-audience"
                onClick={() => toggleIsEditing(true)}
              >
                <span className="text-14 font-semibold text-blue-redx">
                  Edit
                </span>
                <Icon
                  className="size-16 text-blue-redx"
                  icon="lucide:pencil-line"
                />
              </button>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className="inline-flex gap-8">
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                id="btn-cancel-edit-submission-target-audience"
                onClick={() => {
                  fetchData();
                  toggleIsEditing(false);
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-16" icon="lucide:x" />
              </button>

              <button
                className="inline-flex cursor-pointer items-center gap-x-4"
                id="btn-edit-submission-audience-archetype"
                onClick={() => setIsModalOpen(true)}
              >
                <p className="text-14 font-semibold text-blue-redx">Save</p>
                <Icon className="size-16 text-blue-redx" icon="lucide:save" />
              </button>
            </div>
          )}
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="age_market_start"
              value="Age Range"
            />
            <div className="inline-flex w-full items-center justify-between gap-x-10">
              {isFetching ? (
                <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              ) : (!state.submission.is_audience_information_complete ||
                  isEditing) &&
                isEditor ? (
                <TextInput
                  className="mt-1 block w-full !text-14"
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  disabled={!isActive}
                  error={errors.age_market_start?.message}
                  id="age_market_start"
                  isFocused
                  max={100}
                  maxLength={3}
                  min={0}
                  name="age_market_start"
                  onChange={handleAgeMarketStartChange}
                  placeholder="Input lower bound here"
                  type="number"
                  value={data.age_market_start}
                />
              ) : (
                <p className="w-full px-0 py-6 !text-14">
                  {data.age_market_start ? data.age_market_start : '-'}
                </p>
              )}

              <span className="text-black">-</span>

              {isFetching ? (
                <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              ) : (!state.submission.is_audience_information_complete ||
                  isEditing) &&
                isEditor ? (
                <TextInput
                  className="mt-1 block w-full !text-14"
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  disabled={!isActive}
                  error={errors.age_market_end?.message}
                  id="age_market_end"
                  isFocused
                  max={100}
                  maxLength={3}
                  min={0}
                  name="age_market_end"
                  onChange={handleAgeMarketEndChange}
                  placeholder="Input upper bound here"
                  type="number"
                  value={data.age_market_end}
                />
              ) : (
                <p className="w-full px-0 py-6 !text-14">
                  {data.age_market_end ? data.age_market_end : '-'}
                </p>
              )}
            </div>
            <div className="mt-2 inline-flex w-full items-center justify-between gap-x-10">
              <p className=" ml-2 w-full">
                <InputError message={errors.age_market_start?.message} />
              </p>
              <p className=" ml-5 w-full">
                <InputError message={errors.age_market_end?.message} />
              </p>
            </div>
          </div>
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="gender"
              value="Gender"
            />
            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_audience_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputCheckboxDropdown
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.gender?.message}
                list={genders}
                modelValue={data.gender}
                multiple
                onChange={(value: any) => {
                  setData('gender', value);
                  setError('gender', { message: '' });
                }}
                placeholder="Choose gender here"
                state="init"
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {data.gender.length > 0 ? data.gender : '-'}
              </p>
            )}

            <InputError className="mt-2" message={errors.gender?.message} />
          </div>
        </div>
        <RegenerateModal
          handleSubmit={submitForm}
          isOpen={isModalOpen}
          isRegenerating={false}
          onClose={() => {
            fetchData();
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          section="audience-archetype"
        />
      </div>
    );
  },
);

export default AudienceArchetype;
