/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@iconify/react';
import markerSDK from '@marker.io/browser';
import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useLocation, useNavigate } from 'react-router-dom';

import Profile from '@/Components/TopMenu/Profile';
import { useSidebarContext } from '@/Context/SidebarContext';
import useUser from '@/Hooks/react-query/useUser';

export default function Authenticated({ children }: PropsWithChildren) {
  const { data: user, isLoading } = useUser();
  const navigate = useNavigate();
  const { isSidebarOpen, closeSidebar, showSidebar } = useSidebarContext();
  const location = useLocation();

  useEffect(() => {
    // TODO: Will replace with openreplay
    const initializeMarker = async () => {
      try {
        // Load the Marker.io widget
        const widget = await markerSDK.loadWidget({
          // TODO: convert into ENV
          project:
            import.meta.env.VITE_MARKER_PROJECT_CODE ??
            '66d527ce6c3f5b2807cff0c3',
        });

        widget.setReporter({
          email: user?.email ?? 'ai@ainstein.com',
          fullName: user?.name ?? 'ainstein',
        });

        // Optional: Show the widget or configure further as needed
        widget.show();
      } catch (error) {
        // console.error('Failed to load Marker.io widget:', error);
      }
    };

    initializeMarker();

    const projectCode = import.meta.env.VITE_CLARITY_PROJECT_CODE;
    clarity.init(projectCode);

    if (clarity.hasStarted()) {
      clarity.identify('USER_ID', {
        userProperty: user?.email ?? 'ai@ainstein.com',
      });
    }
  }, [user]);

  useEffect(() => {
    const removeOverflowHidden = () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = ''; // Remove the overflow hidden style
      }
    };
    removeOverflowHidden();

    const handlePopState = () => {
      removeOverflowHidden();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleLogout = () => {
    // Remove the access_token cookie
    Cookies.remove('access_token');

    // Redirect the user to the login page or another appropriate page
    navigate('/login', { replace: true });
  };

  const handleBackToProject = () => {
    navigate('/projects', { replace: true });
  };

  const shouldShowMenuIcon = location.pathname !== '/projects';
  if (!isLoading) {
    return (
      <div className="w-full bg-white">
        <header className="fixed left-0 top-0 z-40 flex w-full items-center justify-between bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] px-30 py-10">
          <div className="inline-flex flex-row gap-10">
            {shouldShowMenuIcon && (
              <Icon
                className="mr-20 block size-24 cursor-pointer text-white lg:hidden"
                icon="lucide:menu"
                onClick={() => {
                  isSidebarOpen ? closeSidebar() : showSidebar();
                }}
              />
            )}
            <img
              alt="AInstein.png"
              className="h-35 w-auto cursor-pointer"
              onClick={handleBackToProject}
              src="/assets/img/AInstein.svg"
            />
          </div>
          <Profile avatar={user.avatar_url} />
        </header>

        {!isLoading && user && <main>{children}</main>}
      </div>
    );
  }
  if (!user && !isLoading) {
    handleLogout();
  }
}
