import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { IdeaPersonalizationSelectedResponse } from "@/Types/idea_personalization";

const patch = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.patch(
      `/${projectSlug}/idea-personalization/selected-content/${id}`,
      {},
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelected = () => {
  return useMutation<
    IdeaPersonalizationSelectedResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: patch,
  });
};

export { useUpdateSelected };
