import toast from "react-hot-toast";
import { Icon } from "@iconify/react";

const RequestAccess: React.FC<{ handleClick?: () => void; t: any }> = ({
  handleClick = () => {},
  t,
}) => {
  return (
    <div
      className="inline-flex items-center py-10 px-20 mb-4 text-10 text-white bg-danger-redx rounded-lg"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: "opacity 100ms ease-in-out",
      }}
    >
      <p className="mr-10">
        Your account does not have permission to log in. 
        <button
          onClick={handleClick}
          className='px-10 py-3 ml-5 border-1 border-white rounded-md'>
          Request Access Here
        </button>
      </p>
      <Icon
        icon="mdi:close"
        className="w-15 h-15 cursor-pointer"
        onClick={() => toast.remove()}
      >
        X
      </Icon>
    </div>
  );
};

export default RequestAccess;
