import ProjectLayout from "@/Layouts/ProjectLayout";
import ProjectIndex from "./Index";
import { PageProps } from "@/Types";
import { CategoryProps } from "@/Types/projects";
import { useParams } from "react-router-dom";
import { useSubmission } from "@/Hooks/react-query/submission/useSubmission";
import { Helmet } from "react-helmet";

const Wrapper = ({}: PageProps<{
  categories: CategoryProps[];
  subcategories: CategoryProps[];
}>) => {
  const params = useParams<{ project_slug: string }>();
  const { data, isLoading } = useSubmission(params?.project_slug ?? "");

  return (
    <ProjectLayout>
      <Helmet>
        <title>Submission</title>
      </Helmet>
      {!isLoading && data && <ProjectIndex data={data} />}
    </ProjectLayout>
  );
};

export default Wrapper;
