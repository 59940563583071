import { z } from "zod";

// Schema for key_highlight
const keyHighlightSchema = z.object({
  emotional: z.string().min(5, "Mandatory filled"),
  functional: z.string().min(5, "Mandatory filled"),
});

// Schema for desired_response
const desiredResponseSchema = z.object({
  after: z.string().min(5, "Mandatory filled"),
  before: z.string().min(5, "Mandatory filled"),
});

// Schema for audience_insights
const audienceInsightsSchema = z.object({
  lifestyle: z.string().min(5, "Mandatory filled"),
  behavioral: z.string().min(5, "Mandatory filled"),
  pain_point: z.string().min(5, "Mandatory filled"),
  current_trends: z.string().min(5, "Mandatory filled"),
  audience_insights: z.string().min(5, "Mandatory filled"),
  cultural_insights: z.string().min(5, "Mandatory filled").optional(),
});

const onepageContentSchema = z.object({
  archetype_id: z.number().optional(),
  key_highlight: keyHighlightSchema,
  archetype_number: z.number().optional(),
  desired_response: desiredResponseSchema,
  audience_insights: audienceInsightsSchema,
  strategic_statement: z.string().min(5, "Mandatory filled"),
  product_feature_benefit: z.string().min(5, "Mandatory filled"),
});

// Schema for selected_audience_archetypes
const selectedAudienceArchetypesSchema = z.object({
  id: z.number(),
  submission_id: z.number(),
  archetype_id: z.number(),
  history_id: z.number(),
  archetype_name: z.string().min(5, "Mandatory filled"),
  onepage_content: onepageContentSchema,
});

// Main schema
export const mainSchema = z.object({
  challenge: z.string().min(5, "Mandatory filled"),
  communication_task: z.string().min(5, "Mandatory filled"),
  campaign_objective: z.string().min(5, "Mandatory filled"),
  selected_audience_archetypes: z
    .array(selectedAudienceArchetypesSchema)
    .optional(),
});

export type Schema = z.infer<typeof mainSchema>;
