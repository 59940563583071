import { Icon } from '@iconify/react';
import React, { Fragment, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import MarkdownView from '@/Components/MardownView';
import EditButton from '@/Components/Projects/EditButton';
import TextAreaInput from '@/Components/TextAreaInput';
import type { CompetitorAnalysisProps } from '@/Types/competitor_analysis';

const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = '' }) => (
  <div className={`flex flex-col gap-10 ${className}`}>{children}</div>
);
const Label: React.FC<{ value: string }> = ({ value }) => (
  <p className="text-12 font-semibold leading-14 text-black-redx">{value}</p>
);
const Content: React.FC<{
  value: string | string[];
  isFetching?: boolean;
}> = ({ value, isFetching = false }) => {
  if (isFetching) {
    return (
      <div className="flex flex-col gap-10">
        <div className="h-20 w-full animate-pulse rounded-full bg-soft-purple-redx" />
      </div>
    );
  }
  if (Array.isArray(value)) {
    return (
      <ul className="ms-20 list-disc">
        {value.map((item, index) => (
          <li
            key={index}
            className="text-14 font-normal leading-20 text-black-redx"
          >
            <MarkdownView content={item} />
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div className="text-14 font-normal leading-20 text-black-redx">
      <MarkdownView content={value} />
    </div>
  );
};
const Card: React.FC<{
  item: CompetitorAnalysisProps;
  index: number;
  isCanEdit?: boolean;
  isFetching?: boolean;
  onChangedData?: (data: CompetitorAnalysisProps, index: number) => void;
  onSaveEditedData?: (data: CompetitorAnalysisProps, index: number) => void;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  item,
  index,
  isCanEdit = true,
  isFetching = false,
  onSaveEditedData,
  isEditingCount,
  setIsEditingCount,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CompetitorAnalysisProps>({
    defaultValues: {
      brand_name: item.brand_name ?? '',
      product_name: item.product_name ?? '',
      communication_angle: item.communication_angle ?? '',
      communication_idea: item.communication_idea ?? '',
      communication_tone: item.communication_tone ?? '',
      features_benefits: item.features_benefits ?? '',
      key_touch_points: item.key_touch_points ?? '',
      opportunities: item.opportunities ?? '',
    },
  });

  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
    if (setIsEditingCount) {
      const newCount = !isEditing
        ? (isEditingCount += 1)
        : (isEditingCount -= 1);
      setIsEditingCount(newCount);
    }
  };

  useEffect(() => {
    reset(item);
  }, [item, reset]);

  const handleCancelEdit = () => {
    reset();
    toggleIsEditing();
  };

  const onSubmit: SubmitHandler<CompetitorAnalysisProps> = (data) => {
    toggleIsEditing();
    if (onSaveEditedData) {
      onSaveEditedData(data, index);
    }
  };

  return (
    <div className="flex w-full flex-col pb-35">
      <div className="mb-15 border-b-1 border-stroke-redx py-15 text-left">
        {!isFetching && (
          <h1 className="text-16 font-bold leading-20 text-black-redx">
            Competitor {index + 1}
          </h1>
        )}

        {isFetching && (
          <h1 className="h-20 w-2/12 animate-pulse rounded-full bg-soft-purple-redx" />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-24 pb-10">
          <Container>
            <Label value="Brand Name" />
            {isEditing ? (
              <Controller
                control={control}
                name="brand_name"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.brand_name?.message}
                    isFocused={false}
                    placeholder="Input the brand name here"
                    rows={2}
                  />
                )}
              />
            ) : (
              <Content isFetching={isFetching} value={item.brand_name} />
            )}
          </Container>
          <Container>
            <Label value="Product Name" />
            {isEditing ? (
              <Controller
                control={control}
                name="product_name"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.product_name?.message}
                    isFocused={false}
                    placeholder="Input the product name here"
                    rows={2}
                  />
                )}
              />
            ) : (
              <Content isFetching={isFetching} value={item.product_name} />
            )}
          </Container>
          <Container>
            <Label value="Communication Angle" />
            {isEditing ? (
              <Controller
                control={control}
                name="communication_angle"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.communication_angle?.message}
                    isFocused={false}
                    placeholder="Input the communication angle here"
                    rows={2}
                  />
                )}
              />
            ) : (
              <Content
                isFetching={isFetching}
                value={item.communication_angle}
              />
            )}
          </Container>
          <Container>
            <Label value="Communication Idea" />
            {isEditing ? (
              <Controller
                control={control}
                name="communication_idea"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.communication_idea?.message}
                    isFocused={false}
                    placeholder="Input the communication idea here"
                    rows={2}
                  />
                )}
              />
            ) : (
              <Content
                isFetching={isFetching}
                value={item.communication_idea}
              />
            )}
          </Container>
          <Container>
            <Label value="Communication Tone" />
            {isEditing ? (
              <Controller
                control={control}
                name="communication_tone"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.communication_tone?.message}
                    isFocused={false}
                    placeholder="Input the communication tone here"
                    rows={2}
                  />
                )}
              />
            ) : (
              <Content
                isFetching={isFetching}
                value={item.communication_tone}
              />
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Features & Benefits" />
            {isEditing ? (
              <Controller
                control={control}
                name="features_benefits"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.features_benefits?.message}
                    isFocused={false}
                    placeholder="Input the features and benefits here"
                    rows={3}
                  />
                )}
              />
            ) : (
              <Content
                isFetching={isFetching}
                value={item.features_benefits as string | string[]}
              />
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Key Touch Points" />
            {isEditing ? (
              <Controller
                control={control}
                name="key_touch_points"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.key_touch_points?.message}
                    isFocused={false}
                    placeholder="Input the key touch points here"
                    rows={3}
                  />
                )}
              />
            ) : (
              <Content isFetching={isFetching} value={item.key_touch_points} />
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Opportunities" />
            {isEditing ? (
              <Controller
                control={control}
                name="opportunities"
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    autoComplete="off"
                    className="overflow-custom mt-1 block w-full resize-none !text-14"
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    error={errors.opportunities?.message}
                    isFocused={false}
                    placeholder="Input the opportunities here"
                    rows={3}
                  />
                )}
              />
            ) : (
              <Content isFetching={isFetching} value={item.opportunities} />
            )}
          </Container>
        </div>
        <div
          className={`mt-10 flex w-full justify-end gap-x-15 py-10 ${
            !isEditing ? 'border-t-1 border-t-stroke-redx' : ''
          }`}
        >
          {isCanEdit &&
            (isEditing ? (
              <>
                <button
                  className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                  onClick={handleCancelEdit}
                  type="button"
                >
                  <span className="text-15 font-semibold">Cancel</span>
                  <Icon className="size-20" icon="lucide:x" />
                </button>
                <button
                  className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                  id="btn-edit-competitor-analysis"
                  type="submit"
                >
                  <span className="text-15 font-semibold">Save</span>
                  <Icon className="size-20" icon="lucide:save" />
                </button>
              </>
            ) : (
              <EditButton toggleEditing={toggleIsEditing} />
            ))}
        </div>
      </form>
    </div>
  );
};

export default Card;
