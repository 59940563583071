/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { forwardRef, useContext, useState } from 'react';
import type { FieldErrors, UseFormSetValue } from 'react-hook-form';

import InputDropdown from '@/Components/InputDropdown';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import { ProjectContext } from '@/Context/ProjectContext';
import type { SubmissionFormPayload } from '@/Types/form';

interface MarketInformationProps {
  data: {
    product_category: string;
    product_subcategory: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
  categories: { title: string; value: number }[];
  subcategories: { title: string; value: number }[];
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const MarketInformation = forwardRef<HTMLDivElement, MarketInformationProps>(
  (props, ref) => {
    const {
      data,
      setData,
      errors,
      setError,
      isActive,
      categories,
      subcategories,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
      isEditingCount,
      setIsEditingCount,
    } = props;
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleIsEditing = (isEdit: boolean) => {
      setIsEditing(isEdit);
      if (setIsEditingCount) {
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        setIsEditingCount(newCount);
      }
    };

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(1);
      if (!isNotError) {
        toggleIsEditing(true);
        return;
      }
      toggleIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Market Information
          </h1>
          {!isFetching &&
            state.submission.is_market_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <button
                className=" inline-flex cursor-pointer items-center justify-center gap-8"
                id="btn-edit-submission-market-information"
                onClick={() => toggleIsEditing(true)}
              >
                <span className="text-14 font-semibold text-blue-redx">
                  Edit
                </span>
                <Icon
                  className="size-16 text-blue-redx"
                  icon="lucide:pencil-line"
                />
              </button>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className="inline-flex gap-8">
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                id="btn-cancel-edit-submission-market-information"
                onClick={() => {
                  fetchData();
                  toggleIsEditing(false);
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-16" icon="lucide:x" />
              </button>

              <button
                className="inline-flex cursor-pointer items-center gap-x-4"
                id="btn-save-edit-submission-market-information"
                onClick={() => setIsModalOpen(true)}
              >
                <p className="text-14 font-semibold text-blue-redx">Save</p>
                <Icon className="size-16 text-blue-redx" icon="lucide:save" />
              </button>
            </div>
          )}
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="product_category"
              value="Product Category"
            />
            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_market_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputDropdown
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.product_category?.message}
                list={categories}
                modelValue={data.product_category}
                multiple={false}
                onChange={(value: any) => {
                  setData('product_category', value);
                  setData('product_subcategory', '');
                  setError('product_category', { message: '' });
                  setError('product_subcategory', { message: '' });
                }}
                placeholder="Choose product category here"
                state="init"
                withFilter
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {Number(data.product_category) > 0
                  ? categories.find(
                      (listItem) =>
                        listItem.value === Number(data.product_category),
                    )?.title
                  : '-'}
              </p>
            )}

            <InputError
              className="mt-2"
              message={errors.product_category?.message}
            />
          </div>
          <div>
            <InputLabel
              className="!text-black-redx"
              htmlFor="product_subcategory"
              value="Product Subcategory"
            />
            {isFetching ? (
              <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : (!state.submission.is_market_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputDropdown
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                disabled={!isActive}
                error={errors.product_subcategory?.message}
                list={subcategories}
                modelValue={data.product_subcategory}
                multiple={false}
                onChange={(value: any) => {
                  setData('product_subcategory', value);
                  setError('product_subcategory', { message: '' });
                }}
                placeholder="Choose product subcategory here"
                state="init"
                withFilter
              />
            ) : (
              <p className="px-0 py-6 !text-14">
                {Number(data.product_subcategory) > 0
                  ? subcategories.find(
                      (listItem) =>
                        listItem.value === Number(data.product_subcategory),
                    )?.title
                  : '-'}
              </p>
            )}

            <InputError
              className="mt-2"
              message={errors.product_subcategory?.message}
            />
          </div>
        </div>
        <RegenerateModal
          handleSubmit={submitForm}
          isOpen={isModalOpen}
          isRegenerating={false}
          onClose={() => {
            fetchData();
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          section="market-information"
        />
      </div>
    );
  },
);

export default MarketInformation;
