import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { Markdown } from "tiptap-markdown";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import showdown from "showdown";
import { adjustOrderedList } from "@/Utils/markdownAdjustOrderList";

// Inisialisasi showdown converter
const showdownService = new showdown.Converter({
  omitExtraWLInCodeBlocks: true,
  noHeaderId: true,
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  strikethrough: true,
  tables: true,
  openLinksInNewWindow: true,
  emoji: true,
  simpleLineBreaks: true,
  encodeEmails: false,
  requireSpaceBeforeHeadingText: false,
});

const MarkdownEditorReadOnly: React.FC<{ content: string }> = ({ content }) => {
  let html = showdownService.makeHtml(content);
  html = adjustOrderedList(html);

  const editor = useEditor({
    content: html,
    extensions: [
      StarterKit,
      Markdown,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
    ],
    editable: false, // Set editor to read-only
    editorProps: {
      attributes: {
        class:
          "border border-gray-400 py-4 px-0 outline-none prose overflow-custom max-w-none",
      },
    },
  });

  useEffect(() => {
    if (editor) {
      let updatedHtml = showdownService.makeHtml(content);
      updatedHtml = adjustOrderedList(updatedHtml);
      editor.commands.setContent(updatedHtml, false); // false to not scroll into view
    }
  }, [content, editor]);

  return <EditorContent editor={editor} />;
};

export default MarkdownEditorReadOnly;
