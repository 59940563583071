import { z } from "zod";

export const AudienceInsights = z.object({
  passion_points: z.string().min(4, "Passion points is required"),
  motivations: z.string().min(4, "Motivations is required"),
  pain_points: z.string().min(4, "Pain points is required"),
  needs_expectations: z.string().min(4, "Needs expectations is required"),
});

export type AudienceInsightsSchema = z.infer<typeof AudienceInsights>;
