import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";

const SliderContainer: React.FC<{
  children: React.ReactNode;
  isEditing?: boolean;
  isAdding: boolean;
  length: number;
  goToIndex?: number;
  selectedIndexes?: number[];
  onActiveIndex?: (activeIndex: number) => void;
}> = ({
  children,
  isAdding,
  selectedIndexes = [],
  isEditing = false,
  length,
  onActiveIndex,
  goToIndex,
}) => {
  let sliderRef = React.useRef<null | Slider>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(0 === length - 1);

  const NextArrow: React.FC<{
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }> = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={
          className +
          " text-black-redx relative" +
          (isAdding || isEditing
            ? " !text-placeholder-redx !cursor-not-allowed"
            : "")
        }
        style={{
          ...style,
          top: "1.5rem",
          right: 0,
          zIndex: 10,
          backgroundColor:
            isAdding || isEditing || isAtEnd ? "#D7D7D7" : "#330DCC",
        }}
        onClick={isAdding || isEditing || isAtEnd ? () => {} : onClick}>
        <Icon
          icon='lucide:arrow-right'
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={
            "w-4/5 h-4/5 text-white absolute" +
            (isAdding || isEditing || isAtEnd
              ? " !text-white !cursor-not-allowed"
              : "")
          }
        />
      </div>
    );
  };

  const PrevArrow: React.FC<{
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }> = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={
          className +
          " text-black-redx relative" +
          (isAdding || isEditing
            ? " !text-placeholder-redx !cursor-not-allowed"
            : "")
        }
        style={{
          ...style,
          top: "1.5rem",
          left: 0,
          zIndex: 10,
          backgroundColor:
            isAdding || isEditing || isAtStart ? "#D7D7D7" : "#330DCC",
        }}
        onClick={isAdding || isEditing || isAtStart ? () => {} : onClick}>
        <Icon
          icon='lucide:arrow-left'
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={
            "w-4/5 h-4/5 !text-white absolute" +
            (isAdding || isEditing || isAtStart
              ? " !text-white !cursor-not-allowed"
              : "")
          }
        />
      </div>
    );
  };

  const settings = {
    customPaging: (i: number) => {
      const isActive = selectedIndexes.includes(i);
      if (isActive)
        return (
          <div className='h-20 w-20 rounded-full border-1 border-blue-purple-redx'>
            <span
              className='text-1218 text-blue-purple-redx absolute font-semibold'
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}>
              {i + 1}
            </span>
          </div>
        );
      return (
        <div className='h-20 w-20 rounded-full'>
          <span
            className='text-1218 text-blue-purple-redx absolute font-semibold'
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {i + 1}
          </span>
        </div>
      );
    },
    dotsClass: `slick-dots slick-thumb ${[
      isAdding ? "slick-dots-disabled" : "",
    ]} `,
    dots: isAdding || isEditing ? false : true,
    infinite: false,
    swipe: isAdding || isEditing ? false : true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_: any, next: number) => {
      setActiveIndex(next);
      setIsAtStart(next === 0);
      setIsAtEnd(next === length - 1);
    },
  };

  useEffect(() => {
    if (isAdding) {
      sliderRef.current?.slickGoTo(length + 1, true);
    }
  }, [isAdding]);

  useEffect(() => {
    if (goToIndex !== undefined && goToIndex <= length) {
      sliderRef.current?.slickGoTo(goToIndex + 1, true);
    }
  }, [goToIndex, length]);

  useEffect(() => {
    if (onActiveIndex) {
      onActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  return (
    <div
      className={`slider-container relative ${
        selectedIndexes.includes(activeIndex)
          ? "border-b-2 border-blue-redx"
          : "border-b-1 border-stroke-redx"
      }`}>
      <Slider
        ref={sliderRef}
        {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default SliderContainer;
