import { PageProps } from "@/Types";

import ProjectLayout from "@/Layouts/ProjectLayout";
import Index from "./Index";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Wrapper = ({}: PageProps<{}>) => {
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? "",
  );

  return (
    <>
      <Helmet>
        <title>Challenge And Task</title>
      </Helmet>
      <ProjectLayout>
        {!isLoading && project && <Index project={project} />}
      </ProjectLayout>
    </>
  );
};

export default Wrapper;
