import type { Dispatch } from 'react';
import React, { useContext, useEffect, useReducer } from 'react';

import { useLocalStorage } from '@/Hooks/usePersist';

import type { ProjectAction } from './actions/projectActions';
import type { State } from './reducer/projectReducer';
import reducer, { initialState } from './reducer/projectReducer';

const ProjectContext = React.createContext<[State, Dispatch<ProjectAction>]>([
  initialState,
  () => {},
]);

interface ProjectProviderProps {
  children: React.ReactNode;
}

const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [value, setValue] = useLocalStorage('project');
  const initialStore = Object.keys(value)?.length > 0 ? value : initialState;
  const store = useReducer(reducer, initialStore);

  useEffect(() => {
    setValue(store[0]);
  }, [setValue, store, value]);

  return (
    <ProjectContext.Provider value={store}>{children}</ProjectContext.Provider>
  );
};

const ProjectConsumer = ProjectContext.Consumer;

const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return context;
};

export { ProjectConsumer, ProjectContext, ProjectProvider, useProject };
