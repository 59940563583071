export const IconCheckMark = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9273_4011)">
        <path
          clipRule="evenodd"
          d="M7.99999 2.0013C4.68628 2.0013 1.99999 4.68759 1.99999 8.0013C1.99999 11.315 4.68628 14.0013 7.99999 14.0013C11.3137 14.0013 14 11.315 14 8.0013C14 4.68759 11.3137 2.0013 7.99999 2.0013ZM0.666656 8.0013C0.666656 3.95121 3.9499 0.667969 7.99999 0.667969C12.0501 0.667969 15.3333 3.95121 15.3333 8.0013C15.3333 12.0514 12.0501 15.3346 7.99999 15.3346C3.9499 15.3346 0.666656 12.0514 0.666656 8.0013Z"
          fill="#388E3C"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.4714 6.19526C10.7317 6.45561 10.7317 6.87772 10.4714 7.13807L7.80472 9.80474C7.54437 10.0651 7.12226 10.0651 6.86191 9.80474L5.52858 8.4714C5.26823 8.21106 5.26823 7.78894 5.52858 7.5286C5.78892 7.26825 6.21103 7.26825 6.47138 7.5286L7.33331 8.39052L9.52857 6.19526C9.78892 5.93491 10.211 5.93491 10.4714 6.19526Z"
          fill="#388E3C"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_9273_4011">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
