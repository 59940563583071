import { z } from "zod";

export const IdeaExpansionSchema = z.object({
  idea_title: z.string().min(4, "Idea Title is required"),
  campaign_example: z.string().min(4, "Campaign Example is required"),
  campaign_metrics: z.string().min(4, "Campaign metrics is required"),
  idea_description: z.string().min(4, "Idea Description is required"),
  preferred_platform: z.string().min(4, "Preferred Platform is required"),
  preferred_influencer: z.string().min(4, "Preferred Influencer is required"),
  reason_product_brand: z.string().min(4, "Reason Product brand is required"),
  reason_audience_archetype: z
    .string()
    .min(4, "Reason Target audience is required"),
  idea_expansion_number: z.number().optional(),
});

export type Schema = z.infer<typeof IdeaExpansionSchema>;
