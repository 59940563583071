import { fetcher } from "@/Services/axios";
import { IdeaExpansionResponse } from "@/Types/idea-expansion";

import { useQuery } from "@tanstack/react-query";

const fetch = async (project_slug: string): Promise<IdeaExpansionResponse> => {
  try {
    const response = await fetcher.get(
      `${project_slug}/idea-expansion/lists`, // todo: change with correct url
    );

    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch idea expansion data",
    );
  }
};

const useIdeaExpansions = (project_slug: string) => {
  return useQuery<IdeaExpansionResponse, Error>({
    queryKey: ["ideaExpansion", project_slug],
    queryFn: () => fetch(project_slug),
    refetchOnMount: false, // Disable refetch on remount
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
    staleTime: Infinity, // Keep data fresh indefinitely (no refetching)
  });
};

export default useIdeaExpansions;
