import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useOpportunities from '@/Hooks/react-query/opportunity/useGetList';
import { useUpdateOpportunities } from '@/Hooks/react-query/opportunity/useUpdate';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type {
  OpportunityAmongCompetitionProps,
  OpportunityData,
} from '@/Types/opportunity_among_competition';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';

import Card from './Partials/Card';

export interface CardHandles {
  submitForm: () => void;
}

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [parsedData, setParsedData] = useState<OpportunityData[]>([]);
  const [activeArchetype, setActiveArchetype] = useState<OpportunityData>();
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState<
    number[] | undefined
  >();
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const {
    isOnePageStrategy,
    isOpportunityAmongCompetitionComplete,
    isUpdateState: isPendingOpportunity,
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data, isLoading, refetch, isRefetching } = useOpportunities(
    project.slug,
  );
  const { mutate } = useUpdateOpportunities();
  const [totalSelectedIdea, setTotalSelectedIdea] = useState<number>(0);
  const [totalIdea, setTotalIdea] = useState<number>(0);

  const routeOptionValue: RouteOption = {
    label: 'Generate One-Page Strategy',
    isActive: true,
    isGenerate: true,
    isInactive: true,
  };
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const refreshData = () => {
    isAdding && toggleIsAdding();
    isEditing && toggleIsEditing();

    setTimeout(() => {
      refetch();
    }, 700);

    setUpdateLoading(false);
  };

  const handleCancelEdit = async () => {
    toggleIsEditing();
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const {
    handleGenerateOpportunityAmongCompetition,
    handleGenerateOnePageStrategy,
  } = useGenerate(dispatch);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const handleSaveEditedData = (
    updatedData: OpportunityAmongCompetitionProps,
    index: number,
  ) => {
    const opportunitiesContent = omit(updatedData, ['id', 'is_selected']);

    const payload = {
      opportunities_content: opportunitiesContent,
      id: updatedData.id,
      archetype_id: activeArchetype!.id,
    };

    setUpdateLoading(true);
    mutate(
      {
        payload,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          refreshData();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
        onError: (error) => {
          toast.error(error.message);
          refreshData();
        },
      },
    );
  };

  const handleRefetchSelectedData = async (isRegenerated: boolean) => {
    queryClient.resetQueries({
      queryKey: ['OnePageStrategy', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/opportunity-among-competition/check-regenerate`,
    );

    if (data?.data.is_show_pop_up || isRegenerated) {
      setShowRegenerateModal(data.data.is_show_pop_up || isRegenerated);
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const hasSelectedOpportunity = (parseData: OpportunityData[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedIdea = parseData
      .map((section, index) => {
        const hasSelectedIdea = section.opportunities.some(
          (opportunity) => opportunity.is_selected,
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);
    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select idea for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateButton = (prompt: string) => {
    dispatch(saveSubInformation(''));
    setErrorTabIndex([]);
    setParsedData([]);
    setIsGenerated(true);
    handleGenerateOpportunityAmongCompetition()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    await handleGenerateOnePageStrategy()(project.slug, null);
    setIsReGenerating(false);
    dispatch(saveSubInformation(''));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (selectedIndex < parsedData.length - 1) {
      const nextIndex = selectedIndex + 1;
      setSelectedIndex(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1;
      setSelectedIndex(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/competitor-analysis`);
        },
      }),
    );
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedIndex]);

  const countSelectedData = (data: OpportunityData[]) => {
    const archetypeCounts = data.map((archetype) => {
      let totalArchetype = 0;
      let totalSelected = 0;

      if (archetype.opportunities && Array.isArray(archetype.opportunities)) {
        archetype.opportunities.forEach((opportunity) => {
          totalArchetype++;
          if (opportunity.is_selected === 1) {
            totalSelected++;
          }
        });
      }

      return {
        archetypeId: archetype.id,
        totalArchetype,
        totalSelected,
      };
    });

    const isInactive = archetypeCounts.some(
      (archetype) => archetype.totalSelected === 0,
    );

    return isInactive;
  };

  useEffect(() => {
    const totalSelected = data?.total_selected_opportunities || 0;
    const totalIdea = data?.total_opportunities || 0;

    setTotalSelectedIdea(totalSelected);
    setTotalIdea(totalIdea);

    if (data?.data) {
      const {
        data: selectedArchetypesOpportunity,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data;

      if (isQuerying) {
        setIsGenerated(true);
        setParsedData([]);
        setTotalRegenerate(0);
        setSources([]);
        return;
      }

      setParsedData(selectedArchetypesOpportunity);
      setTotalRegenerate(totalRegenerate);
      setSources(sources);
      dispatch(saveSubInformation(''));
      setIsGenerated(false);
    }
  }, [
    data,
    isOpportunityAmongCompetitionComplete,
    differentArchetypes?.is_different_archetype.opportunities,
  ]);

  useEffect(() => {
    if (!isOpportunityAmongCompetitionComplete.status) {
      dispatch(saveInformation(`0 from 0 idea selected`));
    }

    if (isOpportunityAmongCompetitionComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isOpportunityAmongCompetitionComplete.status,
    isOpportunityAmongCompetitionComplete.isError,
  ]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.opportunities
    ) {
      if (
        !isGenerated &&
        !isPendingOpportunity &&
        !isOpportunityAmongCompetitionComplete.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        dispatch(saveInformation(''));
        dispatch(saveSubInformation(''));
        handleGenerateOpportunityAmongCompetition()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isOpportunityAmongCompetitionComplete,
    isPendingOpportunity,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    !isOpportunityAmongCompetitionComplete.isQuerying &&
      !isPendingOpportunity &&
      refetch();

    isOpportunityAmongCompetitionComplete.isQuerying &&
      !isPendingOpportunity &&
      setIsGenerated(true);
  }, [
    isGenerated,
    isOpportunityAmongCompetitionComplete,
    isPendingOpportunity,
    differentArchetypes?.is_different_archetype.opportunities,
  ]);

  useEffect(() => {
    if (!parsedData.length) return;

    setSelectedOpportunityIndex([]);

    const activeArchetype = parsedData[selectedIndex];
    const selectedDataWithIndex = activeArchetype?.opportunities
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedOpportunityIndex(indices);

      if (errorTabIndex?.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== selectedIndex) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(', ');

        dispatch(saveSubInformation(''));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select idea for ${namesWithoutSelectedKeyInsight}`,
            ),
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }

    setActiveArchetype(activeArchetype);
  }, [selectedIndex, parsedData]);

  useEffect(() => {
    if (
      isAdding ||
      isEditing ||
      differentArchetypes?.is_different_archetype.opportunities ||
      parsedData.length === 0 ||
      isOpportunityAmongCompetitionComplete.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.opportunities,
    parsedData,
    isOpportunityAmongCompetitionComplete.isQuerying,
  ]);

  useEffect(() => {
    dispatch(
      saveInformation(`${totalSelectedIdea} from ${totalIdea} idea selected`),
    );
  }, [totalSelectedIdea, totalIdea]);

  useEffect(() => {
    if (isEditor) {
      const isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        !isKeyInsightsComplete.status ||
        !differentArchetypes?.is_selected_content.challenges ||
        !differentArchetypes?.is_selected_content.key_insight ||
        isOpportunityAmongCompetitionComplete.isQuerying ||
        !isOpportunityAmongCompetitionComplete.status
          ? true
          : countSelectedData(parsedData);

      if (
        isOnePageStrategy.status &&
        (isOnePageStrategy.isRegenerated ||
          differentArchetypes?.is_different_archetype.one_pages)
      )
        routeOptionValue.label = 'Regenerate One-Page Strategy';
      if (
        isOnePageStrategy.status &&
        !isOnePageStrategy.isRegenerated &&
        !differentArchetypes?.is_different_archetype.one_pages
      )
        routeOptionValue.label = 'Next To One-Page Strategy';

      routeOptionValue.isGenerate =
        isOnePageStrategy.isRegenerated ||
        !isOnePageStrategy.status ||
        differentArchetypes?.is_different_archetype.one_pages;

      routeOptionValue.isInactive = isGenerated ? true : isInactive;

      routeOptionValue.onClick = () => {
        if (
          !isOpportunityAmongCompetitionComplete.status ||
          isGenerated ||
          isOpportunityAmongCompetitionComplete.isQuerying
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, Idea is still generating`,
            ),
          );
          return;
        }

        if (
          (!isKeyInsightsComplete.status &&
            !isChallengeAndTaskOfTheBrand.status) ||
          !differentArchetypes?.is_selected_content.key_insight
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please generate & choose Key Insight, Challenge & Communication Task first.`,
            ),
          );
          return;
        }

        if (
          !isChallengeAndTaskOfTheBrand.status ||
          !differentArchetypes?.is_selected_content.challenges
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please generate & choose Challenge & Communication Task first.`,
            ),
          );
          return;
        }

        if (isOnePageStrategy.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, one page is still generating`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        if (isEditor && (isAdding || isEditing)) {
          dispatch(
            saveSubInformation(`{text-error-redx} Please do save first`),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        const hasError = hasSelectedOpportunity(parsedData);

        if (!hasError)
          handleRefetchSelectedData(isOnePageStrategy.isRegenerated);
      };
    } else {
      routeOptionValue.label = 'Next To One-Page Strategy';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isOnePageStrategy.status ||
        isOnePageStrategy.isQuerying ||
        differentArchetypes?.is_different_archetype.one_pages;

      routeOptionValue.onClick = () => {
        if (!isOpportunityAmongCompetitionComplete.status || isGenerated) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, Idea is still generating`,
            ),
          );
          return;
        }
        if (
          !isOnePageStrategy.status ||
          differentArchetypes?.is_different_archetype.one_pages
        ) {
          setShowViewInfoModal(true);
          return;
        }
        navigate(`/${project.slug}/one-page-strategy`);
      };
    }

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    isKeyInsightsComplete,
    isAdding,
    isEditing,
    isOpportunityAmongCompetitionComplete,
    isOnePageStrategy,
    differentArchetypes?.is_different_archetype.one_pages,
    parsedData,
    isGenerated,
    showRegenerateModal,
    totalSelectedIdea,
    isEditor,
    isAdding,
    isEditing,
  ]);

  const sectionList: SectionList = {
    title: 'Opportunity Among Competition',
    value: '',
    section: 'opportunity_among_competition',
  };

  return (
    <>
      <title title="Opportunity Among Competition" />
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.opportunityAmongCompetition.progress / 2).toFixed(0),
            ),
            isQuerying: isOpportunityAmongCompetitionComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={
            isOpportunityAmongCompetitionComplete.isSendingEmail
          }
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="flex w-2/3 flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Opportunity Among Competition
              </h1>
              <span className="text-15 font-normal text-grey-redx">
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="opportunity-among-competition"
              />
            )}
          </div>

          <TabGroup
            className="pb-80"
            onChange={setSelectedIndex}
            selectedIndex={selectedIndex}
          >
            <div className="relative flex items-center">
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === 0}
                    onClick={handlePrev}
                  >
                    <Icon className="text-25" icon="mingcute:left-line" />
                  </button>
                )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying
                  ? parsedData.map((section, index) => (
                      <Tab
                        key={index}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                        disabled={isEditing || isAdding}
                      >
                        <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${index + 1}`}
                            <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              className="text-25 text-error-redx"
                              icon="mingcute:information-line"
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                      >
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === parsedData.length - 1}
                    onClick={handleNext}
                  >
                    <Icon className="text-25" icon="mingcute:right-line" />
                  </button>
                )}
            </div>
            {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying ? (
              <TabPanels className="py-24">
                {parsedData.map((section, index) => (
                  <TabPanel key={index}>
                    <div key={index} className="pt-5">
                      <SelectedCard
                        data={[
                          {
                            title: 'Archetype Name',
                            content: section.archetype_content.archetype_name,
                          },
                          {
                            title: 'Demographic',
                            content: section.archetype_content.demographics,
                          },
                          {
                            title: 'Occupation',
                            content: section.archetype_content.occupation,
                          },
                          {
                            title: 'Lifestyle',
                            content: section.archetype_content.lifestyle,
                          },
                          {
                            title: 'Behavior',
                            content: section.archetype_content.behavior,
                          },
                          {
                            title: 'Need & Challenges',
                            content:
                              section.archetype_content.needs_and_challenges,
                          },
                          {
                            title: 'Potential Buying Motives',
                            content:
                              section.archetype_content
                                .potential_buying_motives,
                          },
                          {
                            title: 'Current Trends',
                            content: section.archetype_content.current_trends,
                          },
                          {
                            title: 'Source of Information',
                            content:
                              section.archetype_content.source_of_information,
                          },
                          {
                            title: 'Online Search Behavior',
                            content:
                              section.archetype_content.online_search_behavior,
                          },
                          {
                            title: 'Purchase Frequency',
                            content:
                              section.archetype_content.purchase_frequency,
                          },
                          {
                            title: 'Preferred Sales Channels',
                            content:
                              section.archetype_content
                                .preferred_sales_channels,
                          },
                        ]}
                        title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                      />
                      <div className="mt-20" />
                      <SliderContainer
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        isEditing={isEditing}
                        length={section.opportunities.length}
                        onActiveIndex={(index) =>
                          isAdding ? setActiveIndex(0) : setActiveIndex(index)
                        }
                        selectedIndexes={selectedOpportunityIndex}
                      >
                        {section.opportunities.map((item, index) => (
                          <Card
                            key={index}
                            ref={(el) => (cardRefs.current[index] = el)}
                            index={index}
                            isCanEdit={isEditor}
                            isEditing={isEditing}
                            isFetching={isRefetching}
                            item={
                              {
                                ...item.opportunities_content,
                                id: item.id,
                                is_selected: item.is_selected === 1,
                              } as OpportunityAmongCompetitionProps
                            }
                            onSaveEditedData={handleSaveEditedData}
                            onSuccessSelected={(status) => {
                              refetch();
                              setShowAlertGenerateModal(status);
                            }}
                            setIsEditing={setIsEditing}
                            totalItem={section.opportunities.length}
                          />
                        ))}
                        {isAdding && (
                          <Card
                            ref={(el) => (cardRefs.current[0] = el)}
                            index={section.opportunities.length + 1}
                            isAdding={isAdding}
                            isCanEdit={isEditor}
                            item={
                              {
                                id: -1,
                                brand_persona: '',
                                features_and_benefits: '',
                                creative_communication_angle: '',
                                digital_key_touch_points: '',
                                opportunity_among_competition_number:
                                  section.opportunities.length + 1,
                                is_selected: false,
                              } as OpportunityAmongCompetitionProps
                            }
                            onSaveEditedData={handleSaveEditedData}
                            setIsAdding={setIsAdding}
                            totalItem={section.opportunities.length}
                          />
                        )}
                      </SliderContainer>
                      <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                              onClick={toggleIsAdding}
                            >
                              Add Idea manually
                              <Icon className="size-20" icon="lucide:plus" />
                            </button>
                          ) : (
                            <div />
                          ))}
                        <div className="flex items-center justify-end gap-x-15">
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <>
                                <button
                                  className={`inline-flex cursor-pointer items-center gap-x-4 ${
                                    updateLoading
                                      ? 'text-gray-redx'
                                      : 'text-red-500'
                                  }`}
                                  disabled={updateLoading}
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }
                                >
                                  <span className="text-15 font-semibold">
                                    Cancel
                                  </span>
                                  <Icon className="size-20" icon="lucide:x" />
                                </button>
                                <button
                                  className={`inline-flex cursor-pointer items-center gap-x-4 ${
                                    updateLoading
                                      ? 'text-gray-redx'
                                      : 'text-blue-redx'
                                  }`}
                                  disabled={updateLoading}
                                  id="btn-edit-opportunity-among-competition"
                                  onClick={handleSubmit}
                                >
                                  <span className="text-15 font-semibold">
                                    Save
                                  </span>
                                  <Icon
                                    className="size-20"
                                    icon="lucide:save"
                                  />
                                </button>
                              </>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className="flex w-full pb-60">
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className="block">
                <div className="h-55 w-full animate-pulse rounded-8 bg-soft-purple-redx" />
                <div className="mt-20" />
                <SliderContainer
                  goToIndex={goToIndex}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  length={1}
                  onActiveIndex={undefined}
                >
                  <Card
                    ref={(el) => (cardRefs.current[-1] = el)}
                    index={-1}
                    isEditing={isAdding}
                    isFetching
                    item={
                      {
                        brand_persona: '',
                        features_and_benefits: '',
                        creative_communication_angle: '',
                        digital_key_touch_points: '',
                        is_selected: false,
                      } as OpportunityAmongCompetitionProps
                    }
                    totalItem={0}
                  />
                </SliderContainer>
                <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                  {isEditor &&
                    (!isAdding && !isEditing ? (
                      <button
                        className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                        onClick={undefined}
                      >
                        Add Opportunity Manually
                        <Icon className="size-20" icon="lucide:plus" />
                      </button>
                    ) : (
                      <div />
                    ))}
                  <div className="flex items-center justify-end gap-x-15">
                    {isEditor &&
                      (isEditing || isAdding ? (
                        <>
                          <button
                            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">
                              Cancel
                            </span>
                            <Icon className="size-20" icon="lucide:x" />
                          </button>
                          <button
                            className={`inline-flex items-center gap-x-4 ${
                              false ? 'text-gray-600' : 'text-blue-redx'
                            } cursor-pointer`}
                            disabled={false}
                            id="btn-edit-opportunity-among-competition"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">Save</span>
                            <Icon className="size-20" icon="lucide:save" />
                          </button>
                        </>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className="flex w-full pb-60">
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        handleSubmit={handleRegenerateNextProcess}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="opportunity-amount-competition"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
