import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";

const updateSelectedChallengeAndTask = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await fetcher.put(
      `/${projectSlug}/challenge-and-task/update-selected/${id}`,
      {},
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedChallengeAndTask = () => {
  return useMutation<any, Error, { id: number; projectSlug: string }>({
    mutationFn: updateSelectedChallengeAndTask,
  });
};

export { useUpdateSelectedChallengeAndTask };
